import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { UpdateAgencyDto, AgencyDto } from '@observatory/shared/gard/dtos';

export function useUpdateAgency() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  return {
    error,
    async updateAgency(data: UpdateAgencyDto) {
      const result = await fetcher('/api/agency/update', {
        method: 'PUT',
        body: data,
      });

      return handle<Array<AgencyDto>>(result);
    },
  };
}
