import { useAggregateError } from '@f-technology-srl/react-hook-utils';
import { ConnectedNavbar } from '@observatory/front-end/user-features';
import {
  BreadcrumbItemProps,
  ButtonPrimary,
  HeadPageButtonsContainer,
  LinkOutlined,
  Modal,
  NavbarContainer,
  PageWrapper,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import {
  CollectorDto,
  UpdateSubSectionDto,
} from '@observatory/shared/gard/dtos';
import { useParams } from 'react-router-dom';
import AddAgencyModalForm, {
  AddAgencyFormValues,
} from '../../components/add-agency-modal-form/add-agency-modal-form';
import AddSubSectionModalForm, {
  AddContactFormValues,
} from '../../components/add-sub-section-modal-form/add-sub-section-modal-form';
import AgenciesList from '../../components/agencies-list/agencies-list';
import EditDetailsModalForm, {
  EditDetailsModalFormProps,
} from '../../components/edit-details-modal-form/edit-details-modal-form';
import { SearchAndButtons } from '../../components/search-and-buttons/search-and-buttons';
import {
  useAddSubSection,
  useCreateAgency,
  useGetRateCollection,
} from '../../data-access';

/* eslint-disable-next-line */
export interface RateCollectionDetailProps {}

export interface EditDetailProps {
  rateCollDetail: CollectorDto['data'];
  onSubmit: EditDetailsModalFormProps['onSubmit'];
}

const EditDetails = ({ rateCollDetail, onSubmit }: EditDetailProps) => {
  return (
    <Modal
      triggerElement={<ButtonPrimary>EDIT DETAILS</ButtonPrimary>}
      title="Edit details"
      subtitle="This will auto-populate the templates whilst letting the agency change it"
      customWidth="970px"
    >
      {({ close }) => (
        <EditDetailsModalForm
          defaultValues={{
            clientName: rateCollDetail.client_name,
            clientBrand: rateCollDetail.client_brand,
            currentCurrency: rateCollDetail.currency,
            country: rateCollDetail.country,
            city: rateCollDetail.city,
            numberEmployes: rateCollDetail.number_of_employees,
            billableHoursYear: rateCollDetail.billable_hours_year,
            ownership: rateCollDetail.ownership,
            overhead: rateCollDetail.overhead,
            profitMargin: rateCollDetail.margin,
            agency_type: rateCollDetail.agency_type,
            dataType: rateCollDetail.rate_category,
          }}
          onSubmit={onSubmit}
          modalClose={close}
        />
      )}
    </Modal>
  );
};

const AddSubSection = (props: {
  subSections: CollectorDto['sub_sections'];
  createNewSubsection: (values: AddContactFormValues) => Promise<unknown>;
  editSubSection: (value: UpdateSubSectionDto) => Promise<unknown>;
  onDeleteSubSection: (id: string) => Promise<unknown>;
}) => {
  return (
    <Modal
      triggerElement={<ButtonPrimary>SUBSECTIONS</ButtonPrimary>}
      title="Subsections"
      contentMarginXs
    >
      {({ close }) => (
        <AddSubSectionModalForm
          onSubmit={(values) => props.createNewSubsection(values)}
          modalClose={close}
          subSections={props.subSections.map((sub) => ({
            label: sub.name,
            id: sub.id,
            disabled: false,
          }))}
          onChangeEditSubSection={(value) => props.editSubSection(value)}
          onDeleteSubSection={props.onDeleteSubSection}
        />
      )}
    </Modal>
  );
};

const AddAgency = (props: {
  subSections: CollectorDto['sub_sections'];
  createNewAgency: (values: AddAgencyFormValues) => Promise<unknown>;
}) => {
  return (
    <Modal
      triggerElement={<ButtonPrimary>ADD AGENCY</ButtonPrimary>}
      title="Add agency"
      subtitle="Please enter the information requested in the fields below."
    >
      {({ close }) => (
        <AddAgencyModalForm
          onSubmit={(values) => props.createNewAgency(values).then(close)}
          subSectionsDefault={props.subSections.map((sub) => sub.id)}
          subSectionsOptions={props.subSections.map((sub) => ({
            label: sub.name,
            value: sub.id,
          }))}
          modalClose={close}
        />
      )}
    </Modal>
  );
};

export function RateCollectionDetail(props: RateCollectionDetailProps) {
  const params = useParams();
  const rateCollectorId = params.rate_collection_id || '';

  const { showMessageWithTimeout, showMessage } = useAlertController();

  const {
    data,
    mutate,
    isError,
    update,
    errorMessage: updateErrorMessage,
  } = useGetRateCollection(rateCollectorId);

  const { addSubSection, renameSubSection, deleteSubSection } =
    useAddSubSection(rateCollectorId);

  const { createAndConnectToCollector } = useCreateAgency(rateCollectorId);

  const fetchErrorMessage = isError
    ? updateErrorMessage
      ? updateErrorMessage
      : 'Error during fetching rate collections'
    : undefined;

  const errorMessage = useAggregateError('danger', fetchErrorMessage);

  useSyncAlertControllerWithState(errorMessage, [
    errorMessage?.label,
    errorMessage?.status,
  ]);

  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: '/rate_collection',
      active: false,
      label: 'Rate collection list',
    },
    {
      link: '',
      active: true,
      label: 'Rate collection',
    },
  ];

  return (
    <PageWrapper title={data?.name} breadcrumbs={breadcrumbs} borderBottom>
      <HeadPageButtonsContainer>
        <LinkOutlined href={`/collector_analysis/${rateCollectorId}`}>
          PERFORM ANALYSIS
        </LinkOutlined>
        {data?.data && (
          <EditDetails
            rateCollDetail={data?.data}
            onSubmit={(formData) =>
              update({
                client_name: formData.clientName,
                client_brand: formData.clientBrand,
                currency: formData.currentCurrency,
                country: formData.country,
                city: formData.city,
                number_of_employees: formData.numberEmployes,
                billable_hours_year: formData.billableHoursYear,
                ownership: formData.ownership,
                overhead: formData.overhead,
                margin: formData.profitMargin,
                agency_type: formData.agency_type,
                rate_category: formData.dataType,
              })
                .then(() =>
                  showMessageWithTimeout({
                    label: 'Collector has been saved',
                    status: 'success',
                  })
                )
                .catch((error) => {
                  showMessage({ label: error.message, status: 'danger' });
                  throw error;
                })
            }
          />
        )}
      </HeadPageButtonsContainer>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <SearchAndButtons>
        <AddSubSection
          onDeleteSubSection={(id) =>
            deleteSubSection(id)
              .then(() => mutate())
              .then(() =>
                showMessageWithTimeout({
                  label: 'Sub section removed',
                  status: 'success',
                })
              )
              .catch((error) =>
                showMessage({ label: error.message, status: 'danger' })
              )
          }
          subSections={data?.sub_sections || []}
          createNewSubsection={(values) =>
            addSubSection({
              name: values.subSection,
              is_only_rate: values.is_only_rate,
            })
              .then(() => mutate())
              .then(() =>
                showMessageWithTimeout({
                  label: 'Sub section added',
                  status: 'success',
                })
              )
              .catch((error) =>
                showMessage({ label: error.message, status: 'danger' })
              )
          }
          editSubSection={(values) =>
            renameSubSection(values)
              .then(() => mutate())
              .then(() =>
                showMessageWithTimeout({
                  label: 'Sub section renamed',
                  status: 'success',
                })
              )
              .catch((error) =>
                showMessage({ label: error.message, status: 'danger' })
              )
          }
        />
        <AddAgency
          subSections={data?.sub_sections || []}
          createNewAgency={(values) =>
            createAndConnectToCollector({
              name: values.agencyName,
              selected_subsections: values.subSections,
            })
              .then(() => mutate())
              .then(() =>
                showMessageWithTimeout({
                  label: 'Agency added',
                  status: 'success',
                })
              )
              .catch((error) =>
                showMessage({ label: error.message, status: 'danger' })
              )
          }
        />
      </SearchAndButtons>
      <AgenciesList
        aviableSubSections={
          data?.sub_sections?.map((sub) => ({
            sub_section_id: sub.id,
            label: sub.name,
          })) || []
        }
        reloadAgencies={mutate}
        agencies={
          data?.agencies?.map((agency) => ({
            id: agency.id,
            name: agency.name,
            subs: agency.selected_subsections.map((sub) => ({
              sub_section_id: sub.subSection.id,
              completed: sub.completed,
              label: sub.subSection.name,
              historic: sub.historic,
            })),
          })) || []
        }
      />
    </PageWrapper>
  );
}

export default RateCollectionDetail;
