import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { AgencyDto } from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetAgency = (agency_id: string) => {
  const fetcher = useFetcher();
  const { error: actionError, handle } = useHandleRespError(false);

  const { data, error, mutate } = useSWR<AgencyDto>(
    `/api/agency/${agency_id}`,
    fetcher
  );

  return {
    data: handle(data),
    isLoading: !error && !data,
    isError: !!(error || actionError),
    errorMessage: actionError,
    mutate,
  };
};
