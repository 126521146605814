import { ConnectedNavbar } from '@observatory/front-end/user-features';
import {
  BreadcrumbItemProps,
  CommentText,
  Loader,
  NavbarContainer,
  PageWrapper,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FormAnalysis from '../../components/form-analysis/form-analysis';
import { useAnalysis } from '../../data-access';
import { LoadingContainer } from '../simple-analysis/simple-analysis';

/* eslint-disable-next-line */
export interface CollectorAnalysisProps {}

const Subtitle = styled(CommentText)<{ theme: GardThemeType }>`
  display: block;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.blue};
`;

export function CollectorAnalysis(props: CollectorAnalysisProps) {
  const navigate = useNavigate();
  const params = useParams();

  const rateCollectorId = params.rate_collector_id || '';

  const { loading, makeCompareAnalisys } = useAnalysis(rateCollectorId);

  const { showMessage } = useAlertController();

  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: `/rate_collection`,
      active: false,
      label: 'Rate collection list',
    },
    {
      link: `/rate_collection/detail/${rateCollectorId}`,
      active: false,
      label: 'Rate collection',
    },
    {
      link: '',
      active: true,
      label: 'Compare analysis',
    },
  ];

  return (
    <PageWrapper title="Performance analysis" breadcrumbs={breadcrumbs}>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <Subtitle>Please specify the filters for analysis</Subtitle>
      <FormAnalysis
        onSubmit={(values) =>
          makeCompareAnalisys(values)
            .then((result) =>
              navigate(
                `/collector_analysis/results/${result?.banchMarkResultId}/collector/${rateCollectorId}`
              )
            )
            .catch((err) => {
              showMessage({ label: err.message, status: 'danger' });
              throw err;
            })
        }
      />
      {loading ? (
        <LoadingContainer>
          <Loader type="circle" />
        </LoadingContainer>
      ) : null}
    </PageWrapper>
  );
}

export default CollectorAnalysis;
