import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { LinkText, PillText } from '../text/text';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface BreadcrumbItemProps {
  link: string;
  label: string;
  active?: boolean;
}

const Icon = styled.svg<{ theme: GardThemeType }>`
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 2px;
  width: 20px;
  height: 20px;
  stroke-linecap: round;
  stroke-linejoin: round;
  margin-left: ${(props) => props.theme.spacing.s};
  margin-right: ${(props) => props.theme.spacing.s};
`;

const StyledBreadcrumbItem = styled.li<{
  theme: GardThemeType;
  current?: boolean;
}>`
  list-style: none;
  display: flex;
  align-items: center;
  ${Icon} {
    visibility: ${(props) => (props.current ? 'hidden' : 'visible')};
  }
`;

const StyledLink = styled(Link)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.textHeading};

  > span {
    font-family: ${(props) => props.theme.fonts.italic};
  }
`;

const CurrentItem = styled(PillText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.italic};
  text-decoration: underline;
`;

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  return (
    <StyledBreadcrumbItem current={props.active}>
      {props.active ? (
        <CurrentItem>{props.label}</CurrentItem>
      ) : (
        <StyledLink to={props.link}>
          <LinkText>{props.label}</LinkText>
        </StyledLink>
      )}
      <Icon viewBox="0 0 24 24">
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </Icon>
    </StyledBreadcrumbItem>
  );
}

export default BreadcrumbItem;
