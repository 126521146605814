import {
  StateRedirectAuth,
  useLogin,
} from '@f-technology-srl/client-authorization';
import { Navigate, useLocation } from 'react-router-dom';
import {
  Login,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';

export const LoginPage: React.FC = () => {
  const location = useLocation();
  const { loading, login, loggedIn, error } = useLogin();

  const from = (location.state as StateRedirectAuth)?.from?.pathname || '/';

  useSyncAlertControllerWithState(
    error
      ? {
          label: error.message,
          status: 'danger',
        }
      : undefined,
    [error?.message]
  );

  if (!loading && loggedIn) {
    return <Navigate to={from} replace />;
  }

  return (
    <Login
      loading={loading}
      onSubmit={(credentials) => {
        login({
          username: credentials.username,
          password: credentials.password,
        });
      }}
    />
  );
};

export default LoginPage;
