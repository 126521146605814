import { useAggregateError } from '@f-technology-srl/react-hook-utils';
import {
  Loader,
  PageWrapper,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import {
  AgencyDto,
  DepartmentType,
  IsSubsectionNewDto,
  RateDto,
} from '@observatory/shared/gard/dtos';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { KeyedMutator } from 'swr';
import AddRatesSubForm, {
  RowInputsTypes,
} from '../../components/add-rates-sub-form/add-rates-sub-form';
import { useCreateRate, useGetAgency } from '../../data-access';

export interface RateCollectionSubSectionCreateRouteStateInterface {
  newlyCreated?: boolean;
  agency?: AgencyDto;
}

export function mapListIntoRate(
  type: DepartmentType,
  data: Array<RowInputsTypes>
): Array<RateDto> {
  return data.map((values) => ({
    department: type,
    role_generic_descriptor: values.role,
    role_bespoke_descriptor: values.jobTitle,
    perc_of_time: values.percentageTime / 100,
    rate: values.hourlyRate,
  }));
}

export function mapRatesFromForm(
  agencyManagement: Array<RowInputsTypes>,
  accountManagement: Array<RowInputsTypes>,
  projectManagement: Array<RowInputsTypes>,
  creative: Array<RowInputsTypes>,
  analytics: Array<RowInputsTypes>,
  production: Array<RowInputsTypes>,
  media: Array<RowInputsTypes>,
  other: Array<RowInputsTypes>
): Array<RateDto> {
  const result: Array<RateDto> = [];

  if (agencyManagement) {
    result.push(...mapListIntoRate('Agency Management', agencyManagement));
  }

  if (accountManagement) {
    result.push(...mapListIntoRate('Account Management', accountManagement));
  }

  if (projectManagement) {
    result.push(...mapListIntoRate('Project Management', projectManagement));
  }

  if (creative) {
    result.push(...mapListIntoRate('Creative', creative));
  }

  if (analytics) {
    result.push(
      ...mapListIntoRate('Analytics, Planning & Strategy', analytics)
    );
  }

  if (production) {
    result.push(...mapListIntoRate('Production & Technology', production));
  }

  if (media) {
    result.push(...mapListIntoRate('Media Buying', media));
  }

  if (other) {
    result.push(...mapListIntoRate('Other', other));
  }

  return result;
}

export const LoadingContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

export interface RateCollectionSubSectionCreateProps {
  mutateOnSubmit: KeyedMutator<IsSubsectionNewDto>;
}

export function RateCollectionSubSectionCreate(
  props: RateCollectionSubSectionCreateProps
) {
  const params = useParams();

  const agency_id = params?.agency_id || '';
  const sub_section_id = params?.sub_section_id || '';

  const { data: agency, errorMessage } = useGetAgency(agency_id);

  const subSection = agency?.selected_subsections?.find(
    (sub) => sub.subSection.id === sub_section_id
  );

  const { createRate, defaultData, error } = useCreateRate(
    agency_id,
    sub_section_id
  );

  const errors = useAggregateError('danger', error, errorMessage);
  const { showMessageWithTimeout, showMessage } = useAlertController();

  useSyncAlertControllerWithState(errors, [errors?.label, errors?.status]);

  return (
    <PageWrapper>
      {defaultData ? (
        <AddRatesSubForm
          agencyName={agency?.name}
          subSectionName={subSection?.subSection?.name}
          isRateOnly={subSection?.subSection?.is_only_rate}
          defaultValues={{
            clientName: defaultData.client_name,
            clientBrand: defaultData.client_brand,
            currency: defaultData.currency,
            country: defaultData.country,
            city: defaultData.city,
            profitMargin: defaultData.margin,
            dataType: defaultData.rate_category,
            numberOfEmployees: defaultData.number_of_employees,
            numberOfBillableInAWorkingYear: defaultData.billable_hours_year,
            overhead: defaultData.overhead,
            ownership: defaultData.ownership,
            agency_type: defaultData.agency_type,
          }}
          onSubmit={(values) =>
            createRate({
              client_name: values.clientName,
              client_brand: values.clientBrand,
              currency: values.currency,
              agency_country: values.country,
              agency_city: values.city,
              margin: values.profitMargin,
              rate_category: values.dataType,
              agency_size: values.numberOfEmployees,
              billable_hours: values.numberOfBillableInAWorkingYear,
              overhead: values.overhead,
              ownership: values.ownership,
              agency_type: values.agency_type,
              rates: mapRatesFromForm(
                values.agencyManagement,
                values.accountManagement,
                values.projectManagement,
                values.creative,
                values.analytics,
                values.production,
                values.media,
                values.other
              ),
            })
              .then(() => {
                showMessageWithTimeout(
                  {
                    label: 'Rate collection created',
                    status: 'success',
                  },
                  1500,
                  () => props.mutateOnSubmit()
                );
              })
              .catch((err) => {
                showMessage({ label: err.message, status: 'danger' });
                throw err;
              })
          }
        />
      ) : (
        <LoadingContainer>
          <Loader type="circle" />
        </LoadingContainer>
      )}
    </PageWrapper>
  );
}

export default RateCollectionSubSectionCreate;
