import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

export const H1 = styled.h1<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.h1};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  margin-top: 0;
`;

export const H2 = styled.h2<{ theme: GardThemeType; titleCenter: boolean }>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.h2};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  margin-top: 0;
  text-align: ${(props) => (props.titleCenter ? 'center' : 'left')};
`;

export const H3 = styled.h3<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.h3};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  margin-top: 0;
`;

export const H4 = styled.h4<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  margin-top: 0;
`;

export const H5 = styled.h5<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.textHeading};
  font-size: ${(props) => props.theme.fontSizes.h5};
  font-family: ${(props) => props.theme.fonts.regular};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  margin-top: 0;
`;

export default H1;
