import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import React, { ForwardedRef } from 'react';

/* eslint-disable-next-line */
export interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  hasArrow?: boolean;
  dataOpen?: boolean;
  size?: 'normal' | 'small';
}

const StyledButton = styled.button<{
  theme: GardThemeType;
  fullWidth?: boolean;
  size?: ButtonProps['size'];
}>`
  font-size: ${(props) => props.theme.fontSizes.button};
  font-family: ${(props) => props.theme.fonts.bold};
  padding: ${(props) =>
    props.size === 'small'
      ? `${props.theme.spacing.xxs} ${props.theme.spacing.xxs}`
      : `11px ${props.theme.spacing.m}`};
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonRef = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps> &
    Omit<React.HTMLProps<HTMLButtonElement>, 'type' | 'as' | 'size'> & {
      type?: 'button' | 'submit' | 'reset' | undefined;
    }
>(({ children, ...props }, ref: ForwardedRef<HTMLButtonElement>) => (
  <StyledButton {...props} ref={ref}>
    {children}
  </StyledButton>
));

export const ButtonOutlined = styled(ButtonRef)<{
  theme: GardThemeType;
  hasArrow?: boolean;
  button?: string;
  active?: boolean;
}>`
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.secondary};
  border: ${(props) =>
    `1px solid ${
      props.active ? props.theme.colors.white : props.theme.colors.secondary
    }`};
  background-color: ${(props) =>
    props.active ? props.theme.colors.secondary : 'transparent'};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  position: relative;
  :hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.colors.secondaryVariant
        : props.theme.colors.greyLight};
    border-color: ${(props) =>
      props.active
        ? props.theme.colors.greyLight
        : props.theme.colors.secondaryVariant};
    color: ${(props) =>
      props.active
        ? props.theme.colors.white
        : props.theme.colors.secondaryVariant};
  }
  ${(props) =>
    props.hasArrow
      ? `:after {
    content: "";
    display:inline-block!important;
    width:0;
    height:0;
    border-left: 4px solid transparent;
    border-top: 8px solid ${props.theme.colors.secondary};
    border-right: 4px solid transparent;
    position: absolute;
    right: 15px;
    top: 14px;
  }`
      : ``};
`;

export const ButtonPrimary = styled(ButtonRef)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  border: ${(props) => `1px solid ${props.theme.colors.secondary}`};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  :hover {
    background-color: ${(props) => props.theme.colors.secondaryVariant};
    border-color: ${(props) => props.theme.colors.secondaryVariant};
  }
`;

export const ButtonSecondary = styled(ButtonRef)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  border: ${(props) => `1px solid ${props.theme.colors.primary}`};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryVariant};
    border-color: ${(props) => props.theme.colors.primaryVariant};
  }
`;
