import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { CreateUserDto, UserDto } from '@observatory/shared/gard/dtos';

export function useAddUser() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  return {
    addUser: async (credential: CreateUserDto) => {
      const response = await fetcher(`/api/user/add`, {
        method: 'POST',
        body: credential,
      });

      return handle<UserDto>(response);
    },
    error,
  };
}
