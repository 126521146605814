import React from 'react';
import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

export interface SearchProps {
  placeholder: string;
  required?: boolean;
  invalid?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const Icon = styled.svg<{ theme: GardThemeType }>`
  fill: none;
  stroke: ${(props) => props.theme.colors.greyLight};
  stroke-width: 2px;
  width: 24px;
  height: 24px;
  stroke-linecap: round;
  stroke-linejoin: round;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSearch = styled.input<{ theme: GardThemeType }>`
  padding: 10px 30px 10px 10px;
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.greyLight}`};
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.regular};
  border-radius: 3px;
  width: 100%;

  :focus {
    border-color: ${(props) => props.theme.colors.primary};
  }

  :focus-visible {
    outline-color: ${(props) => props.theme.colors.primary};
  }
`;

const Container = styled.div<{ theme: GardThemeType }>`
  position: relative;
  display: flex;
`;

export function Search(
  props: SearchProps & React.HTMLAttributes<HTMLInputElement>
) {
  return (
    <Container>
      <StyledSearch {...props} />
      <Icon viewBox="0 0 24 24">
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </Icon>
    </Container>
  );
}

export default Search;
