import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import LogoImage from './gard-logo.png';
import HeadPage, { HeadPageProps } from '../head-page/head-page';

const PageWrapperContainer = styled.div<{
  theme: GardThemeType;
  noNavbar?: boolean;
}>`
  background-color: ${(props) => props.theme.colors.greyBackground};
  min-height: ${(props) => (props.noNavbar ? '100vh' : 'calc(100vh - 62px)')};
`;

const PageWrapperContent = styled.main<{
  noNavbar?: boolean;
  containerSize?: number;
}>`
  padding: 40px 65px;
  max-width: ${(props) =>
    props.containerSize ? `${props.containerSize}px` : '1680px'};
  margin: ${(props) => (props.noNavbar ? '0 auto 0' : '62px auto 0')};
  box-sizing: content-box;
`;

const GardLogo = styled.img`
  width: 250px;
  margin-bottom: 30px;
`;

export const NavbarContainer = (props: PropsWithChildren<unknown>) => {
  return props.children as JSX.Element;
};

export const HeadPageButtonsContainer = (props: PropsWithChildren<unknown>) => {
  return props.children as JSX.Element;
};

/**
 * PageWrapper component
 * This component is ment to create the base layout for the application,
 * it uses a child container pattern to manage the positioning of the elements
 *
 * @example
  ```html
    <PageWrapper title="Page of the day">
      <HeadPageButtonsContainer>
        {// Header buttons location}
        <Button>Hello I'm in the header</Button>
      </HeadPageButtonsContainer>
      <NavbarContainer>
        {// Navbar container}
        <Button>Hello I'm in the navbar</Button>
      </NavbarContainer>
    </PageWrapper>

  ```
 */
export const PageWrapper: React.FC<
  HeadPageProps & React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const childAsArray = React.Children.toArray(props.children);
  const NavBarChild = childAsArray.find((child) => {
    if (React.isValidElement(child)) {
      return child.type === NavbarContainer;
    }
    return false;
  });

  const HeadPageButtonsChild = childAsArray.find((child) => {
    if (React.isValidElement(child)) {
      return child.type === HeadPageButtonsContainer;
    }
    return undefined;
  });

  const nonNavAndHeadPageButtonChild = childAsArray.filter((child) => {
    if (React.isValidElement(child)) {
      return (
        child.type !== NavbarContainer &&
        child.type !== HeadPageButtonsContainer
      );
    }
    return true;
  });

  const hasNavbar = !!NavBarChild;

  return (
    <PageWrapperContainer noNavbar={!hasNavbar} className={props.className}>
      {hasNavbar && NavBarChild}
      <PageWrapperContent
        noNavbar={!hasNavbar}
        containerSize={props.containerSize}
      >
        {!hasNavbar && <GardLogo src={LogoImage} alt="Gard Logo" />}
        <HeadPage
          title={props.title}
          breadcrumbs={props.breadcrumbs}
          borderBottom={props.borderBottom}
          titleCenter={props.titleCenter}
        >
          {HeadPageButtonsChild}
        </HeadPage>
        {nonNavAndHeadPageButtonChild}
      </PageWrapperContent>
    </PageWrapperContainer>
  );
};

export default PageWrapper;
