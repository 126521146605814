import { IsArray, IsString, Length } from 'class-validator';

export class CreateAgencyDto {
  @IsString()
  @Length(3, 50, {
    message: 'Agency name must be at least 5 and less than 50 characters',
  })
  name: string;

  @IsArray()
  selected_subsections: Array<string>;
}
