import useSWR from 'swr';
import { useFetcher } from '@f-technology-srl/client-authorization';
import { PaginatedUserDto } from '@observatory/shared/gard/dtos';

export const useGetUsers = (page = 0, offset = 20) => {
  const fetcher = useFetcher();
  const { data, mutate, error } = useSWR<PaginatedUserDto>(
    `/api/user?page=${page}&offset=${offset}`,
    fetcher
  );

  return {
    data: data,
    mutate,
    isLoading: !error && !data,
    isError: error as Error,
  };
};
