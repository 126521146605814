import React from 'react';
import styled from 'styled-components';
import {
  ButtonText,
  CtaCard,
  InfoBlock,
  InfoIcon,
  InfoText,
  NavbarContainer,
  PageWrapper,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ConnectedNavbar } from '@observatory/front-end/user-features';

const CtaCardContainer = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;

  > *:nth-child(1) {
    margin-right: 40px;
  }
`;

const InfoBlockTitle = styled(ButtonText)`
  display: flex;
  align-items: center;
`;

const InfoBlockText = styled(InfoText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const InfoIconTitle = styled(InfoIcon)`
  margin-right: 16px;
`;

export const HomePage: React.FC = () => {
  return (
    <PageWrapper
      title="Global Agency Rates Database"
      containerSize={882}
      titleCenter
    >
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <CtaCardContainer>
        <CtaCard
          title="Data entry"
          text="To upload agency rates and time allocations or to create and view client projects, click here."
          ctaLabel="RATE COLLECTION"
          ctaHref="/rate_collection"
        ></CtaCard>
        <CtaCard
          title="Data analysis"
          text="To choose the filters to apply to<br/>data and view resulting benchmarks,<br/>click here."
          ctaLabel="PERFORM ANALYSIS"
          ctaHref="/simple_analysis"
        ></CtaCard>
      </CtaCardContainer>
      <InfoBlock>
        <InfoBlockTitle>
          <InfoIconTitle />
          Confidentially of Information
        </InfoBlockTitle>
        <InfoBlockText>
          We believe in protecting the privacy of agencies fee data submissions.
          Security measures are in place to protect access to our database which
          is restricted to personnel employed by The Observatory International
          only. Individual agency fee information will not be disclosed to any
          outside parties (e.g. clients of The Observatory International)
          without the express written agreement of the agency.
        </InfoBlockText>
        <InfoBlockTitle>
          <InfoIconTitle /> Data Protection
        </InfoBlockTitle>
        <InfoBlockText>
          Insofar as any data provided contains personal data, we will act in
          accordance with the Data Protection Act. Personal data will not be
          shared with any third party without consent. The Observatory
          International Limited is registered with the Information Commissioner
          s Office.
        </InfoBlockText>
      </InfoBlock>
    </PageWrapper>
  );
};

export default HomePage;
