import { IsEmail, IsOptional, IsString, Length } from 'class-validator';

export class UpdateContactDto {
  @IsString()
  id: string;

  @IsString()
  @Length(3, 50, {
    message: 'Contact name must be at least 5 and less than 50 characters',
  })
  @IsOptional()
  name?: string;

  @IsString()
  @IsEmail()
  @IsOptional()
  email?: string;
}
