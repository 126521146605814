export type DepartmentType =
  | 'Project Management'
  | 'Other'
  | 'Media Buying'
  | 'Production & Technology'
  | 'Account Management'
  | 'Analytics, Planning & Strategy'
  | 'Agency Management'
  | 'Creative';

export const DepartmentEnumList = [
  'Project Management',
  'Other',
  'Media Buying',
  'Production & Technology',
  'Account Management',
  'Analytics, Planning & Strategy',
  'Agency Management',
  'Creative',
];
