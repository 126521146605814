import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';

export function useMakeSubsectionHistoric() {
  const fetcher = useFetcher();
  const { error, handle, reset } = useHandleRespError();

  return {
    error,
    reset,
    async makeHistoric(agency_id: string, sub_section_id: string) {
      const result = await fetcher(
        `/api/sub-section/historicize/sub_section/${sub_section_id}/agency/${agency_id}`,
        {
          method: 'POST',
          body: {},
        }
      );

      return handle<{ success: boolean; created: number }>(result);
    },
    async rollbackHistoric(agency_id: string, sub_section_id: string) {
      const result = await fetcher(
        `/api/sub-section/rollback-historicize/sub_section/${sub_section_id}/agency/${agency_id}`,
        {
          method: 'POST',
          body: {},
        }
      );

      return handle<{ success: boolean; removed: number }>(result);
    },
  };
}
