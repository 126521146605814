import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  ButtonOutlined,
  DialogButtons,
  InputFormInput,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { CreateUserDto, UserRolesDto } from '@observatory/shared/gard/dtos';
import { DialogClose } from '@radix-ui/react-dialog';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

export interface AddUserModalFormProps<ResultData> {
  onSubmit: (values: CreateUserDto) => Promise<ResultData>;
  modalClose: () => void;
}

const AddUserModalFormContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;

  > * {
    flex: 1;

    &:first-of-type {
      margin-right: ${(props) => props.theme.spacing.m};
    }
  }
`;

export function AddUserModalForm<ResultData>(
  props: AddUserModalFormProps<ResultData>
) {
  const formMethods = useForm<CreateUserDto>({
    resolver: classValidatorResolver(CreateUserDto),
    defaultValues: {
      username: '',
      password: '',
      role: UserRolesDto.USER,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <AddUserModalFormContainer>
          <InputFormInput
            label="Email"
            name="username"
            type="text"
            placeholder="Email"
            required
          />
          <InputFormInput
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            required
          />
        </AddUserModalFormContainer>
        <DialogButtons>
          <SubmitButton
            disabled={formMethods.formState.isSubmitting}
            type="submit"
          >
            Save
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Close</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      </form>
    </FormProvider>
  );
}

export default AddUserModalForm;
