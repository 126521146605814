import { ButtonPrimary } from '@observatory/front-end/core-ui';
import { GardTheme } from '@observatory/front-end/gard-theme';
import { TableAnalysisContainer } from '../table-analysis-container/table-analysis-container';
import TableTitle from '../table-title/table-title';
import {
  SunburstChart,
  useSvgToPng,
  ZoomableSunBurstData,
} from '@observatory/front-end/charts';

export interface TableProjectGraphProps {
  title: string;
  weight?: string;
  data: ZoomableSunBurstData;
  id?: string;
}

export function TableProjectGraph(props: TableProjectGraphProps) {
  const { props: graphProps, exportToPng } = useSvgToPng();
  return (
    <TableAnalysisContainer id={props.id}>
      <TableTitle
        title={props.title}
        weight={props.weight}
        background={GardTheme.colors.secondary}
      ></TableTitle>
      <div style={{ padding: '0 20px 20px' }}>
        <ButtonPrimary
          style={{ marginLeft: '55px', marginTop: '33px' }}
          onClick={exportToPng}
        >
          Export my D3 visualization to PNG
        </ButtonPrimary>
        <SunburstChart
          data={props.data}
          style={{ height: 500 }}
          {...graphProps}
        />
      </div>
    </TableAnalysisContainer>
  );
}

export default TableProjectGraph;
