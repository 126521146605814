import { Table, TableDescription } from '@observatory/front-end/core-ui';
import { GardTheme, GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import { TableAnalysisContainer } from '../table-analysis-container/table-analysis-container';
import TableTitle from '../table-title/table-title';

export interface TableProjectFeeProps<DataType> {
  color?: string;
  background?: string;
  title: string;
  weight?: string;
  data?: Array<DataType>;
  colDescription?: Array<TableDescription<DataType>>;
  id?: string;
}

export function TableProjectFee<DataType>(
  props: TableProjectFeeProps<DataType>
) {
  return (
    <TableAnalysisContainer id={props.id}>
      <TableTitle
        title={props.title}
        weight={props.weight}
        background={GardTheme.colors.secondary}
      ></TableTitle>
      <Table
        data={props.data}
        colDescription={props.colDescription}
        numColumnSticky={2}
        headerSticky
      />
    </TableAnalysisContainer>
  );
}

export default TableProjectFee;
