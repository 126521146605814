import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  NewSubsectionDto,
  CollectorDto,
  UpdateSubSectionDto,
  SubSectionDto,
} from '@observatory/shared/gard/dtos';

export function useAddSubSection(collector_id: string) {
  const fetcher = useFetcher();
  const { error, handle, reset } = useHandleRespError(false);

  return {
    error,
    resetError: reset,
    async addSubSection(data: NewSubsectionDto) {
      const result = await fetcher(
        `/api/rate-collection/sub-section/${collector_id}`,
        {
          method: 'POST',
          body: data,
        }
      );

      return handle<Array<CollectorDto>>(result);
    },
    async renameSubSection(data: UpdateSubSectionDto) {
      const result = await fetcher(`/api/sub-section/rename`, {
        method: 'PUT',
        body: data,
      });

      return handle<SubSectionDto>(result);
    },
    async deleteSubSection(sub_section_id: string) {
      const result = await fetcher(`/api/sub-section/${sub_section_id}`, {
        method: 'DELETE',
        body: {},
      });

      return handle<SubSectionDto>(result);
    },
  };
}
