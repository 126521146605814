import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';

export interface DividerProps {
  marginBottom?: string;
}

export const Divider = styled.div<{
  theme: GardThemeType;
  marginBottom?: string;
}>`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '48px'};
  background-color: ${(props) => props.theme.colors.secondary};
  height: 1px;
`;

export default Divider;
