import { IsString, Length } from 'class-validator';

export class UpdateSubSectionDto {
  @IsString()
  sub_section_id: string;

  @IsString()
  @Length(3, 50, {
    message: 'Subsection name length should be between 5 and 50 characters',
  })
  name?: string;
}
