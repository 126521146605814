import styled from 'styled-components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ButtonOutlined, ButtonPrimary } from '../button/button';

export interface ModalButtonsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnClick?: (values: any) => void | Promise<void>;
}

export const DialogButtons = styled.div`
  margin-top: 40px;
`;

export const SubmitButton = styled(ButtonPrimary)<{ theme: GardThemeType }>`
  margin-right: ${(props) => props.theme.spacing.s};
`;

const DialogClose = DialogPrimitive.Close;

export function ModalButtons(props: ModalButtonsProps) {
  return (
    <DialogButtons>
      <SubmitButton type="submit">Add</SubmitButton>
      <DialogClose asChild>
        <ButtonOutlined>Close</ButtonOutlined>
      </DialogClose>
    </DialogButtons>
  );
}

export default ModalButtons;
