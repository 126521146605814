import {
  ButtonIcon,
  ButtonOutlined,
  DialogButtons,
  Modal,
  SubmitButton,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { DialogClose } from '@radix-ui/react-dialog';
import { useDeleteUser } from '../hooks';

export interface DeleteUserModalProps {
  userId: string;
  username: string;
  mutate: () => unknown;
}

export function DeleteUserModal(props: DeleteUserModalProps) {
  const { deleteUser, error } = useDeleteUser();

  useSyncAlertControllerWithState(
    error
      ? {
          label: error,
          status: 'danger',
        }
      : undefined,
    [error]
  );

  return (
    <Modal
      triggerElement={<ButtonIcon type="delete" />}
      title={`Delete '${props.username}'`}
      subtitle="Are you sure you want to delete this user? It won’t be able to access the GARD anymore and can’t be restored. "
    >
      {({ close }) => (
        <DialogButtons>
          <SubmitButton
            onClick={() =>
              deleteUser(props.userId).then(() => {
                props.mutate();
                close();
              })
            }
          >
            Confirm
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Cancel</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      )}
    </Modal>
  );
}

export default DeleteUserModal;
