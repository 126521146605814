import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import { ButtonSecondary } from '../button/button';
import { H4 } from '../headers/header';
import { LinkSecondary } from '../link/link';
import { ParagraphText } from '../text/text';

/* eslint-disable-next-line */
export interface CtaCardProps {
  title: string;
  text: string;
  ctaLabel: string;
  ctaOnClick?: () => void;
  ctaHref?: string;
}

const CtaCardContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 2px;
  padding: 30px 38px;
  width: 50%;
`;

const CtaCardTitle = styled(H4)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.white};
  margin: 0;
  text-align: center;
`;

const CtaCardText = styled(ParagraphText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.lavender};
  text-align: center;
  padding: 8px 0 16px 0;
  margin-bottom: auto;
`;

export function CtaCard(props: CtaCardProps) {
  return (
    <CtaCardContainer>
      <CtaCardTitle>{props.title}</CtaCardTitle>
      <CtaCardText
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></CtaCardText>
      {props.ctaOnClick && (
        <ButtonSecondary onClick={props.ctaOnClick}>
          {props.ctaLabel}
        </ButtonSecondary>
      )}
      {props.ctaHref && (
        <LinkSecondary href={props.ctaHref}>{props.ctaLabel}</LinkSecondary>
      )}
    </CtaCardContainer>
  );
}

export default CtaCard;
