import { OptionDto } from '@observatory/shared/gard/dtos';

export function orderOptions(type: string, data: Array<OptionDto>) {
  if (type === 'agency-sizes') {
    const ordering = [
      '1 to 10',
      '11 to 50',
      '51 to 100',
      '101 to 250',
      '251 to 400',
      '401 +',
    ];

    data.sort((a, b) => ordering.indexOf(a.value) - ordering.indexOf(b.value));
  }

  return data;
}
