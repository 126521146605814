import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ButtonPrimary } from '../button/button';
import { InputFormInput, ErrorContainer } from '../input/input';
import { H4 } from '../headers/header';
// import { SwitchFormInput } from '../switch/switch';
import Logo from '../logo/logo';
import { ErrorText } from '../text/text';
import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

export interface LoginProps {
  loginError?: boolean;
  loading?: boolean;
  onSubmit: (values: LoginFormValues) => void | Promise<void>;
}

const LoginContainer = styled.div<{ theme: GardThemeType }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const LoginContent = styled.div<{ theme: GardThemeType }>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 390px;
  width: 100%;
  height: auto;
  z-index: 2;
`;
const LoginCard = styled.div<{ theme: GardThemeType }>`
  background-color: white;
  margin-top: ${(props) => props.theme.spacing.m};
  padding: ${(props) => `${props.theme.spacing.ml} ${props.theme.spacing.m}`};
`;
const Overlay = styled.div<{ theme: GardThemeType }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const InputContainer = styled.div<{ theme: GardThemeType }>`
  margin-bottom: ${(props) => props.theme.spacing.s};
`;
const Spacer = styled.div<{ theme: GardThemeType }>`
  height: ${(props) => props.theme.spacing.xxs};
`;
const schema = Joi.object({
  scope: Joi.string().required(),
  username: Joi.string()
    .min(5)
    .message('Username must be at least 5 charachters')
    .max(100)
    .message('Username can be max 100 charachters')
    .required(),
  password: Joi.string().min(5).max(50).required(),
});

/* const LoginTypeOptions = ['Admin', 'Client']; */

type LoginFormValues = {
  username: string;
  password: string;
  scope: 'Admin' | 'Client';
};

export function Login(props: React.PropsWithChildren<LoginProps>) {
  const formMethods = useForm<LoginFormValues>({
    defaultValues: { scope: 'Client' },
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
  });

  return (
    <LoginContainer>
      <Overlay />
      <LoginContent>
        <Logo />
        <LoginCard>
          <H4>LOGIN</H4>
          <Spacer />
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
              <InputContainer>
                {/* <SwitchFormInput name="scope" options={LoginTypeOptions} /> */}
              </InputContainer>
              <InputContainer>
                <InputFormInput
                  label="Username"
                  name="username"
                  placeholder={'Username'}
                  type={'text'}
                  data-testid="login-username"
                  required
                />
              </InputContainer>
              <InputContainer>
                <InputFormInput
                  label="Password"
                  name="password"
                  placeholder={'Password'}
                  type={'password'}
                  required
                  data-testid="login-password"
                />
              </InputContainer>
              {props.loginError && (
                <ErrorContainer center>
                  <ErrorText>
                    This user does not exist, check username or password.
                  </ErrorText>
                </ErrorContainer>
              )}
              <ButtonPrimary
                disabled={false}
                fullWidth
                type="submit"
                data-testid="login-button"
              >
                Login
              </ButtonPrimary>
            </form>
          </FormProvider>
        </LoginCard>
      </LoginContent>
    </LoginContainer>
  );
}

export default Login;
