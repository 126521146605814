import { useAggregateError } from '@f-technology-srl/react-hook-utils';
import {
  PageWrapper,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import {
  CollectorRateDto,
  DepartmentType,
  RateDto,
} from '@observatory/shared/gard/dtos';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AddRatesSubForm, {
  AddRatesSubFormValues,
  RowInputsTypes,
} from '../../components/add-rates-sub-form/add-rates-sub-form';
import { useGetAgency, useGetRate } from '../../data-access';
import {
  mapRatesFromForm,
  RateCollectionSubSectionCreateRouteStateInterface,
} from '../rate-collection-sub-section-create/rate-collection-sub-section-create';

export function filterForDepartment(type: DepartmentType) {
  return (item: RateDto) => item.department === type;
}

export function rateToRowInput(item: RateDto): RowInputsTypes {
  return {
    role: item.role_generic_descriptor,
    jobTitle: item.role_bespoke_descriptor,
    percentageTime: item.perc_of_time * 100,
    hourlyRate: item.rate,
  };
}

export function mapToDefaultValues(
  apiData: CollectorRateDto
): AddRatesSubFormValues {
  const agencyManagement = apiData.rates
    .filter(filterForDepartment('Agency Management'))
    .map(rateToRowInput);
  const accountManagement = apiData.rates
    .filter(filterForDepartment('Account Management'))
    .map(rateToRowInput);
  const projectManagement = apiData.rates
    .filter(filterForDepartment('Project Management'))
    .map(rateToRowInput);
  const creative = apiData.rates
    .filter(filterForDepartment('Creative'))
    .map(rateToRowInput);
  const analytics = apiData.rates
    .filter(filterForDepartment('Analytics, Planning & Strategy'))
    .map(rateToRowInput);
  const production = apiData.rates
    .filter(filterForDepartment('Production & Technology'))
    .map(rateToRowInput);
  const media = apiData.rates
    .filter(filterForDepartment('Media Buying'))
    .map(rateToRowInput);
  const other = apiData.rates
    .filter(filterForDepartment('Other'))
    .map(rateToRowInput);
  return {
    clientName: apiData.client_name,
    clientBrand: apiData.client_brand,
    currency: apiData.currency,
    country: apiData.agency_country,
    city: apiData.agency_city,
    profitMargin: apiData.margin,
    dataType: apiData.rate_category,
    numberOfEmployees: apiData.agency_size,
    numberOfBillableInAWorkingYear: apiData.billable_hours,
    overhead: apiData.overhead,
    ownership: apiData.ownership,
    agency_type: apiData.agency_type,
    agencyManagement,
    accountManagement,
    projectManagement,
    creative,
    analytics,
    production,
    media,
    other,
  };
}

/* eslint-disable-next-line */
export interface RateCollectionSubSectionEditProps {}

export function RateCollectionSubSectionEdit(
  props: RateCollectionSubSectionEditProps
) {
  const location = useLocation();
  const locState =
    location.state as RateCollectionSubSectionCreateRouteStateInterface;

  const params = useParams();

  const agency_id = params?.agency_id || '';
  const sub_section_id = params?.sub_section_id || '';

  const { data, errorMessage, updateRate } = useGetRate(
    agency_id,
    sub_section_id
  );

  const { data: agency, errorMessage: getAgencyError } =
    useGetAgency(agency_id);

  const errorMsg = useAggregateError('danger', errorMessage, getAgencyError);

  const subSection = agency?.selected_subsections?.find(
    (sub) => sub.subSection?.id === sub_section_id
  );

  const { showMessageWithTimeout, showMessage } = useAlertController();

  useSyncAlertControllerWithState(errorMsg, [
    errorMsg?.status,
    errorMsg?.label,
  ]);

  useEffect(() => {
    if (locState?.newlyCreated) {
      showMessageWithTimeout({
        status: 'success',
        label: 'Created successfully',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locState?.newlyCreated]);

  return (
    <PageWrapper>
      {data && (
        <AddRatesSubForm
          defaultValues={mapToDefaultValues(data)}
          agencyName={agency?.name}
          subSectionName={subSection?.subSection?.name}
          isRateOnly={subSection?.subSection?.is_only_rate}
          onSubmit={(values) =>
            updateRate({
              collector_rate_id: data.id,
              client_name: values.clientName,
              client_brand: values.clientBrand,
              currency: values.currency,
              agency_country: values.country,
              agency_city: values.city,
              margin: values.profitMargin,
              rate_category: values.dataType,
              agency_size: values.numberOfEmployees,
              billable_hours: values.numberOfBillableInAWorkingYear,
              overhead: values.overhead,
              ownership: values.ownership,
              agency_type: values.agency_type,
              rates: mapRatesFromForm(
                values.agencyManagement,
                values.accountManagement,
                values.projectManagement,
                values.creative,
                values.analytics,
                values.production,
                values.media,
                values.other
              ),
            })
              .then(() => {
                showMessageWithTimeout({
                  status: 'success',
                  label: 'Updated successfully',
                });
              })
              .catch((err) => {
                showMessage({ label: err.message, status: 'danger' });
                throw err;
              })
          }
        />
      )}
    </PageWrapper>
  );
}

export default RateCollectionSubSectionEdit;
