import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import BreadcrumbItem, {
  BreadcrumbItemProps,
} from '../breadcrumbs/breadcrumb-item';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import { H2 } from '../headers/header';

/* eslint-disable-next-line */
export interface HeadPageProps {
  title?: string;
  breadcrumbs?: Array<BreadcrumbItemProps>;
  borderBottom?: boolean;
  containerSize?: number;
  titleCenter?: boolean;
}

const HeadPageContainer = styled.div``;

const HeadPageContent = styled.div<{
  borderBottom?: boolean;
  theme: GardThemeType;
  titleCenter?: boolean;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.titleCenter ? 'center' : 'space-between'};
  align-items: center;
  margin-bottom: ${(props) => (props.borderBottom ? '15px' : '0')};
  border-bottom: ${(props) =>
    props.borderBottom ? `1px solid ${props.theme.colors.secondary}` : '0'};
`;

const HeadPageButtons = styled.div<{ theme: GardThemeType }>`
  > :not(div, :first-child) {
    margin-left: ${(props) => props.theme.spacing.sm};
  }
`;

export function HeadPage(props: React.PropsWithChildren<HeadPageProps>) {
  return (
    <HeadPageContainer>
      {props.breadcrumbs ? (
        <Breadcrumbs>
          {props.breadcrumbs.map((item, key) => {
            return (
              <BreadcrumbItem
                link={item.link}
                label={item.label}
                active={item.active}
                key={key}
              />
            );
          })}
        </Breadcrumbs>
      ) : null}
      <HeadPageContent
        borderBottom={props.borderBottom}
        titleCenter={props.titleCenter || false}
      >
        <H2 titleCenter={props.titleCenter || false}>{props.title}</H2>
        {props.children && <HeadPageButtons>{props.children}</HeadPageButtons>}
      </HeadPageContent>
    </HeadPageContainer>
  );
}

export default HeadPage;
