export type RoleGenericDescriptorType =
  | 'Other Production - Middle (Lower)'
  | 'Print Production - Senior'
  | 'Department Head'
  | 'Other Production - Middle (Upper)'
  | 'CEO'
  | 'Other (e.g. Partner, COO, etc.)'
  | 'Technology - Middle (Lower)'
  | 'Digital Production - Middle (Lower)'
  | 'Broadcast Production - Middle (Upper)'
  | 'Other Production - Junior'
  | 'Middle (Upper)'
  | 'Technology - Middle (Upper)'
  | 'Technology - Junior'
  | 'Technology - Senior'
  | 'Print Production - Middle (Upper)'
  | 'Broadcast Production - Junior'
  | 'Print Production - Junior'
  | 'Broadcast Production - Middle (Lower)'
  | 'Broadcast Production - Senior'
  | 'President'
  | 'Department Overall'
  | 'Digital Production - Senior'
  | 'Digital Production - Middle (Upper)'
  | 'Managing Director'
  | 'Print Production - Middle (Lower)'
  | 'Digital Production - Junior'
  | 'Other Production - Senior'
  | 'Junior'
  | 'Senior'
  | 'Chairman'
  | 'Middle (Lower)'
  | 'Broadcast Production - Middle (Upper)'
  | 'Broadcast Production - Middle (Lower)'
  | 'Print Production - Middle (Upper)'
  | 'Print Production - Middle (Lower)'
  | 'Digital Production - Middle (Upper)'
  | 'Digital Production - Middle (Lower)'
  | 'Other Production - Middle (Upper)'
  | 'Other Production - Middle (Lower)';

export const RoleGenericDescriptorEnumList = [
  'Middle (Upper)',
  'Other Production - Senior',
  'Digital Production - Junior',
  'Other Production - Middle (Lower)',
  'Print Production - Senior',
  'Other Production - Middle (Upper)',
  'Department Head',
  'Other (e.g. Partner, COO, etc.)',
  'CEO',
  'Technology - Middle (Lower)',
  'Digital Production - Middle (Lower)',
  'Broadcast Production - Middle (Upper)',
  'Other Production - Junior',
  'Middle (Upper)',
  'Technology - Middle (Upper)',
  'Technology - Junior',
  'Technology - Senior',
  'Print Production - Middle (Upper)',
  'Broadcast Production - Junior',
  'Print Production - Junior',
  'Broadcast Production - Middle (Lower)',
  'Broadcast Production - Senior',
  'President',
  'Department Overall',
  'Digital Production - Senior',
  'Digital Production - Middle (Upper)',
  'Managing Director',
  'Print Production - Middle (Lower)',
  'Junior',
  'Senior',
  'Chairman',
  'Middle (Lower)',
  'Broadcast Production - Middle (Upper)',
  'Broadcast Production - Middle (Lower)',
  'Print Production - Middle (Upper)',
  'Print Production - Middle (Lower)',
  'Digital Production - Middle (Upper)',
  'Digital Production - Middle (Lower)',
  'Other Production - Middle (Upper)',
  'Other Production - Middle (Lower)',
  'Middle (Upper)',
];
