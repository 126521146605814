import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useMemo } from 'react';
import styled from 'styled-components';
import { LabelText } from '../text/text';

/* eslint-disable-next-line */
export interface TableProps<DataType> {
  data?: Array<DataType>;
  colDescription?: Array<TableDescription<DataType>>;
  numColumnSticky?: number;
  headerSticky?: boolean;
  bgTransparent?: boolean;
  smallPadding?: boolean;
}

export interface TableDescription<DataType> {
  headerName: string;
  headerComponent?: () => JSX.Element;
  percSize?: number;
  component: (row?: DataType) => JSX.Element;
  collapsedKey?: (row?: DataType) => string | undefined;
  display?: string;
  justifyContentItem?: 'flex-start' | 'center' | 'flex-end';
  csv?: (row?: DataType) => string | undefined | number;
}

const TableContainer = styled.div<{ sticky?: boolean }>`
  * {
    word-break: break-word;
  }

  overflow: auto;
  max-height: ${(props) => (props.sticky ? '70vh' : 'none')};
`;

const TableHeader = styled.div<{ minWidth: string; sticky?: boolean }>`
  display: flex;
  min-width: ${(props) => props.minWidth};
  position: ${(props) => (props.sticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 1;
`;

const TableHeaderItem = styled.div<{
  theme: GardThemeType;
  width: number;
  textAlign?: string;
  sticky?: boolean;
  stickyOffset?: number;
  borderRight?: boolean;
  bgTransparent?: boolean;
}>`
  width: ${(props) => props.width}%;
  max-width: 100%;
  flex: 1 1 ${(props) => props.width}%;
  padding: 5px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.greyLight};
  text-align: ${(props) => props.textAlign};
  background-color: ${(props) =>
    props.bgTransparent ? 'transparent' : props.theme.colors.white};
  position: ${(props) => (props.sticky ? 'sticky' : 'static')};
  left: ${(props) => props.stickyOffset}px;
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${props.theme.colors.greyLight}` : 'none'};
`;

const TableHeaderName = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
`;

const TableBody = styled.div<{ minWidth: string }>`
  min-width: ${(props) => props.minWidth};
`;

const TabledBodyRow = styled.div`
  display: flex;
`;

const TabledBodyItem = styled.div<{
  theme: GardThemeType;
  width: number;
  textAlign?: string;
  color?: string;
  sticky?: boolean;
  stickyOffset?: number;
  borderRight?: boolean;
  bgTransparent?: boolean;
  smallPadding?: boolean;
  justifyContentItem?: 'flex-start' | 'center' | 'flex-end';
}>`
  width: ${(props) => props.width}%;
  max-width: 100%;
  flex: 1 1 ${(props) => props.width}%;
  padding: ${(props) => (props.smallPadding ? '4px 16px' : '16px')};
  border-bottom: 1px solid ${(props) => props.theme.colors.greyLight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  background-color: ${(props) =>
    props.bgTransparent ? 'transparent' : props.theme.colors.white};
  position: ${(props) => (props.sticky ? 'sticky' : 'static')};
  left: ${(props) => props.stickyOffset}px;
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${props.theme.colors.greyLight}` : 'none'};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContentItem ? props.justifyContentItem : 'flex-start'};
`;

export function Table<DataType>(props: TableProps<DataType>) {
  const minRows = 1;
  const emptyRows = props.data ? minRows - props.data.length : minRows;

  const getMinRows = () => {
    let rows: Array<JSX.Element> = [];

    const EmptyRowCmp = (number: number) => (
      <div key={number}>
        {props.colDescription ? (
          <TabledBodyRow>
            {props.colDescription.map((_, index) => {
              return (
                <TabledBodyItem
                  key={index}
                  width={colDescriptionSizes[index]}
                  bgTransparent={props.bgTransparent}
                ></TabledBodyItem>
              );
            })}
          </TabledBodyRow>
        ) : null}
      </div>
    );

    for (let i = 0; i < emptyRows; i++) {
      rows = [...rows, EmptyRowCmp(i)];
    }

    return rows;
  };

  const colDescriptionSizes = useMemo(() => {
    const sizes = [];
    const aviableSpacePerc =
      props.colDescription?.reduce((acc, { percSize }) => {
        if (percSize) {
          return acc - percSize;
        }
        return acc;
      }, 100) || 100;

    const normalSize = props.colDescription
      ? aviableSpacePerc / props.colDescription.length
      : aviableSpacePerc;

    for (const { percSize } of props.colDescription || []) {
      if (percSize) {
        sizes.push(percSize);
      } else {
        sizes.push(normalSize);
      }
    }
    return sizes;
  }, [props.colDescription]);

  let prevVal: string | undefined;

  return (
    <TableContainer role="table" sticky={props.headerSticky}>
      <TableHeader
        minWidth={
          props.colDescription
            ? `${150 * props.colDescription.length}px`
            : 'none'
        }
        role="row"
        sticky={props.headerSticky}
      >
        {props.colDescription
          ? props.colDescription.map(
              ({ headerName, headerComponent, component }, index) => {
                return (
                  <TableHeaderItem
                    key={index}
                    width={colDescriptionSizes[index]}
                    textAlign={
                      props?.data
                        ? component(props.data[index]).props.textAlign
                        : 'left'
                    }
                    role="columnheader"
                    sticky={
                      props.numColumnSticky && index < props.numColumnSticky
                        ? true
                        : false
                    }
                    stickyOffset={150 * index}
                    borderRight={
                      !!(
                        props.numColumnSticky &&
                        index === props.numColumnSticky - 1
                      )
                    }
                    bgTransparent={props.bgTransparent}
                  >
                    {headerComponent ? (
                      headerComponent()
                    ) : (
                      <TableHeaderName>{headerName}</TableHeaderName>
                    )}
                  </TableHeaderItem>
                );
              }
            )
          : null}
      </TableHeader>
      <TableBody
        minWidth={
          props.colDescription
            ? `${150 * props.colDescription.length}px`
            : 'none'
        }
        role="rowgroup"
      >
        {props.data
          ? props.data.map((data, index) => {
              return (
                <TabledBodyRow key={index} role="row" aria-rowindex={index}>
                  {props.colDescription
                    ? props.colDescription.map(
                        (
                          { component, collapsedKey, justifyContentItem },
                          key
                        ) => {
                          if (collapsedKey && prevVal === collapsedKey(data)) {
                            return (
                              <TabledBodyItem
                                key={key}
                                width={colDescriptionSizes[key]}
                                role="cell"
                                sticky={
                                  props.numColumnSticky &&
                                  key < props.numColumnSticky
                                    ? true
                                    : false
                                }
                                stickyOffset={150 * key}
                                borderRight={
                                  !!(
                                    props.numColumnSticky &&
                                    key === props.numColumnSticky - 1
                                  )
                                }
                                bgTransparent={props.bgTransparent}
                                smallPadding={props.smallPadding}
                                justifyContentItem={justifyContentItem}
                              ></TabledBodyItem>
                            );
                          } else {
                            if (collapsedKey) {
                              prevVal = collapsedKey(data);
                            }

                            return (
                              <TabledBodyItem
                                key={key}
                                width={colDescriptionSizes[key]}
                                role="cell"
                                textAlign={component(data).props.textAlign}
                                color={component(data).props.color}
                                sticky={
                                  props.numColumnSticky &&
                                  key < props.numColumnSticky
                                    ? true
                                    : false
                                }
                                stickyOffset={150 * key}
                                borderRight={
                                  !!(
                                    props.numColumnSticky &&
                                    key === props.numColumnSticky - 1
                                  )
                                }
                                bgTransparent={props.bgTransparent}
                                smallPadding={props.smallPadding}
                                justifyContentItem={justifyContentItem}
                              >
                                {component(data)}
                              </TabledBodyItem>
                            );
                          }
                        }
                      )
                    : null}
                </TabledBodyRow>
              );
            })
          : null}
        {emptyRows > 0 ? getMinRows() : null}
      </TableBody>
    </TableContainer>
  );
}

export default Table;
