import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { RateCalculationQueryDto } from '@observatory/shared/gard/dtos';
import { useState } from 'react';

export const useAnalysis = (collector_id?: string) => {
  const [loading, setLoading] = useState(false);
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  function handleLoading<Result>(promise: Promise<Result>) {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }

  async function makeSimpleAnalisys(data: RateCalculationQueryDto) {
    const result = await fetcher(`/api/rate-calculation/simple`, {
      method: 'POST',
      body: data,
    });

    return handle<{ banchMarkResultId: string }>(result);
  }

  async function makeCompareAnalisys(data: RateCalculationQueryDto) {
    const result = await fetcher(
      `/api/rate-calculation/compare/${collector_id}`,
      {
        method: 'POST',
        body: data,
      }
    );

    return handle<{ banchMarkResultId: string }>(result);
  }

  return {
    makeSimpleAnalisys: (data: RateCalculationQueryDto) =>
      handleLoading(makeSimpleAnalisys(data)),
    makeCompareAnalisys: (data: RateCalculationQueryDto) =>
      handleLoading(makeCompareAnalisys(data)),
    error,
    loading,
  };
};
