import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

/* eslint-disable-next-line */
export interface BreadcrumbsProps {}

const StyledBreadcrumbs = styled.ul<{ theme: GardThemeType }>`
  display: flex;
  padding-left: 0;
  align-items: center;
  margin: 0 0 ${(props) => props.theme.spacing.s} 0;
`;

export function Breadcrumbs(props: React.PropsWithChildren<BreadcrumbsProps>) {
  return <StyledBreadcrumbs>{props.children}</StyledBreadcrumbs>;
}

export default Breadcrumbs;
