import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAdmin } from '../hooks';

export interface ShowIfAdminProps {
  redirectTo?: string;
}

export function ShowIfAdmin(props: PropsWithChildren<ShowIfAdminProps>) {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin && props.redirectTo) {
      navigate(props.redirectTo);
    }
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isAdmin ? <>{props.children}</> : null;
}
