import { AnalysisItemDto } from '@observatory/shared/gard/dtos';

export function orderDataByDepartment(results: Array<AnalysisItemDto>) {
  const ordering = [
    'Agency Management',
    'Account Management',
    'Project Management',
    'Creative',
    'Analytics, Planning & Strategy',
    'Production & Technology',
    'Media Buying',
    'Other',
  ];

  results.sort(
    (a, b) => ordering.indexOf(a.department) - ordering.indexOf(b.department)
  );

  return results;
}
