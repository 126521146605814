import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';

export interface D3GraphProps<DataInterface>
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: DataInterface;
  'data-test-id'?: string;
  makeGraph: (data: DataInterface, container: HTMLDivElement) => SVGElement;
  refSvgElement?: MutableRefObject<SVGElement | null>;
}

export function D3Graph<DataInterface>({
  data,
  makeGraph,
  refSvgElement,
  ...containerProps
}: D3GraphProps<DataInterface>) {
  const graphContainer = useRef<HTMLDivElement>(null);

  const drawGraph = useMemo(
    () => () => {
      requestAnimationFrame(() => {
        if (graphContainer.current) {
          const svg = makeGraph(data, graphContainer.current);
          if (svg && refSvgElement) {
            refSvgElement.current = svg;
          }
        }
      });
    },
    [data, makeGraph, refSvgElement]
  );

  useEffect(() => {
    if (graphContainer.current) {
      window.addEventListener('resize', drawGraph);
      drawGraph();
      return () => {
        window.removeEventListener('resize', drawGraph);
      };
    }
    return () => null;
  }, [drawGraph]);

  return <div ref={graphContainer} {...containerProps}></div>;
}

export default D3Graph;
