import { Type } from 'class-transformer';
import { DepartmentType, RoleGenericDescriptorType } from '.';
import { ResultQueryDto } from './result-query.dto';

export interface AgencyInfoDto {
  name: string;
  country: string;
  city: string;
}

export class AnalysisItemDto {
  rate: number;
  department: DepartmentType;
  count: number;
  role_generic_descriptor: RoleGenericDescriptorType;
  agency?: string;
  agencies: Array<AgencyInfoDto>;
  sub_section?: string;
  column_name?: string;
  role_bespoke_descriptor?: string;
  perc_of_time?: number;
  total_billable_hours_in_year?: number;
  fee?: number;
  compareRate?: number;
  compareCount?: number;
}

export class AnalysisResultDto {
  @Type(() => AnalysisItemDto)
  results: Array<Array<AnalysisItemDto>>;

  @Type(() => ResultQueryDto)
  from_query: ResultQueryDto;
}
