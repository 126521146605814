import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import React, { ForwardedRef } from 'react';
import { useController } from 'react-hook-form';
import { ErrorText } from '../text/text';

/* eslint-disable-next-line */
export interface CheckboxProps<Type> {
  isChecked: boolean;
  label?: string;
  value: Type;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
}

const CheckboxContainer = styled.div<{
  theme: GardThemeType;
  disabled?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  :hover {
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  }
`;

const Icon = styled.svg<{ theme: GardThemeType }>`
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ theme: GardThemeType; checked: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: all 150ms;
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.primary}`};
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledLabel = styled.label<{ theme: GardThemeType }>`
  margin-left: ${(props) => props.theme.spacing.xxs};
  font-size: ${(props) => props.theme.fontSizes.placeholder};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.text};
`;

export const CheckboxFormInputContainer = styled.div`
  position: relative;
`;

const ErrorContainer = styled.div<{
  theme: GardThemeType;
  center?: boolean;
}>`
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  margin-bottom: ${(props) => (props.center ? props.theme.spacing.xs : '0px')};
  position: relative;
  width: auto !important;
`;

export const Checkbox = React.forwardRef(
  <Type,>(
    props: CheckboxProps<Type> & React.HTMLAttributes<HTMLDivElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <CheckboxContainer
        onClick={props.disabled ? undefined : props.onClick}
        className={props.className}
        disabled={props.disabled}
      >
        <HiddenCheckbox
          name={props.name}
          checked={props.isChecked || false}
          onChange={props.disabled ? () => undefined : props.onChange}
          value={props.value ? String(props.value) : ''}
          ref={ref}
          disabled={props.disabled}
        />
        <StyledCheckbox checked={props.isChecked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        <StyledLabel>{props.label}</StyledLabel>
      </CheckboxContainer>
    );
  }
);

export const CheckboxFormInput = <Type,>(
  props: Omit<CheckboxProps<Type>, 'onChange' | 'isChecked'> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  const { field, fieldState } = useController({
    name: props.name,
    defaultValue: [],
  });

  const onClick = (value: Type) => {
    if (field.value?.find((item: Type) => item === value)) {
      field.onChange(field.value.filter((item: Type) => item !== value));
    } else {
      field.onChange([...(field?.value || []), value]);
    }
  };

  return (
    <CheckboxFormInputContainer className={props.className}>
      <Checkbox
        name={props.name}
        isChecked={field.value?.find((item: Type) => item === props.value)}
        onChange={field.onChange}
        value={`${props.value}`}
        label={props.label}
        onClick={() => onClick(props.value as Type)}
        disabled={props.disabled}
      />
      {fieldState.error?.message && (
        <ErrorContainer>
          <ErrorText>{fieldState.error?.message}</ErrorText>
        </ErrorContainer>
      )}
    </CheckboxFormInputContainer>
  );
};

export default Checkbox;
