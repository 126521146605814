import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

export type InfoAreaType = 'success' | 'error' | 'total';
export interface InfoAreaProps {
  label: string;
  type: InfoAreaType;
  totalLabel?: string;
}

const InfoAreaContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  gap: ${(props) => props.theme.spacing.s};
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

const TotalLabel = styled.label<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.label};
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
`;

const StyledInfoArea = styled.div<{ theme: GardThemeType; type: InfoAreaType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) =>
    props.type === 'success'
      ? props.theme.colors.successLight
      : props.type === 'error'
      ? props.theme.colors.dangerLight
      : props.theme.colors.warningLight};
  max-width: 200px;
  height: 40px;
  width: 100%;
`;

const StyledLabel = styled.label<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.navbar};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.textHeading};
`;

export function InfoArea(props: InfoAreaProps) {
  return (
    <InfoAreaContainer>
      {props.type === 'total' && props.totalLabel && (
        <TotalLabel>{props.totalLabel}</TotalLabel>
      )}
      <StyledInfoArea
        type={props.type}
        data-success={props.type}
        data-testid={props.type === 'total' ? 'total-info-area' : 'info-area'}
      >
        <StyledLabel>{props.label}</StyledLabel>
      </StyledInfoArea>
    </InfoAreaContainer>
  );
}

export default InfoArea;
