import { useAggregateError } from '@f-technology-srl/react-hook-utils';
import {
  LabelText,
  PageWrapper,
  RowItem,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { AgencyDto } from '@observatory/shared/gard/dtos';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetAgency } from '../../data-access';

/* eslint-disable-next-line */
export interface RateCollectionDashboardProps {}

const GrayText = styled(LabelText)<{ theme: GardThemeType }>`
  display: block;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: ${(props) => props.theme.spacing.m};
`;

const SubSectionHeader = styled.div<{ theme: GardThemeType }>`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const SubSectionHeaderName = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  flex: 1 0 20%;
`;

const SubSectionHeaderCompleted = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  flex: 1 1 80%;
  padding-left: 15px;
`;

const SubSectionItem = styled(RowItem)<{ theme: GardThemeType }>`
  margin-bottom: ${(props) => props.theme.spacing.xs};
  padding: 10px;
`;

export interface RateCollectionDashboardLocationStateInterface {
  agency?: AgencyDto;
}

export function RateCollectionDashboard(props: RateCollectionDashboardProps) {
  const params = useParams();

  const agencyId = params?.agency_id;
  const {
    errorMessage,
    data: agency,
    isLoading,
  } = useGetAgency(agencyId || '');

  const error = useAggregateError('danger', errorMessage);

  useSyncAlertControllerWithState(error, [
    error?.label,
    error?.status,
    isLoading,
  ]);

  const subSections = agency?.selected_subsections || [];

  return (
    <PageWrapper title={agency?.name} borderBottom>
      <GrayText>Please click on each link and supply your SOW</GrayText>
      <SubSectionHeader>
        <SubSectionHeaderName>Sub-sections</SubSectionHeaderName>
        <SubSectionHeaderCompleted>Completed</SubSectionHeaderCompleted>
      </SubSectionHeader>
      {subSections.map((sub) => {
        return (
          <SubSectionItem
            key={sub.subSection.id}
            label={sub.subSection.name}
            type={sub.completed ? 'create' : 'edit'}
            linkLabel={`${sub.completed ? 'Edit' : 'Create'} template`}
            linkHref={`/rate_collection/sub_section/${sub.subSection.id}/agency/${agency?.id}`}
            linkState={
              {
                agency,
              } as RateCollectionDashboardLocationStateInterface
            }
            data-testid="subsection-item-list"
          />
        );
      })}
    </PageWrapper>
  );
}

export default RateCollectionDashboard;
