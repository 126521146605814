import React, { ForwardedRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useController, useFormState } from 'react-hook-form';
import { ErrorText, LabelText } from '../text/text';

export interface InputProps {
  label?: string;
  placeholder: string;
  type: 'text' | 'password' | 'number';
  required?: boolean;
  invalid?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
  defaultValue?: string;
  backgroundOnFocus?: string;
  disabled?: boolean;
  isPercentage?: boolean;
}

const StyledInput = styled.input.attrs((props) => ({
  value: props.value,
}))<{
  theme: GardThemeType;
  invalid?: boolean;
  required?: boolean;
  value?: string;
  backgroundOnFocus?: string;
  isPercentage?: boolean;
}>`
  padding: ${(props) =>
    props.isPercentage ? '0.75em 2em 0.75em 0.75em' : '0.75em'};
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.white};
  border: ${(props) =>
    `1px solid ${
      props.invalid ? props.theme.colors.danger : props.theme.colors.greyLight
    }`};
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.regular};
  border-radius: 3px;
  margin: ${(props) => (props.isPercentage ? '0' : 'auto')};
  background-color: ${(props) =>
    props.backgroundOnFocus ? props.backgroundOnFocus : '#ffffff'};
  width: 100%;
  outline: none;
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }

  &:focus-visible {
    outline-color: ${(props) => props.theme.colors.primary};
  }

  &::placeholder {
    color: rgba(114, 114, 114, 0.4);
  }

  &::-webkit-input-placeholder {
    color: rgba(114, 114, 114, 0.4);
  }
`;

const Label = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

export const RequiredAsterics = styled.span`
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  right: 5px;
  top: 25px;
  opacity: 0.5;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.div<{
  theme: GardThemeType;
  center?: boolean;
}>`
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  margin-bottom: ${(props) => (props.center ? props.theme.spacing.xs : '0px')};
  width: auto !important;
`;

export const Percentage = styled.div<{
  theme: GardThemeType;
  center?: boolean;
}>`
  position: relative;
  color: ${(props) => props.theme.colors.text};
`;

export const PercentageContent = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const InputFormInputContainer = styled.div`
  position: relative;
`;

export const Input = React.forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => (
    <InputContainer>
      {props.label && <Label>{props.label}</Label>}
      {props.isPercentage ? (
        <Percentage>
          <PercentageContent>%</PercentageContent>
          <StyledInput {...props} ref={ref} />
        </Percentage>
      ) : (
        <StyledInput {...props} ref={ref} />
      )}
      {props.required && <RequiredAsterics>*</RequiredAsterics>}
    </InputContainer>
  )
);

export function InputFormInput(
  props: Omit<InputProps, 'invalid' | 'onChange' | 'onBlur' | 'value'> & {
    name: string;
    defaultValue?: string;
  } & React.HTMLAttributes<HTMLDivElement>
) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { field, fieldState } = useController({
    name: props.name,
    defaultValue: props.defaultValue,
    rules: { required: props.required },
  });
  const { isSubmitSuccessful, isValid, errors } = useFormState();

  useEffect(() => {
    if (
      fieldState.invalid &&
      fieldState.isDirty &&
      fieldState.error &&
      !isValid &&
      !isSubmitSuccessful &&
      errors
    ) {
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [
    fieldState.invalid,
    fieldState.isDirty,
    fieldState.error,
    isValid,
    isSubmitSuccessful,
    errors,
    props.name,
  ]);

  return (
    <InputFormInputContainer className={props.className}>
      <Input
        data-testid={`input-${props.name}`}
        {...props}
        type={
          // See https://github.com/facebook/react/issues/16554 to know why
          props.type === 'number' ? 'text' : props.type
        }
        invalid={fieldState.invalid}
        onBlur={field.onBlur}
        onChange={field.onChange}
        ref={(newRef) => {
          inputRef.current = newRef;
          field.ref(newRef);
        }}
        isPercentage={props.isPercentage}
        value={
          // Avoid react complain about undefined values
          typeof field.value === 'undefined' || field.value === null
            ? ''
            : field.value
        }
      />
      {fieldState.error?.message && (
        <ErrorContainer>
          <ErrorText data-testid={`error-label-${props.name}`}>
            {fieldState.error?.message}
          </ErrorText>
        </ErrorContainer>
      )}
    </InputFormInputContainer>
  );
}

export default Input;
