import { Type } from 'class-transformer';
import {
  IsArray,
  IsEnum,
  IsIn,
  IsNumber,
  IsOptional,
  IsString,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import {
  DepartmentType,
  CurrencyType,
  RoleGenericDescriptorType,
  DepartmentEnumList,
  RoleGenericDescriptorEnumList,
} from '../rate-calculation';

export class RateDto {
  @IsIn(DepartmentEnumList)
  department: DepartmentType;

  @IsIn(RoleGenericDescriptorEnumList)
  role_generic_descriptor: RoleGenericDescriptorType;

  @IsString()
  role_bespoke_descriptor: string;

  @IsNumber()
  @Min(0, { message: 'should be a percentage between 0 and 1' })
  @Max(1, { message: 'should be a percentage between 0 and 1' })
  perc_of_time: number;

  @IsNumber()
  @Min(1, { message: 'Rate should be grater than zero' })
  rate: number;
}

export class NewCollectorRateDto {
  @IsOptional()
  client_name?: string;

  @IsOptional()
  client_brand?: string;

  @IsEnum(CurrencyType, { message: 'Currency should be a valid currency code' })
  currency: CurrencyType;

  @IsOptional()
  agency_country?: string;

  @IsOptional()
  agency_city?: string;

  @IsOptional()
  agency_size?: string;

  @IsOptional()
  @IsString()
  agency_type?: string;

  @IsNumber()
  @Min(1, { message: 'Rate should be grater than zero' })
  billable_hours: number;

  @IsOptional()
  ownership?: string;

  @IsOptional()
  @IsNumber()
  overhead?: number;

  @IsOptional()
  @IsNumber()
  margin?: number;

  @Type(() => RateDto)
  @ValidateNested({ each: true })
  @IsArray()
  rates: Array<RateDto>;

  @IsOptional()
  @IsString()
  rate_category?: string;
}
