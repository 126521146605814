import { TableModalInfoData } from '../components/add-rates-sub-form/add-rates-sub-form';

export const agencyManagementTableInfo: Array<TableModalInfoData> = [
  {
    department: 'CEO',
    explanation:
      'Responsibility for the direction of the company, its culture and profitability. A team of senior managers will report into this individual but they ensure appropriate direction and strategies are in place.',
    roles:
      'Chief Executive, Chief Executive Officer, President (non-US markets)',
    years: '10 years +',
  },
  {
    department: 'Chairman',
    explanation:
      "Ensures that the agency's management is effective in setting and implementing the agency’s direction and strategy, and acts as the company’s leading representative externally.",
    roles: 'Chairman, President (US markets)',
    years: '10 years +',
  },
  {
    department: 'President',
    explanation:
      "Ensures that the agency's management is effective in setting and implementing the agency’s direction and strategy, and acts as the company’s leading representative externally.",
    roles: 'Chairman, President (US markets)',
    years: '10 years +',
  },
  {
    department: 'Managing director',
    explanation:
      'One of the most experienced personnel in an Agency. Provides overall direction in the business and has oversight of multiple client accounts.',
    roles: 'Managing director',
    years: '10 years +',
  },
  {
    department: 'Other (e.g. Partner, COO, etc.)',
    explanation:
      'e.g. COO - often second in command to the CEO with responsibilty for internal running of the agency, profitability, operating budgets',
    roles:
      'COO or other C-suite or Partner-level roles involved in senior level management of the agency organisation',
    years: '10 years +',
  },
];

export const accountManagementTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management. Leads all client business strategy development.',
    roles: 'Client Services Director, Director, Division Head',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Takes overall responsibility for the development and management of accounts. Lead client-facing role.',
    roles:
      'Business Director, Group Account Director, Senior Account Director, Account Director. Associate Account Director, Associate Director, Regional Director, Senior Consultant',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Second in command on the management of an account. Deputy in the client-facing role and highly operational. Reports into account lead such as account director.',
    roles:
      'Junior Account Director, Account Supervisor, Associate Consultant, Senior Account Manager Consultant, Client Services Associate',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'Experience within marketing services, supports the account under supervision. Responsible for day to day organisation of an account.',
    roles:
      'Account Manager, Senior Account Executive, Account Manager, Account Manager Assistant',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation:
      'A junior member of the client services team. Their role is to support the main account handling team. In most cases they will work closely and report into an account manager.',
    roles:
      'Account Coordinator, Account Executive, Junior Account Executive, Assistant, Account Assistant, Assistant Account Executive',
    years: 'Up to 3 years',
  },
];

export const projectManagementTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management. Leads all client business strategy development.',
    roles: 'Group Project Manager, Head of Project Management',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Plays a lead role managing a team and provides a link between departments such as client service, creative, and technology. Ensures ideas conceptualised by the creative team can be produced in reality. An external facing role with significant client contact.',
    roles: 'Project Director, Senior Project Manager, Lead Project Manager',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Manages the day to day operational and tactical aspects of multiple projects. Generally an internal role, but some client contact required.',
    roles: 'Associate Project Manager, Project Manager',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'An internal-facing role which supports the account lead in the day to day operational aspects of projects.',
    roles:
      'Senior Project Executive, Executive Project Manager, Associate Project Manager, Assistant Project Manager',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation:
      'An internal-facing role which provides day to day support to the project management team.',
    roles: 'Trainee, Project Administrator, Project Executive',
    years: 'Up to 3 years',
  },
  {
    department: 'Technology - Senior',
    explanation:
      'Plays a lead role managing a team and provides a link between departments such as client service, creative, and technology. Ensures the digital and/or technological ideas conceptualised by the creative team can be produced in reality. An external facing role with significant client contact.',
    roles:
      'Digital Project Director, Senior Digital Project Manager, Lead Digital Project Manager',
    years: '8 years +',
  },
  {
    department: 'Technology - Middle (Upper)',
    explanation:
      'Manages the day to day technological and/or digital aspects of multiple projects. Generally an internal role, but some client contact required.',
    roles:
      'Associate Digital Project Manager, Digital Project Manager, Community Manager',
    years: '5 years +',
  },
  {
    department: 'Technology - Middle (Lower)',
    explanation:
      'An internal-facing role which supports the account lead in the day to day technological and/or digital aspects of projects.',
    roles:
      'Senior Digital Project Executive, Executive Digital Project Manager, Associate Digital Project Manager,  Assistant Digital Project Manager, Community Manager, Social Community Manager, Social Community Executive',
    years: '3 - 5 years',
  },
  {
    department: 'Technology - Junior',
    explanation:
      'An internal-facing role which provides technological and/or digital support to the team to aide project delivery.',
    roles:
      'Digital Trainee, Digital Project Administrator, Digital Project Executive',
    years: 'Up to 3 years',
  },
];

export const creativeTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management. Responsible for the overall quality and efficacy of the creative product of the agency.',
    roles:
      'Chief Creative Director, Chief Creative Officer, Executive Creative Director, Group Head Creative, Creative Group Head, Creative Partner',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Conceptualises various creative routes in response to a client brief. Brand guardians from a visual and written perspective. Reports into the head of department.',
    roles:
      'Creative Director, Associate Creative Director, Digital Creative Director, Senior Creative Director, Creative Director (Copy), Creative Director (Art),  Senior Art Director, Design Director,  Senior Digital Designer, Head of Digital, Associate Design Director, Senior Art Director, Senior Designer, Head of Design, Senior Visualiser, Senior Graphic Designer, Senior Copywriter Senior Content Writer',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Typically works to a brief set to them by a creative director with a remit to originate ideas for the brief for approval by the creative director.',
    roles:
      'Art Director Copywriter,  Intermediate Illustrator, Intermediate Copywriter, Copy Supervisor',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'Typically works to a brief set to them by a creative director with a remit to originate ideas for the brief for approval by the creative director. Also supports the creative team to visualise ideas.',
    roles:
      'Art Director, Copywriter, Graphic Designer, Designer, Illustrator Visualiser, Artworker Artist',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation:
      'More of an internal-facing role, sometimes working to a brief set to them by a creative director with a remit to originate ideas for approval by the creative director. Often providing day to day support to more senior creative teams.',
    roles:
      'Junior Digital Designer, Junior Visualiser, Junior Copywriter, Junior Art Director, Art Assistant, Design Assistant, Copywriter, Executive Junior Content, Writer Proofreader',
    years: 'Up to 3 years',
  },
];

export const analyticsTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management. Leads all client business strategy development.',
    roles:
      'Head of Planning, Chief Strategy Officer, Head of Digital Planning, Director of Media Planning,  Analytics & CRM Director',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Integrates insight, planning and data across client business and oversees work of planning team.',
    roles:
      'Director, User Experience, Strategy Director, Planning Director, Data Service Director,Head of Data, Senior Brand Strategist, Senior Planner, Senior Digital Planner, Senior Comms Planner, Senior Media Planner, Senior Analyst, Social Media Director, Content Strategist',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Undertakes research, provides insight on strategy and approach. Creates integrated communications strategies and carries out evaluation and analysis.',
    roles:
      'Planner, Brand Planner, Social Strategist, Analyst, Data Analyst, Media Planner, Analytics & CRM Manager, Strategic Planner',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'Conducts desk research and works with researchers. Involved in customer journey development, creative or media briefing and guidance, as well as evaluation of communications strategy.',
    roles:
      'Planner, Brand Planner, Social Strategist, Analyst, Data Analyst, Media Planner, Analytics & CRM Manager, Strategic Planner',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation:
      'A more internal facing role providing day to day assistance to the analytics, planning and strategy teams. Conducts desk research and works with researchers.',
    roles:
      'Junior Planner, Assistant Planner, Junior Brand Strategist, Junior Strategic Planner, Junior Strategist, Strategy Executive, Junior Data Planner, Junior Data Analyst',
    years: 'Up to 3 years',
  },
];

export const productionTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management.',
    roles:
      'Chief Technology Officer, Technology Director, Chief Digital Officer, Head of Production, Head of Technology, Head of Digital, Head of Broadcast',
    years: '10 years +',
  },
  {
    department: 'Broadcast Production - Senior',
    explanation:
      'Substantial broadcast production and post-production experience with broadcast technology understanding. Use of complex production techniques. Manages a production team.',
    roles: 'Senior Producer, Senior TV Producer, Film Director, Senior Editor',
    years: '8 years +',
  },
  {
    department: 'Broadcast Production - Middle (Upper)',
    explanation:
      'Practical experience running shoots, film crews and edit teams.',
    roles:
      'Production Controller, Producer (TV/Video/Audio Visual/Radio), Film Producer, Film Manager, AV Producer, Post Producer, Production Controller, Video Producer',
    years: '5 years +',
  },
  {
    department: 'Broadcast Production - Middle (Lower)',
    explanation:
      'Practical experience running shoots, film crews and edit teams.',
    roles: 'Production Assistant, Edit Assistant, Assistant Production Manager',
    years: '3 - 5 years',
  },
  {
    department: 'Broadcast Production - Junior',
    explanation: 'Supports the broadcast team in an administrative role.',
    roles:
      'TV Admin, TV Assistant,  TV Coordinator, Junior TV Producer, Junior Producer',
    years: 'Up to 3 years',
  },
  {
    department: 'Print Production - Senior',
    explanation: '-',
    roles:
      'Print Production Manager, Head of Print Production, Chief Producer, Print Production Director, Director of Print Production',
    years: '8 years +',
  },
  {
    department: 'Print Production - Middle (Upper)',
    explanation: '-',
    roles: 'Print Producer Manager, Associate Director Print',
    years: '5 years +',
  },
  {
    department: 'Print Production - Middle (Lower)',
    explanation: '-',
    roles:
      'Assistant Print Production Finishing Artist, Print Production Assistant',
    years: '3 - 5 years',
  },
  {
    department: 'Print Production - Junior',
    explanation:
      'Administrative support function to the print production team.',
    roles: 'Administration, Print Junior Proofreader, Print Executive',
    years: 'Up to 3 years',
  },
  {
    department: 'Digital Production - Senior',
    explanation:
      'Leads on several accounts in a client facing role whilst also managing a team. Acts as the linchpin between creative and production. Works across a range of disciplines with extensive experience in creative concept development, technical development and delivery, user experience and design, copywriting and video production.',
    roles:
      'Senior User Experience, Senior Developer, Senior Web Producer, Senior Systems Engineer, Senior Coder, Senior Technologist, Head of Digital Production, Technology Manager, Digital Director, Senior Developer, Senior Software Engineer, Senior Technical Producer, Senior SEO Executive, Head of UX',
    years: '8 years +',
  },
  {
    department: 'Digital Production - Middle (Upper)',
    explanation:
      'Strong technical skills and experience of software platforms. Understanding of product design, software and digital development and brand strategy.',
    roles:
      'Online Marketing Manager, Flash Developer, Digital Producer, Technical Manager, 3D/CGI Artist, 3D Motion Animation, Digital Developer, Front End Developer, Web Developer, Software Engineer, QA Manager, Web Producer, Colour Retoucher, Content Engineer, Graphic Designer, Media Developer, Data Analyst, UX Architect,Content Manager, Growth Hacker',
    years: '5 years +',
  },
  {
    department: 'Digital Production - Middle (Lower)',
    explanation:
      'Skills and experience of software platforms. Understanding of product design, software and digital development and brand strategy.',
    roles:
      'Assistant Print Production Finishing Artist, Print Production Assistant',
    years: '3 - 5 years',
  },
  {
    department: 'Digital Production - Junior',
    explanation:
      'Provides day to day support to the digital production team in an administrative role.',
    roles:
      'System Administrator, Support Analyst, Web Administration, Executive Digital Producer, Ecommerce Admin Assistant,  Junior User Experience',
    years: 'Up to 3 years',
  },
  {
    department: 'Other Production - Senior',
    explanation:
      'Experienced in integration and production. Works on a range of creative projects. Leads a team of Producers and works closely with the creative director.',
    roles:
      'Senior Producer, Studio Director, Traffic Director, Director of Photography',
    years: '8 years +',
  },
  {
    department: 'Other Production - Middle (Upper)',
    explanation:
      'Integrated producer, involved in the creative development through to delivery.',
    roles:
      'Art Studio Manager, Creative Producer, Events Producer, Art Buyer, Talent Coordinator, Music Production',
    years: '5 years +',
  },
  {
    department: 'Other Production - Middle (Lower)',
    explanation:
      'Integrated producer, involved in the creative development through to delivery.',
    roles:
      'Art Studio Manager, Creative Producer, Events Producer, Art Buyer, Talent Coordinator, Music Production',
    years: '3 - 5 years',
  },
  {
    department: 'Other Production - Junior',
    explanation:
      'Supports the production team on a day to day basis in an administrative role.',
    roles: 'Assistant Studio Manager, Junior Quality Assurance',
    years: 'Up to 3 years',
  },
];

export const mediaTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Functional area leader with ultimate accountability for that discipline within the agency. Reports into agency senior management.',
    roles: 'Head of Media',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Negotiate, purchase and monitor advertising space and airtime. Works on multiple accounts. Campaign bookings, campaign evaluation, media value, understanding of a media portfolio and strategy planning.',
    roles:
      'Senior Media Buyer, Senior PPC, Senior SEO, Senior Digital Buyer, Buying Director, Senior TV Buyer',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Negotiate, purchase and monitor advertising space and airtime. Experience working on different accounts. Campaign bookings, campaign evaluation, media value, understanding of a media portfolio.',
    roles: 'Media Buyer, Digital Buyer, Digital Media Buyer, PPCSEO',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'Knowledge of media trading industry, able to manage day-to-day commercial relationships with contractors. Operates the booking process.',
    roles: 'Assistant Media Buyer',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation: 'Supports planning and buying campaigns.',
    roles:
      'Junior Media Buyer, Junior Digital Buyer, Junior PPC, Junior SEO, Executive Buyer, Print Planner Buyer',
    years: 'Up to 3 years',
  },
];

export const otherTableInfo: Array<TableModalInfoData> = [
  {
    department: 'Department Head',
    explanation:
      'Please only select this option if the role truly does not fit under any other department. The role is a functional area leader with ultimate accountability for the discipline within the agency. Reports into agency senior management.',
    roles: 'n/a',
    years: '10 years +',
  },
  {
    department: 'Senior',
    explanation:
      'Please only select this option if the role truly does not fit under any other department.',
    roles: 'n/a',
    years: '8 years +',
  },
  {
    department: 'Middle (Upper)',
    explanation:
      'Please only select this option if the role truly does not fit under any other department.',
    roles: 'n/a',
    years: '5 years +',
  },
  {
    department: 'Middle (Lower)',
    explanation:
      'Please only select this option if the role truly does not fit under any other department.',
    roles: 'n/a',
    years: '3 - 5 years',
  },
  {
    department: 'Junior',
    explanation:
      'Please only select this option if the role truly does not fit under any other department.',
    roles: 'n/a',
    years: 'Up to 3 years',
  },
];
