export function useThrottle(
  throttleBy = 500
): (
  callBack: (value: string) => void
) => React.ChangeEventHandler<HTMLInputElement> {
  let prevTimeout: NodeJS.Timeout;
  return (callBack) => (event) => {
    event.preventDefault();
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }
    prevTimeout = setTimeout(() => {
      callBack(event.target.value);
    }, throttleBy);
  };
}
