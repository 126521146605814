import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';

export function useDeleteUser() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  return {
    error,
    async deleteUser(user_id: string) {
      const result = await fetcher(`/api/user/${user_id}`, {
        method: 'DELETE',
        body: {},
      });

      return handle<boolean>(result);
    },
  };
}
