import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  AgencyDto,
  CreateAgencyDto,
  CollectorDto,
} from '@observatory/shared/gard/dtos';

export function useCreateAgency(collector_id?: string) {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function createAgency(data: CreateAgencyDto) {
    const result = await fetcher(`/api/agency/add`, {
      method: 'POST',
      body: data,
    });

    return handle<AgencyDto>(result);
  }

  async function deleteAgency(agency_id: string) {
    const result = await fetcher(`/api/agency/delete/${agency_id}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<undefined>(result);
  }

  async function connectCollectorWithAgency(agency_id: string) {
    const result = await fetcher(
      `/api/rate-collection/agency/${agency_id}/connect/${collector_id}`,
      {
        method: 'PUT',
        body: {},
      }
    );

    return handle<CollectorDto>(result);
  }

  async function createAndConnectToCollector(data: CreateAgencyDto) {
    const agency = await createAgency(data);

    if (!agency) {
      return undefined;
    }

    try {
      return await connectCollectorWithAgency(agency.id);
    } catch (error) {
      await deleteAgency(agency.id);
      throw error;
    }
  }

  return {
    error,
    createAgency,
    deleteAgency,
    connectCollectorWithAgency,
    createAndConnectToCollector,
  };
}
