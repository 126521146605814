import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

/* eslint-disable-next-line */
export interface SubProps {
  sub_section_id: string;
  label: string;
  completed?: boolean;
  historic?: boolean;
  noIcon?: boolean;
}

const Icon = styled.svg<{ theme: GardThemeType }>`
  fill: none;
  width: 24px;
  height: 24px;
`;

const StyledSub = styled.div<{ theme: GardThemeType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.bg}`};
  padding: 1px;
  cursor: pointer;
`;

const StyledLabel = styled.label<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.textHeading};
  margin-left: ${(props) => props.theme.spacing.s};
`;

const LeftSection = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const RightSection = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
`;

export function Sub(props: SubProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledSub className={props.className}>
      <LeftSection>
        {props.completed && !props.noIcon ? (
          <Icon viewBox="0 0 24 24">
            <path
              d="M17.9999 6.99997L16.5899 5.58997L10.2499 11.93L11.6599 13.34L17.9999 6.99997ZM22.2399 5.58997L11.6599 16.17L7.47991 12L6.06991 13.41L11.6599 19L23.6599 6.99997L22.2399 5.58997ZM0.409912 13.41L5.99991 19L7.40991 17.59L1.82991 12L0.409912 13.41Z"
              fill="#41D3AC"
            />
          </Icon>
        ) : !props.completed && !props.noIcon ? (
          <Icon viewBox="0 0 24 24">
            <path
              d="M3.83991 0.97998L2.42991 2.38998L12.8099 12.77L11.3999 14.18L7.15991 9.93998L5.74991 11.35L11.4099 17.01L14.2399 14.18L20.8399 20.78L22.2499 19.37L3.83991 0.97998ZM17.0499 11.36L21.9999 6.39998L20.5699 4.99998L15.6299 9.93998L17.0499 11.36V11.36ZM16.3399 6.39998L14.9299 4.98998L12.8099 7.10998L14.2199 8.51998L16.3399 6.39998ZM0.0799103 11.35L5.73991 17.01L7.14991 15.6L1.48991 9.93998L0.0799103 11.35V11.35Z"
              fill="#EB5757"
            />
          </Icon>
        ) : null}
        <StyledLabel>{props.label}</StyledLabel>
      </LeftSection>
      <RightSection>{props.children}</RightSection>
    </StyledSub>
  );
}

export default Sub;
