import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled, { useTheme } from 'styled-components';

/* eslint-disable-next-line */
export interface PaginationProps {
  pages: Array<{ pageNumber: number; isActive: boolean; onClick: () => void }>;
  arrowLeft: { isActive: boolean; onClick: () => void };
  arrowRight: { isActive: boolean; onClick: () => void };
  paginationRange: { pageStart: number; pageEnd: number };
}

const IconArrow = (color: string) => {
  return (
    <svg width="7" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.07885.58782c.32465-.324644.851-.324644 1.17565 0 .32439.324394.32468.85025.00064 1.175L3.02501 5l3.23013 3.23718c.32404.32475.32375.85061-.00064 1.175-.32465.32464-.851.32464-1.17565 0L.666675 5 5.07885.58782Z"
        fill={color}
        fillOpacity="1"
      />
    </svg>
  );
};

const PaginationContainer = styled.div<{ theme: GardThemeType }>`
  display: inline-flex;
  border: 1px solid ${(props) => props.theme.colors.greyLight};
  border-radius: 4px;
`;

const ArrowLeft = styled.i<{ theme: GardThemeType }>`
  padding: 0 5px;
  border-right: 1px solid ${(props) => props.theme.colors.greyLight};
  cursor: pointer;
`;

const ArrowRight = styled.i<{ theme: GardThemeType }>`
  padding: 0 5px;
  border-left: 1px solid ${(props) => props.theme.colors.greyLight};
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`;

const PageItem = styled.span<{ theme: GardThemeType; active: boolean }>`
  display: flex;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.colors.greyLight};
  padding: 0 5px;
  font-size: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.active
      ? (props) => props.theme.colors.text
      : (props) => props.theme.colors.greyLight09};
  font-weight: ${(props) =>
    props.active
      ? (props) => props.theme.fontWeights.heading
      : (props) => props.theme.fontWeights.paragraph};
  font-family: ${(props) =>
    props.active
      ? (props) => props.theme.fonts.bold
      : (props) => props.theme.fonts.regular};

  &:last-child {
    border-right: 0;
  }
`;

export function Pagination(props: PaginationProps) {
  const themeContext = useTheme() as GardThemeType;

  return (
    <PaginationContainer>
      <ArrowLeft
        aria-disabled={!props.arrowLeft.isActive}
        onClick={() =>
          props.arrowLeft.isActive ? props.arrowLeft.onClick() : null
        }
      >
        {IconArrow(
          props.arrowLeft.isActive
            ? themeContext.colors.text
            : themeContext.colors.greyLight
        )}
      </ArrowLeft>
      {props.pages
        .slice(props.paginationRange.pageStart, props.paginationRange.pageEnd)
        .map((page, key) => {
          return (
            <PageItem key={key} onClick={page.onClick} active={page.isActive}>
              {page.pageNumber}
            </PageItem>
          );
        })}
      <ArrowRight
        aria-disabled={!props.arrowRight.isActive}
        onClick={() =>
          props.arrowRight.isActive ? props.arrowRight.onClick() : null
        }
      >
        {IconArrow(
          props.arrowRight.isActive
            ? themeContext.colors.text
            : themeContext.colors.greyLight
        )}
      </ArrowRight>
    </PaginationContainer>
  );
}

export default Pagination;
