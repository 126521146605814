import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';

export function useDeleteSubSectionSubmission() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  return {
    error,
    async deleteSubSectionSubmission(
      agency_id: string,
      sub_section_id: string
    ) {
      const result = await fetcher(
        `/api/sub-section/remove/sub_section/${sub_section_id}/agency/${agency_id}`,
        {
          method: 'DELETE',
          body: {},
        }
      );

      return handle<boolean>(result);
    },
  };
}
