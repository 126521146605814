import { GardThemeType } from '@observatory/front-end/gard-theme';
import React from 'react';
import styled from 'styled-components';
import { ButtonPrimary } from '../button/button';
import { NavbarText } from '../text/text';
import LogoImage from './logo.png';

/* eslint-disable-next-line */
export interface NavbarLoggedInProps {
  type: 'loggedIn';
  username: string;
  logoutOnClick: () => void;
}

export interface NavbarLoggedOutProps {
  type: 'loggedOut';
  loginOnClick: () => void;
}

const NavbarContainer = styled.header<{ theme: GardThemeType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 10px 65px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
`;

const Logo = styled.img`
  width: 212px;
  height: 42px;
`;

const Menu = styled.nav`
  display: flex;
  align-items: center;
`;

const MenuItem = styled(NavbarText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.white};
  margin-right: 24px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

const MenuItemCLient = styled(MenuItem)`
  margin-right: 0;
`;

export const NewUserBtn = styled(ButtonPrimary)<{ theme: GardThemeType }>`
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 4px;

  &:hover {
    border-color: ${(props) => props.theme.colors.white};
  }
`;

export const AdditionalLoggedInNavbarContainer = (
  props: React.PropsWithChildren<unknown>
) => {
  return props.children as JSX.Element;
};

export function Navbar(
  props: React.PropsWithChildren<NavbarLoggedInProps | NavbarLoggedOutProps>
) {
  const childAsArray = React.Children.toArray(props.children);
  const AdditionalLoggedInContainer = childAsArray.find((child) => {
    if (React.isValidElement(child)) {
      return child.type === AdditionalLoggedInNavbarContainer;
    }
    return false;
  });

  return (
    <NavbarContainer>
      <Logo src={LogoImage} alt="Observatory logo" />
      <Menu>
        {props.type === 'loggedIn' ? (
          <>
            <MenuItem data-testid="navbar-username">
              SIGNED IN AS {props.username}
            </MenuItem>
            <MenuItem onClick={props.logoutOnClick}>LOGOUT</MenuItem>
            {AdditionalLoggedInContainer}
          </>
        ) : (
          <MenuItemCLient onClick={props.loginOnClick}>LOGIN</MenuItemCLient>
        )}
      </Menu>
    </NavbarContainer>
  );
}

export default Navbar;
