import {
  Loader,
  Pagination,
  PillText,
  Search,
  Select,
  Table,
  TableDescription,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetLogList } from '../../data-access';
import { PaginateResultLogCheckDto } from '@observatory/shared/gard/dtos';
import { useThrottle } from '../../hooks';
import { useState } from 'react';
export interface TableWithFiltersProps {
  entriesForPageOptions: Array<{ label: string; value: number }>;
  entriesForPageSelected: number;
}

const colDescription: Array<
  TableDescription<PaginateResultLogCheckDto['data'][0]>
> = [
  {
    headerName: 'Date',
    component: (row) => <PillText>{String(row?.createdAt)}</PillText>,
  },
  {
    headerName: 'User',
    component: (row) => <PillText>{row?.user?.username}</PillText>,
  },
  {
    headerName: 'Ip',
    component: (row) => <PillText>{row?.ip}</PillText>,
  },
  {
    headerName: 'Url',
    component: (row) => <PillText>{row?.url}</PillText>,
    percSize: 30,
  },
];

const TableWithFiltersContainer = styled.div``;

const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SearchInput = styled(Search)`
  width: 320px;
  margin-right: 10px;
`;

const EntriesForPageSelect = styled(Select)`
  width: 200px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export function TableWithFilters<Type>(props: TableWithFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage: number = parseInt(searchParams.get('page') || '0');
  const entriesForPage = parseInt(searchParams.get('entries') || '10');
  const searchText = searchParams.get('searchText') || '';
  const [paginationRange, setPaginationrange] = useState({
    pageStart: 0,
    pageEnd: 10,
  });

  const throttle = useThrottle(500);

  const { isError, isLoading, data } = useGetLogList(
    currentPage,
    entriesForPage,
    searchText
  );

  useSyncAlertControllerWithState(
    isError
      ? {
          label: isError.message,
          status: 'danger',
        }
      : undefined,
    [isError?.message, isLoading]
  );

  let pages: Array<{
    pageNumber: number;
    isActive: boolean;
    onClick: () => void;
  }> = [];
  let arrowLeft: { isActive: boolean; onClick: () => void } = {
    isActive: false,
    onClick: () => null,
  };
  let arrowRight: { isActive: boolean; onClick: () => void } = {
    isActive: false,
    onClick: () => null,
  };

  const count = data?.count || 1;
  const totalPages = Math.ceil(count / entriesForPage);

  const setParams = (
    page = String(currentPage),
    entries = String(entriesForPage),
    searchText = ''
  ) => {
    setSearchParams({ page: page, entries: entries, searchText });
  };

  if (totalPages > 1) {
    for (let i = 0; i < totalPages; i++) {
      pages = [
        ...pages,
        {
          pageNumber: i + 1,
          isActive: currentPage === i,
          onClick: () => {
            setParams(`${i}`);
            if (paginationRange.pageEnd === i + 1) {
              setPaginationrange({
                pageStart: paginationRange.pageStart + 5,
                pageEnd: paginationRange.pageEnd + 5,
              });
            }
            if (paginationRange.pageStart === i && i > 0) {
              setPaginationrange({
                pageStart: paginationRange.pageStart - 5,
                pageEnd: paginationRange.pageEnd - 5,
              });
            }
          },
        },
      ];
    }

    arrowLeft = {
      isActive: currentPage > 0,
      onClick: () => {
        setParams(`${currentPage - 1}`);
        if (currentPage && paginationRange.pageEnd === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart + 5,
            pageEnd: paginationRange.pageEnd + 5,
          });
        }
        if (currentPage && paginationRange.pageStart === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart - 5,
            pageEnd: paginationRange.pageEnd - 5,
          });
        }
      },
    };

    arrowRight = {
      isActive: currentPage + 1 < totalPages,
      onClick: () => {
        setParams(`${currentPage + 1}`);
        if (currentPage && paginationRange.pageEnd === currentPage + 1) {
          setPaginationrange({
            pageStart: paginationRange.pageStart + 5,
            pageEnd: paginationRange.pageEnd + 5,
          });
        }
        if (currentPage && paginationRange.pageStart === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart - 5,
            pageEnd: paginationRange.pageEnd - 5,
          });
        }
      },
    };
  }

  return (
    <TableWithFiltersContainer>
      <FiltersContainer>
        <SearchInput
          placeholder="Start typing here"
          onChange={throttle((searchText) =>
            setParams('0', undefined, searchText)
          )}
        />
        <EntriesForPageSelect
          type="single-selection"
          options={props.entriesForPageOptions}
          selected={entriesForPage}
          onSelected={(value) => setParams('0', value as string)}
        />
      </FiltersContainer>
      {!isLoading ? (
        <>
          <Table data={data?.data} colDescription={colDescription} />
          {totalPages > 1 ? (
            <PaginationContainer>
              <Pagination
                pages={pages}
                arrowLeft={arrowLeft}
                arrowRight={arrowRight}
                paginationRange={paginationRange}
              />
            </PaginationContainer>
          ) : null}
        </>
      ) : (
        <Loader type="dots" />
      )}
    </TableWithFiltersContainer>
  );
}

export default TableWithFilters;
