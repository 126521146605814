import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { IsSubsectionNewDto } from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetIsRateNew = (agency_id: string, sub_section_id: string) => {
  const fetcher = useFetcher();
  const { error: fetchError, handle: handleFetch } = useHandleRespError(false);

  const {
    data,
    error: getError,
    mutate,
  } = useSWR<IsSubsectionNewDto>(
    `/api/sub-section/sub_section/${sub_section_id}/agency/${agency_id}/completed`,
    fetcher
  );

  const error = !!getError || !!fetchError;

  return {
    data: handleFetch(data),
    isLoading: !error && !data,
    isError: error,
    errorMessage: fetchError,
    mutate,
  };
};
