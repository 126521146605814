import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { CollectorDto } from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetRateCollectionList = (filter = '') => {
  const { handle, error: responseError } = useHandleRespError(false);
  const fetcher = useFetcher();

  const { data, error, mutate } = useSWR<Array<CollectorDto>>(
    `/api/rate-collection?name=${filter}`,
    fetcher
  );

  return {
    data: handle(data),
    isLoading: !responseError && !error,
    isError: !!(error || responseError),
    error: error || responseError,
    mutate,
  };
};
