import { Route, Routes } from 'react-router-dom';
import CollectorAnalysisResults from './collector-analysis-results/collector-analysis-results';
import CollectorAnalysis from './collector-analysis/collector-analysis';
import SimpleAnalysisResults from './simple-analysis-results/simple-analysis-results';
import SimpleAnalysis from './simple-analysis/simple-analysis';

export const CollectorAnalysisRoutes = () => (
  <Routes>
    <Route path=":rate_collector_id" element={<CollectorAnalysis />} />
    <Route
      path="results/:banchMarkResultId/collector/:rate_collector_id"
      element={<CollectorAnalysisResults />}
    />
  </Routes>
);

export const SimpleAnalysisRoutes = () => (
  <Routes>
    <Route path="" element={<SimpleAnalysis />} />
    <Route
      path="results/:banchMarkResultId"
      element={<SimpleAnalysisResults />}
    />
  </Routes>
);

export * from './collector-analysis/collector-analysis';
export * from './collector-analysis-results/collector-analysis-results';
