import {
  BreadcrumbItemProps,
  HeadPageButtonsContainer,
  LinkOutlined,
  NavbarContainer,
  PageWrapper,
  ButtonPrimary,
  Modal,
  PillText,
  PillTextBold,
  TableDescription,
  Table,
  Pagination,
  Loader,
  useSyncAlertControllerWithState,
  ButtonOutlined,
} from '@observatory/front-end/core-ui';
import { PaginatedUserDto } from '@observatory/shared/gard/dtos';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AddUserModalForm, ConnectedNavbar } from '../../component';
import { DeleteUserModal } from '../../component/delete-user-modal';
import { useAddUser, useGetUsers } from '../../hooks';

const TableContainer = styled.div`
  margin-top: 30px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const UsersManagement = () => {
  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: '/users_management',
      active: true,
      label: 'Admin section',
    },
  ];

  let pages: Array<{
    pageNumber: number;
    isActive: boolean;
    onClick: () => void;
  }> = [];
  let arrowLeft: { isActive: boolean; onClick: () => void } = {
    isActive: false,
    onClick: () => null,
  };
  let arrowRight: { isActive: boolean; onClick: () => void } = {
    isActive: false,
    onClick: () => null,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage: number = parseInt(searchParams.get('page') || '0');
  const entriesForPage = parseInt(searchParams.get('entries') || '10');
  const { isError, isLoading, mutate, data } = useGetUsers(
    currentPage,
    entriesForPage
  );
  const { addUser, error } = useAddUser();
  const [paginationRange, setPaginationrange] = useState({
    pageStart: 0,
    pageEnd: 10,
  });

  const count = data?.count || 1;
  const totalPages = Math.ceil(count / entriesForPage);

  const setParams = (
    page = String(currentPage),
    entries = String(entriesForPage)
  ) => {
    setSearchParams({ page: page, entries: entries });
  };

  if (totalPages > 1) {
    for (let i = 0; i < totalPages; i++) {
      pages = [
        ...pages,
        {
          pageNumber: i + 1,
          isActive: currentPage === i,
          onClick: () => {
            setParams(`${i}`);
            if (paginationRange.pageEnd === i + 1) {
              setPaginationrange({
                pageStart: paginationRange.pageStart + 5,
                pageEnd: paginationRange.pageEnd + 5,
              });
            }
            if (paginationRange.pageStart === i && i > 0) {
              setPaginationrange({
                pageStart: paginationRange.pageStart - 5,
                pageEnd: paginationRange.pageEnd - 5,
              });
            }
          },
        },
      ];
    }

    arrowLeft = {
      isActive: currentPage > 0,
      onClick: () => {
        setParams(`${currentPage - 1}`);
        if (currentPage && paginationRange.pageEnd === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart + 5,
            pageEnd: paginationRange.pageEnd + 5,
          });
        }
        if (currentPage && paginationRange.pageStart === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart - 5,
            pageEnd: paginationRange.pageEnd - 5,
          });
        }
      },
    };

    arrowRight = {
      isActive: currentPage + 1 < totalPages,
      onClick: () => {
        setParams(`${currentPage + 1}`);
        if (currentPage && paginationRange.pageEnd === currentPage + 1) {
          setPaginationrange({
            pageStart: paginationRange.pageStart + 5,
            pageEnd: paginationRange.pageEnd + 5,
          });
        }
        if (currentPage && paginationRange.pageStart === currentPage) {
          setPaginationrange({
            pageStart: paginationRange.pageStart - 5,
            pageEnd: paginationRange.pageEnd - 5,
          });
        }
      },
    };
  }

  const colDescription: Array<TableDescription<PaginatedUserDto['data'][0]>> = [
    {
      headerName: 'UserName/Email',
      component: (row) => <PillTextBold>{row?.username}</PillTextBold>,
    },
    {
      headerName: 'Role',
      component: (row) => <PillText>{row?.role}</PillText>,
    },
    {
      headerName: '',
      component: (row) => (
        <DeleteUserModal
          username={row?.username || ''}
          userId={row?.id || ''}
          mutate={mutate}
        />
      ),
      justifyContentItem: 'flex-end',
    },
  ];

  useSyncAlertControllerWithState(
    isError
      ? {
          label: isError.message,
          status: 'danger',
        }
      : undefined,
    [isError?.message]
  );

  useSyncAlertControllerWithState(
    error
      ? {
          label: error,
          status: 'danger',
        }
      : undefined,
    [error]
  );

  return (
    <PageWrapper
      title="Users management"
      breadcrumbs={breadcrumbs}
      borderBottom
    >
      <HeadPageButtonsContainer>
        <ButtonOutlined
          onClick={() => {
            window.location.href = '/api/download-csv';
          }}
        >
          DOWNLOAD CSV
        </ButtonOutlined>
        <LinkOutlined href="/admin_check">ADMIN LOG</LinkOutlined>
        <Modal
          triggerElement={
            <ButtonPrimary data-testid="add-new-user-button">
              CREATE NEW USER
            </ButtonPrimary>
          }
          title="Register new user"
          subtitle="Insert email and password to create a new user for the GARD.
          The new user can be managed in the admin section. "
        >
          {({ close }) => (
            <AddUserModalForm
              onSubmit={(values) =>
                addUser(values)
                  .then(() => {
                    mutate();
                    close();
                  })
                  .catch((error) => console.error(error))
              }
              modalClose={close}
            />
          )}
        </Modal>
      </HeadPageButtonsContainer>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      {!isLoading ? (
        <TableContainer>
          <Table
            data={data?.data}
            colDescription={colDescription}
            bgTransparent
            smallPadding
          />
          {totalPages > 1 ? (
            <PaginationContainer>
              <Pagination
                pages={pages}
                arrowLeft={arrowLeft}
                arrowRight={arrowRight}
                paginationRange={paginationRange}
              />
            </PaginationContainer>
          ) : null}
        </TableContainer>
      ) : (
        <Loader type="dots" />
      )}
    </PageWrapper>
  );
};

export default UsersManagement;
