import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SearchAndButtonsProps {}

const SearchAndButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsContainer = styled.div<{ theme: GardThemeType }>`
  margin-left: auto;

  > button {
    margin-right: ${(props) => props.theme.spacing.sm};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export function SearchAndButtons(
  props: React.PropsWithChildren<SearchAndButtonsProps>
) {
  return (
    <SearchAndButtonsContainer>
      <ButtonsContainer>{props.children}</ButtonsContainer>
    </SearchAndButtonsContainer>
  );
}
