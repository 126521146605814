import { ConnectedNavbar } from '@observatory/front-end/user-features';
import {
  BreadcrumbItemProps,
  CommentText,
  Loader,
  NavbarContainer,
  PageWrapper,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FormAnalysis } from '../../components/form-analysis/form-analysis';
import { useAnalysis } from '../../data-access';

/* eslint-disable-next-line */
export interface SimpleAnalysisProps {}

const Subtitle = styled(CommentText)<{ theme: GardThemeType }>`
  display: block;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.blue};
`;

export const LoadingContainer = styled.div<{ theme: GardThemeType }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.greyLight03};
`;

export function SimpleAnalysis(props: SimpleAnalysisProps) {
  const navigate = useNavigate();

  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: '/simple_analysis',
      active: true,
      label: 'Simple analysis',
    },
  ];

  const { loading, makeSimpleAnalisys } = useAnalysis();

  const { showMessage } = useAlertController();

  return (
    <PageWrapper title="Simple analysis" breadcrumbs={breadcrumbs}>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <Subtitle>Please specify the filters for analysis</Subtitle>
      <FormAnalysis
        onSubmit={(values) =>
          makeSimpleAnalisys(values)
            .then((result) =>
              navigate(`/simple_analysis/results/${result?.banchMarkResultId}`)
            )
            .catch((err) => {
              showMessage({ label: err.message, status: 'danger' });
              throw err;
            })
        }
      />
      {loading ? (
        <LoadingContainer>
          <Loader type="circle" />
        </LoadingContainer>
      ) : null}
    </PageWrapper>
  );
}

export default SimpleAnalysis;
