import { IsEmail, IsString, Length } from 'class-validator';

export class NewContactDto {
  @IsString()
  @Length(3, 50, {
    message: 'Contact name must be at least 5 and less than 50 characters',
  })
  name: string;

  @IsString()
  @IsEmail()
  email: string;
}
