import { ZoomableSunBurstData } from '@observatory/front-end/charts';
import { AgencyInfoDto, AnalysisItemDto } from '@observatory/shared/gard/dtos';

export function sortByArray<Type>(
  list: Array<string>,
  getter: (item: Type) => string
) {
  return (item1: Type, item2: Type) => {
    const index1 = list.indexOf(getter(item1));
    const index2 = list.indexOf(getter(item2));
    if (index1 === -1 || index2 === -1) {
      return 0;
    }
    return index1 - index2;
  };
}

export function getDistinctFrom(
  data: Array<AnalysisItemDto>,
  keykey: keyof AnalysisItemDto
) {
  const dist: Array<string> = [];
  for (const datum of data) {
    if (
      typeof datum[keykey] !== 'undefined' &&
      !dist.includes(String(datum[keykey]))
    ) {
      dist.push(String(datum[keykey]));
    }
  }
  return dist;
}

export function sumOfAllRates(
  data: Array<AnalysisItemDto>,
  value: string | number | undefined,
  keykey: keyof AnalysisItemDto
): number {
  return data
    .filter((item) => item[keykey] === value)
    .reduce((acc, val) => acc + val.rate, 0);
}

export function sumOfAllFees(
  data: Array<AnalysisItemDto>,
  value: string | number | undefined,
  keykey: keyof AnalysisItemDto
): number {
  return data
    .filter((item) => item[keykey] === value)
    .reduce((acc, val) => acc + (val?.fee || 0), 0);
}

export function sumOfAllTotalBillableHoursInYear(
  data: Array<AnalysisItemDto>,
  value: string | number | undefined,
  keykey: keyof AnalysisItemDto
): number {
  return data
    .filter((item) => item[keykey] === value)
    .reduce((acc, val) => acc + (val?.total_billable_hours_in_year || 0), 0);
}

function sumOfAll(
  data: Array<AnalysisItemDto>,
  value: string | number | undefined,
  keykey: keyof AnalysisItemDto,
  on: 'rate' | 'hous' | 'fee'
) {
  if (on === 'rate') {
    return sumOfAllRates(data, value, keykey);
  }
  if (on === 'fee') {
    return sumOfAllFees(data, value, keykey);
  }
  if (on === 'hous') {
    return sumOfAllTotalBillableHoursInYear(data, value, keykey);
  }
  throw new Error(`Unable to calculate the sumOfAll for ${on}`);
}

export function nestRecursiveData(
  data: Array<AnalysisItemDto>,
  filterFor: Array<keyof AnalysisItemDto>,
  on: 'rate' | 'hous' | 'fee'
): ZoomableSunBurstData['children'] {
  const filterOn = filterFor.pop();
  if (filterOn && filterFor.length === 0) {
    const distincts = getDistinctFrom(data, filterOn);

    return distincts.map((distinctVal) => ({
      name: distinctVal,
      size: sumOfAll(data, distinctVal, filterOn, on),
    }));
  } else {
    if (filterOn) {
      const distincts = getDistinctFrom(data, filterOn);
      return distincts.map((distinctVal) => ({
        name: distinctVal,
        children: nestRecursiveData(
          data.filter((item) => item[filterOn] === distinctVal),
          [...filterFor],
          on
        ),
      }));
    } else {
      return [];
    }
  }
}

export function extractAgencies(data: Array<AnalysisItemDto>) {
  const util: Array<{ name: string; colName: string }> = [];
  for (const datum of data) {
    if (!util.find((item) => item.colName === datum.column_name)) {
      if (datum?.agency && datum?.column_name) {
        util.push({
          name: datum?.agency,
          colName: datum?.column_name,
        });
      }
    }
  }
  return util;
}

export interface ReversedDataInterface {
  department: string;
  role_generic_descriptor: string;
  role_bespoke_descriptor?: string;
  row: {
    [colName: string]: AnalysisItemDto | undefined;
  };
  standardRow: AnalysisItemDto;
}

export function makeRow(
  agencies: Array<{
    name: string;
    colName: string;
  }>,
  department: string,
  role_generic_descriptor: string,
  data: Array<AnalysisItemDto>,
  role_bespoke_descriptor?: string
) {
  const row: ReversedDataInterface['row'] = {};
  for (const agency of agencies) {
    row[agency.colName] = data.find(
      (datum) =>
        datum.column_name === agency.colName &&
        datum.department === department &&
        datum.role_generic_descriptor === role_generic_descriptor &&
        datum.role_bespoke_descriptor === role_bespoke_descriptor
    );
  }
  return row;
}

export function remapResultsToAgency(data: Array<AnalysisItemDto>) {
  const agenciesAndColName = extractAgencies(data);
  const reversedData: Array<ReversedDataInterface> = [];

  for (const datum of data) {
    if (
      !reversedData.find(
        (item) =>
          item.department === datum.department &&
          item.role_generic_descriptor === datum.role_generic_descriptor &&
          item.role_bespoke_descriptor === datum.role_bespoke_descriptor
      )
    ) {
      const row = makeRow(
        agenciesAndColName,
        datum.department,
        datum.role_generic_descriptor,
        data,
        datum.role_bespoke_descriptor
      );
      reversedData.push({
        department: datum.department,
        role_generic_descriptor: datum.role_generic_descriptor,
        role_bespoke_descriptor: datum.role_bespoke_descriptor,
        row,
        standardRow: datum,
      });
    }
  }

  return {
    agenciesAndColName,
    reversedData: reversedData.sort(
      sortByArray(
        [
          'Agency Management',
          'Account Management',
          'Project Management',
          'Creative',
          'Analytics, Planning & Strategy',
          'Production & Technology',
          'Media Buying',
          'Other',
        ],
        (item) => item.department
      )
    ),
  };
}

export function sortByAgencyName(a: AgencyInfoDto, b: AgencyInfoDto) {
  if (!a.name && b.name) {
    return -1;
  }
  if (a.name && !b.name) {
    return 1;
  }
  if (!a.name && !b.name) {
    return 0;
  }
  return a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase());
}

export function condenseRowsIfIsNeeded(data: Array<ReversedDataInterface>) {
  const pivot: Array<ReversedDataInterface> = [];

  for (const datum of data) {
    const countNumberOfNonEmptySpots = Object.keys(datum.row).reduce(
      (acc, colName) => (datum.row[colName] !== undefined ? acc + 1 : acc),
      0
    );

    if (countNumberOfNonEmptySpots > 0) {
      const columnNamesOfNonEmptySpots = Object.keys(datum.row).filter(
        (colName) => datum.row[colName] !== undefined
      );

      let insertedSpot = 0;
      const columnsToBeInsertedName: Array<{
        columnName: string;
        freeSpotInPrevRows: number;
      }> = [];
      // For each column that is empty try to find
      for (const columnName of columnNamesOfNonEmptySpots) {
        const freeSpotInPrevRows = pivot.findIndex(
          (item) =>
            item.row[columnName] === undefined &&
            item.department === datum.department &&
            item.role_generic_descriptor === datum.role_generic_descriptor
        );

        if (freeSpotInPrevRows > -1) {
          insertedSpot++;
          columnsToBeInsertedName.push({
            freeSpotInPrevRows,
            columnName,
          });
        }
      }

      // if all the spots can be inserted do it in the correspondent postion and column
      if (insertedSpot === countNumberOfNonEmptySpots) {
        for (const {
          columnName,
          freeSpotInPrevRows,
        } of columnsToBeInsertedName) {
          pivot[freeSpotInPrevRows].row[columnName] = datum.row[columnName];
        }
      } else {
        // Otherwise, create a new row and insert the data
        pivot.push(datum);
      }
    } else {
      pivot.push(datum);
    }
  }

  return pivot;
}
