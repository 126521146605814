import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

const BaseText = styled.span<{ theme: GardThemeType }>``;

export const ButtonText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.button};
  font-family: ${(props) => props.theme.fonts.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const NavbarText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.navbar};
  font-family: ${(props) => props.theme.fonts.regular};
  text-transform: uppercase;
`;

export const LinkText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.link};
  font-family: ${(props) => props.theme.fonts.regular};
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

export const CommentText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.comments};
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const LabelText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.label};
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const ErrorText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.label};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.danger};
  z-index: 0;
`;

export const PillText = styled(BaseText)<{
  theme: GardThemeType;
  'text-align'?: string;
  color?: string;
}>`
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.regular};
  text-align: ${(props) => props['text-align']};
  line-height: 20px;
  color: ${(props) => props.color};
`;

export const PillTextBold = styled(BaseText)<{
  theme: GardThemeType;
  'text-align'?: string;
  color?: string;
}>`
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.bold};
  text-align: ${(props) => props['text-align']};
  color: ${(props) => props.color};
`;

export const ParagraphText = styled(BaseText)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.paragraph};
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const TableText = styled(BaseText)<{
  theme: GardThemeType;
  color?: string;
}>`
  font-size: ${(props) => props.theme.fontSizes.table};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.color};
`;

export const InfoText = styled(BaseText)<{
  theme: GardThemeType;
  color?: string;
}>`
  font-size: ${(props) => props.theme.fontSizes.label};
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.color};
`;

export default ButtonText;
