import { ThemeProvider } from 'styled-components';
import { RouterOutlet } from './routes/router';
import { GardTheme, GlobalStyles } from '@observatory/front-end/gard-theme';
import { ClientAuthenticationProvider } from '@f-technology-srl/client-authorization';
import { AlertContainer, AlertProvider } from '@observatory/front-end/core-ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThemeProviderReExported = ThemeProvider as any;

export function App() {
  return (
    <ThemeProviderReExported theme={GardTheme}>
      <AlertProvider>
        <ClientAuthenticationProvider transport="cookie" type="password">
          <GlobalStyles />
          <RouterOutlet />
        </ClientAuthenticationProvider>
        <AlertContainer />
      </AlertProvider>
    </ThemeProviderReExported>
  );
}

export default App;
