import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import HomePage from '../pages/homepage/homepage.page';
import { AdminCheckRoutes } from '@observatory/front-end/admin-check';
import {
  CollectorAnalysisRoutes,
  SimpleAnalysisRoutes,
} from '@observatory/front-end/analysis';
import { RequiredAuth } from '@f-technology-srl/client-authorization';
import LoginPage from '../pages/login/login.page';
import { RateCollectionRoutes } from '@observatory/front-end/rate-collection';
import { UsersManagementRoutes } from '@observatory/front-end/user-features';

export function RouterOutlet() {
  return (
    <Router>
      <Routes>
        <Route path="/login/*" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <RequiredAuth redirectUri="/login">
              <HomePage />
            </RequiredAuth>
          }
        />
        <Route
          path="/admin_check/*"
          element={
            <RequiredAuth redirectUri="/login">
              <AdminCheckRoutes />
            </RequiredAuth>
          }
        />
        <Route
          path="/collector_analysis/*"
          element={
            <RequiredAuth redirectUri="/login">
              <CollectorAnalysisRoutes />
            </RequiredAuth>
          }
        />
        <Route
          path="/simple_analysis/*"
          element={
            <RequiredAuth redirectUri="/login">
              <SimpleAnalysisRoutes />
            </RequiredAuth>
          }
        />
        <Route path="/rate_collection/*" element={<RateCollectionRoutes />} />
        <Route
          path="/users_management/*"
          element={
            <RequiredAuth redirectUri="/login">
              <UsersManagementRoutes />
            </RequiredAuth>
          }
        />
        {/* Inserire rotte qui */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
