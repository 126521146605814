import { TableDescription } from '@observatory/front-end/core-ui';
import * as XLSX from 'xlsx';

export type ArrayColsDescription<Type> = Record<
  string,
  Array<TableDescription<Type>>
>;

function getCols<Type>(cols: Array<TableDescription<Type>>): Array<string> {
  return cols.map(({ headerName }) => headerName);
}

function getData<Type>(
  datas: Type,
  cols: Array<TableDescription<Type>>
): Array<string> {
  // ATTENZIONE: utilizza componenti di React, sarà probabilmente da modificare
  return cols.map(({ csv }) => (csv && csv(datas) ? String(csv(datas)) : ''));
}

export function exportCsv<Type, TablesNames extends Array<string>>(
  tableName: TablesNames,
  data: Array<Type>,
  cols: ArrayColsDescription<Type>
) {
  // WorkBook
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  let filename = '';

  tableName.forEach((value) => {
    filename = filename ? (filename += '-' + String(value)) : String(value);

    const dataToExcel: Array<Array<string>> = [];

    // Add cols name in array
    dataToExcel.push(getCols(cols[value]));

    // Loop rows and add values in array
    data.forEach((datas) => dataToExcel.push(getData(datas, cols[value])));

    // WorkSheet
    const ws = XLSX.utils.aoa_to_sheet(dataToExcel);

    // Appends WorkSheet to WorkBook
    XLSX.utils.book_append_sheet(wb, ws, String(value));
  });

  // Download File
  XLSX.writeFile(wb, filename + '.xlsx');
}
