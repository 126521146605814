import { GardThemeType } from '@observatory/front-end/gard-theme';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface LinkProps {
  href: string;
  disabled?: boolean;
  fullWidth?: boolean;
  hasArrow?: boolean;
  targetBlank?: boolean;
  state?: unknown;
}

export const LinkTo = ({
  state,
  href,
  targetBlank,
  children,
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  return (
    <Link
      {...props}
      to={{ pathname: href }}
      state={state}
      target={targetBlank ? '_blank' : '_self'}
    >
      {children}
    </Link>
  );
};

export const BaseLink = styled(LinkTo)<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.link};
  font-family: ${(props) => props.theme.fonts.regular};
  :hover {
    cursor: pointer;
  }
`;

export const BaseButtonLink = styled(LinkTo)<{
  theme: GardThemeType;
  fullWidth?: boolean;
}>`
  font-size: ${(props) => props.theme.fontSizes.button};
  font-family: ${(props) => props.theme.fonts.bold};
  padding: 11px ${(props) => props.theme.spacing.m};
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LinkOutlined = styled(BaseButtonLink)<{
  theme: GardThemeType;
  hasArrow?: boolean;
  active?: boolean;
}>`
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.secondary};
  border: ${(props) =>
    `1px solid ${
      props.active ? props.theme.colors.white : props.theme.colors.secondary
    }`};
  background-color: ${(props) =>
    props.active ? props.theme.colors.secondary : 'none'};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  position: relative;
  :hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.colors.secondaryVariant
        : props.theme.colors.greyLight};
    border-color: ${(props) =>
      props.active
        ? props.theme.colors.greyLight
        : props.theme.colors.secondaryVariant};
    color: ${(props) =>
      props.active
        ? props.theme.colors.white
        : props.theme.colors.secondaryVariant};
  }
  ${(props) =>
    props.hasArrow
      ? `:after {
    content: "";
    display:inline-block!important;
    width:0;
    height:0;
    border-left: 4px solid transparent;
    border-top: 8px solid ${props.theme.colors.secondary};
    border-right: 4px solid transparent;
    position: absolute;
    right: 15px;
    top: 14px;
  }`
      : ``};
`;

export const LinkPrimary = styled(BaseButtonLink)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  border: ${(props) => `1px solid ${props.theme.colors.secondary}`};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  :hover {
    background-color: ${(props) => props.theme.colors.secondaryVariant};
    border-color: ${(props) => props.theme.colors.secondaryVariant};
  }
`;

export const LinkSecondary = styled(BaseButtonLink)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  border: ${(props) => `1px solid ${props.theme.colors.primary}`};
  width: ${(props) => `${props.fullWidth ? '100%' : 'auto'}`};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryVariant};
    border-color: ${(props) => props.theme.colors.primaryVariant};
  }
`;
