import {
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Length,
  Min,
} from 'class-validator';
import { CurrencyType } from '../rate-calculation';

export class UpdateCollectorDto {
  @IsString()
  collector_id: string;

  @IsOptional()
  @IsString()
  @Length(3, 50, {
    message: 'Collector name length should be between 5 and 50 characters',
  })
  name?: string;

  @IsOptional()
  @IsString()
  client_name?: string;

  @IsOptional()
  @IsString()
  client_brand?: string;

  @IsOptional()
  @IsEnum(CurrencyType)
  currency?: CurrencyType;

  @IsOptional()
  @IsString()
  country?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  number_of_employees?: string;

  @IsOptional()
  @IsString()
  agency_type?: string;

  @IsOptional()
  @IsNumber()
  @Min(1, { message: 'Billable hours per year should not be lower than one' })
  billable_hours_year?: number;

  @IsOptional()
  @IsString()
  ownership?: string;

  @IsOptional()
  @IsNumber()
  overhead?: number;

  @IsOptional()
  @IsNumber()
  margin?: number;

  @IsOptional()
  @IsString()
  rate_category?: string;
}
