import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface InfoBlockProps {}

export const InfoIcon = () => {
  return (
    <svg
      width="49"
      height="48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(0.5) translateX(-20px)' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2801 48c13.3013 0 24.0841-10.7452 24.0841-24S37.5814 0 24.2801 0 .196045 10.7452.196045 24 10.9788 48 24.2801 48Z"
        fill="#BD9E24"
        fillOpacity=".3"
      />
      <path
        d="M22.1475 14.4375c0 .5636.2246 1.1041.6245 1.5026.3999.3985.9423.6224 1.5079.6224s1.108-.2239 1.5079-.6224c.3999-.3985.6245-.939.6245-1.5026s-.2246-1.1041-.6245-1.5026c-.3999-.3985-.9423-.6224-1.5079-.6224s-1.108.2239-1.5079.6224c-.3999.3985-.6245.939-.6245 1.5026Zm3.1986 5.5781h-2.1324c-.1466 0-.2666.1196-.2666.2656v15.4063c0 .1461.12.2656.2666.2656h2.1324c.1466 0 .2666-.1195.2666-.2656V20.2812c0-.146-.12-.2656-.2666-.2656Z"
        fill="#BD9E24"
      />
    </svg>
  );
};

const InfoBlockContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 3px;
  padding: 24px;
`;

const InfoBlockContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export function InfoBlock(props: React.PropsWithChildren<InfoBlockProps>) {
  return (
    <InfoBlockContainer>
      <InfoBlockContent>{props.children}</InfoBlockContent>
    </InfoBlockContainer>
  );
}

export default InfoBlock;
