export const GardTheme = {
  colors: {
    secondary: '#270654',
    primary: '#bd9e24',
    background: '#313437',
    bg: 'rgba(189, 158, 36, 0.3)',
    greyLight: 'rgba(114, 114, 114, 0.1)',
    greyLight03: 'rgba(114, 114, 114, 0.3)',
    greyLight09: 'rgba(114, 114, 114, 0.9)',
    success: 'rgba(111, 207, 151, 1)',
    successLight: '#C6F2E7',
    danger: '#eb5757',
    dangerLight: '#F9CDCD',
    warning: 'rgba(235, 167, 87, 1)',
    warningLight: '#F9E5CD',
    white: '#ffffff',
    greyBackground: '#f2f2f2',
    text: '#727272',
    textHeading: ' #000000',
    secondaryVariant: '#170037',
    primaryVariant: '#907817',
    lavender: '#c2bccd',
    secondaryLight: 'rgba(39, 6, 84, 0.3)',
    blue: '#505d68',
    opacheWhite: 'rgba(255, 255, 255, 0.8)',
  },
  fonts: {
    regular: 'Century Gothic Regular',
    bold: 'Century Gothic Bold',
    italic: 'Century Gothic Italic',
    boldItalic: 'Century Gothic Bold Italic',
  },
  fontSizes: {
    h1: '50px',
    h2: '38px',
    h3: '28px',
    h4: '21px',
    h5: '18px',
    paragraph: '16px',
    label: '12px',
    comments: '26px',
    button: '12px',
    navbar: '12px',
    link: '14px',
    placeholder: '16px',
    pill: '14px',
    table: '13px',
  },
  fontWeights: {
    heading: '700',
    paragraph: '400',
    label: '700',
    comments: '400',
    button: '700',
  },
  spacing: {
    xxs: '4px',
    xs: '8px',
    s: '16px',
    sm: '24px',
    m: '32px',
    ml: '48px',
    l: '64px',
    xl: '128px',
  },
};

export type GardThemeType = typeof GardTheme;

// See https://styled-components.com/docs/api#typescript this will let the type system to know how the type of the theme for the styled components
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends GardThemeType {}
}

export * from './global-styles';
