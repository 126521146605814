import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import { BaseLink } from '../link/link';
import { LinkText, ParagraphText, PillText } from '../text/text';

export type RowItemType = 'edit' | 'create' | 'rate-row';

/* eslint-disable-next-line */
export interface RowItemProps {
  type: RowItemType;
  label: string;
  linkLabel: string;
  onClick?: () => void;
  linkHref?: string;
  linkState?: unknown;
  linkTargetBlank?: boolean;
  rateRowText?: Date;
  editOnClick?: () => void;
  deleteOnClick?: () => void;
}

const IconCompleted = () => {
  return (
    <svg height="20px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m18 7-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41 6 19l1.41-1.41L1.83 12 .41 13.41z"
        fill="#41D3AC"
      />
    </svg>
  );
};

const IconNotCompleted = () => {
  return (
    <svg height="20px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.84 1.98 3.43 3.39l10.38 10.38-1.41 1.41-4.24-4.24-1.41 1.41 5.66 5.66 2.83-2.83 6.6 6.6 1.41-1.41L4.84 1.98zm13.21 10.38L23 7.4 21.57 6l-4.94 4.94 1.42 1.42zm-.71-4.96-1.41-1.41-2.12 2.12 1.41 1.41 2.12-2.12zM1.08 12.35l5.66 5.66 1.41-1.41-5.66-5.66-1.41 1.41z"
        fill="#EB5757"
      />
    </svg>
  );
};

const RowItemContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.bg};
  border-radius: 3px;
  padding: 4px 4px 4px 16px;
`;

const RowItemLabel = styled.div<{ type: RowItemType }>`
  flex: ${(props) => (props.type === 'rate-row' ? '1 0 35%' : '1 0 20%')};
`;

const RowItemIcon = styled.div<{ type: RowItemType }>`
  flex: ${(props) => (props.type === 'rate-row' ? '1 1 50%' : '1 1 60%')};
  display: flex;
  color: ${(props) => props.theme.colors.text};
`;

const RowItemLink = styled.div<{ type: RowItemType }>`
  flex: ${(props) => (props.type === 'rate-row' ? '1 0 15%' : '1 0 20%')};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const RowItemLinkText = styled(BaseLink)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.italic};
  font-style: italic;
`;

const RowItemButtonText = styled(LinkText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.italic};
  font-style: italic;
`;

export function RowItem(
  props: React.PropsWithChildren<RowItemProps> &
    React.HTMLAttributes<HTMLDivElement>
) {
  return (
    <RowItemContainer className={props.className} {...props}>
      <RowItemLabel type={props.type}>
        <ParagraphText data-testid="rate-collector-item-name">
          {props.label}
        </ParagraphText>
      </RowItemLabel>
      <RowItemIcon type={props.type}>
        {props.type === 'rate-row' && props.rateRowText ? (
          <PillText>{String(props.rateRowText)}</PillText>
        ) : props.type === 'edit' ? (
          <IconNotCompleted />
        ) : (
          <IconCompleted />
        )}
      </RowItemIcon>
      <RowItemLink type={props.type}>
        {props.onClick && (
          <RowItemButtonText onClick={props.onClick}>
            {props.linkLabel}
          </RowItemButtonText>
        )}
        {props.linkHref && (
          <RowItemLinkText
            href={props.linkHref}
            state={props.linkState}
            targetBlank={props.linkTargetBlank}
          >
            {props.linkLabel}
          </RowItemLinkText>
        )}
        {props.children}
      </RowItemLink>
    </RowItemContainer>
  );
}

export default RowItem;
