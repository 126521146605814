import {
  IsArray,
  IsEnum,
  IsNumber,
  IsOptional,
  Validate,
  IsDate,
} from 'class-validator';
import { Type } from 'class-transformer';
import { IsPastConstraint } from '../validators';
import { CurrencyType } from './currency.type';

export class RateCalculationQueryDto {
  @Type(() => Date)
  @IsDate()
  @Validate(IsPastConstraint)
  date: Date;

  @IsEnum(CurrencyType)
  currency: CurrencyType;

  @IsNumber()
  billableHoursPerYear: number;

  @IsOptional()
  @IsArray()
  dataType?: Array<string>;

  @IsOptional()
  @IsArray()
  clientCategory?: Array<string>;

  @IsOptional()
  @IsArray()
  agencySize?: Array<string>;

  @IsOptional()
  @IsArray()
  agencyType?: Array<string>;

  @IsOptional()
  @IsArray()
  agencyOwnership?: Array<string>;

  @IsOptional()
  @IsArray()
  agencyLocationCountry?: Array<string>;

  @IsOptional()
  @IsArray()
  agencyLocationCity?: Array<string>;
}
