import { RequiredAuth } from '@f-technology-srl/client-authorization';
import { Route, Routes } from 'react-router-dom';
import {
  RateCollection,
  RateCollectionDashboard,
  RateCollectionDetail,
} from './pages';
import RateCollectionSubSectionSwitch from './pages/rate-collection-sub-section-switch/rate-collection-sub-section-switch';

export const RateCollectionRoutes = () => (
  <Routes>
    <Route
      path=""
      element={
        <RequiredAuth redirectUri="/login">
          <RateCollection />
        </RequiredAuth>
      }
    />
    <Route
      path="detail/:rate_collection_id"
      element={
        <RequiredAuth redirectUri="/login">
          <RateCollectionDetail />
        </RequiredAuth>
      }
    />
    <Route path="dashboard/:agency_id" element={<RateCollectionDashboard />} />

    <Route
      path="sub_section/:sub_section_id/agency/:agency_id"
      element={<RateCollectionSubSectionSwitch />}
    />
  </Routes>
);
