import { joiResolver } from '@hookform/resolvers/joi';
import {
  ButtonOutlined,
  DialogButtons,
  InputFormInput,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import { BooleanCheckbox } from '@observatory/front-end/form-inputs';
import { DialogClose } from '@radix-ui/react-dialog';
import Joi from 'joi';
import { FormProvider, useForm } from 'react-hook-form';

/* eslint-disable-next-line */
export interface AddRateCollectionModalFormProps<ResultData> {
  onSubmit: (values: EditRateCollectionFormValues) => Promise<ResultData>;
  modalClose: () => void;
  defaultValues?: EditRateCollectionFormValues;
  isEdit?: boolean;
}

export type EditRateCollectionFormValues = {
  name: string;
};

const schema = Joi.object({
  name: Joi.string().required().messages({
    'string.base': `The field 'Name' is required`,
  }),
});

export function AddRateCollectionModalForm<ResultData>(
  props: AddRateCollectionModalFormProps<ResultData>
) {
  const formMethods = useForm<EditRateCollectionFormValues>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
        data-testid="rate-collection-modal-form"
      >
        <InputFormInput
          label="Rate collector name"
          name="name"
          type="text"
          placeholder="Rate collector name"
          required
          data-testid="rate-collection-name-input"
        />
        <DialogButtons>
          <SubmitButton
            disabled={formMethods.formState.isSubmitting}
            type="submit"
            data-testid="rate-collection-new-submit"
          >
            Save
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Close</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      </form>
    </FormProvider>
  );
}

export default AddRateCollectionModalForm;
