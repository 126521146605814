import { joiResolver } from '@hookform/resolvers/joi';
import {
  ButtonOutlined,
  DialogButtons,
  InputFormInput,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ContactDto } from '@observatory/shared/gard/dtos';
import { DialogClose } from '@radix-ui/react-dialog';
import Joi from 'joi';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

export type CreateOrUpdateContactForm = Omit<ContactDto, 'id'>;

export interface AddContactModalFormProps {
  onSubmit: (values: CreateOrUpdateContactForm) => void | Promise<void>;
  modalClose: () => void;
  defaultValues?: CreateOrUpdateContactForm;
}

const AddContactModalFormContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;

  > * {
    flex: 1;

    &:first-of-type {
      margin-right: ${(props) => props.theme.spacing.m};
    }
  }
`;

const schema = Joi.object({
  name: Joi.string().required().messages({
    'string.base': `The field 'Name' is required`,
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

export function AddContactModalForm(props: AddContactModalFormProps) {
  const formMethods = useForm<CreateOrUpdateContactForm>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <AddContactModalFormContainer>
          <InputFormInput
            label="Name"
            name="name"
            type="text"
            placeholder="Name"
            required
          />
          <InputFormInput
            label="Email"
            name="email"
            type="text"
            placeholder="Email"
            required
          />
        </AddContactModalFormContainer>
        <DialogButtons>
          <SubmitButton
            disabled={formMethods.formState.isSubmitting}
            type="submit"
          >
            Add
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Close</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      </form>
    </FormProvider>
  );
}

export default AddContactModalForm;
