import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { OptionDto } from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export type AviableOptions =
  | 'currency'
  | 'cities'
  | 'countries'
  | 'agency-types'
  | 'client-categories'
  | 'agency-sizes'
  | 'agency-ownerships'
  | 'rate_categories';

export const useOptions = (as: AviableOptions, params?: URLSearchParams) => {
  const fetcher = useFetcher();
  const { error: actionError, handle } = useHandleRespError(false);

  const { data, error, mutate } = useSWR<Array<OptionDto>>(
    `/api/options/${as}${params ? `?${params.toString()}` : ''}`,
    fetcher
  );

  return {
    data: handle(data),
    isLoading: !error && !data,
    isError: !!(error || actionError),
    errorMessage: actionError,
    mutate,
  };
};
