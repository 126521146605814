import { useRef } from 'react';
import { saveAs } from 'file-saver';

// See http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177

function getSVGString(svgNode: SVGElement) {
  svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
  const cssStyleText = getCSSStyles(svgNode);
  appendCSS(cssStyleText, svgNode);

  const serializer = new XMLSerializer();
  let svgString = serializer.serializeToString(svgNode);
  svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

  return svgString;

  function getCSSStyles(parentElement: SVGElement) {
    const selectorTextArr = [];

    if (!parentElement) {
      return '';
    }

    // Add Parent element Id and Classes to the list
    selectorTextArr.push('#' + parentElement.id);
    for (let c = 0; c < parentElement.classList.length; c++)
      if (!contains('.' + parentElement.classList[c], selectorTextArr))
        selectorTextArr.push('.' + parentElement.classList[c]);

    // Add Children element Ids and Classes to the list
    const nodes = parentElement.getElementsByTagName('*');
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id;
      if (!contains('#' + id, selectorTextArr)) selectorTextArr.push('#' + id);

      const classes = nodes[i].classList;
      for (let c = 0; c < classes.length; c++)
        if (!contains('.' + classes[c], selectorTextArr))
          selectorTextArr.push('.' + classes[c]);
    }

    // Extract CSS Rules
    let extractedCSSText = '';
    for (let i = 0; i < document.styleSheets.length; i++) {
      const s = document.styleSheets[i];

      try {
        if (!s.cssRules) continue;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.name !== 'SecurityError') throw e; // for Firefox
        continue;
      }

      const cssRules = s.cssRules;
      for (let r = 0; r < cssRules.length; r++) {
        const rule = cssRules[r];
        if (!(rule instanceof CSSStyleRule)) {
          continue;
        }
        if (contains(rule.selectorText, selectorTextArr)) {
          extractedCSSText += rule.cssText;
        }
      }
    }

    return extractedCSSText;

    function contains(str: string, arr: Array<string>) {
      return arr.find((searchOn) => !searchOn.includes(str));
    }
  }

  function appendCSS(cssText: string, element: SVGElement) {
    const styleElement = document.createElement('style');
    styleElement.setAttribute('type', 'text/css');
    styleElement.innerHTML = cssText;
    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }
}

function svgString2Image(
  svgString: string,
  width: number,
  height: number,
  callback: (blob: Blob) => void
) {
  const imgsrc =
    'data:image/svg+xml;base64,' +
    btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  const image = new Image();

  image.onload = function () {
    context?.clearRect(0, 0, width, height);
    context?.drawImage(image, 0, 0, width, height);

    canvas.toBlob(function (blob) {
      if (!blob) {
        throw new Error('Unable to generate blob');
      }
      if (callback) {
        callback(blob);
      }
    });
  };

  image.src = imgsrc;
}

async function exportToPng(svg: SVGElement) {
  const { width, height } = svg.getBoundingClientRect();

  const svgCloned = svg.cloneNode(true) as SVGElement;

  // To solve firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=700533
  svgCloned.setAttribute('width', `${width}px`);
  svgCloned.setAttribute('height', `${height}px`);

  const svgString = getSVGString(svgCloned);
  svgString2Image(svgString, 2 * width, 2 * height, save); // passes Blob and filesize String to the callback

  function save(dataBlob: Blob) {
    saveAs(dataBlob, 'D3 vis exported to PNG.png');
  }
}

export function useSvgToPng() {
  const refSvgElement = useRef<SVGElement | null>(null);

  return {
    props: {
      refSvgElement,
    },
    exportToPng() {
      if (!refSvgElement.current) {
        console.warn(
          'Unable to get the svg reference, check the refSvgElement props'
        );
        return;
      }
      return exportToPng(refSvgElement.current);
    },
  };
}
