import { ConnectedNavbar } from '@observatory/front-end/user-features';
import {
  BreadcrumbItemProps,
  ButtonPrimary,
  HeadPageButtonsContainer,
  NavbarContainer,
  PageWrapper,
  PillText,
  Table,
  TableDescription,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { AnalysisItemDto } from '@observatory/shared/gard/dtos';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Divider } from '../../components/divider/divider';
import { TableTitle } from '../../components/table-title/table-title';
import { useAnalysisResult } from '../../data-access';
import {
  AgenciesTableListModal,
  VisibilityButtonOnHoverContainer,
} from '../collector-analysis-results/collector-analysis-results';
import { orderDataByDepartment } from './symple-analysis-utils';
import { exportCsv } from '../collector-analysis-results/export-csv-utils';

const WhiteBackground = styled.div<{
  theme: GardThemeType;
}>`
  background-color: ${(props) => props.theme.colors.white};
`;

const breadcrumbs: Array<BreadcrumbItemProps> = [
  {
    link: '/',
    active: false,
    label: 'GARD',
  },
  {
    link: '/simple_analysis',
    active: false,
    label: 'Simple analysis',
  },
  {
    link: '/analysis',
    active: true,
    label: 'Simple analysis result',
  },
];
/* eslint-disable-next-line */
export interface SimpleAnalysisResultsProps {}

/* DTO */
export class RateDto {
  department: string;
  role: string;
  rate: number;
}

export class PaginateResult {
  page: number;
  offset: number;
  count: number;
  data: Array<RateDto>;
}

export function flatArrayTwoTimes<Generic>(
  array: Array<Array<Generic>>
): Array<Generic> {
  return array.reduce((acc, item) => [...acc, ...item], []);
}

const colDescription: Array<TableDescription<AnalysisItemDto>> = [
  {
    headerName: 'Department',
    component: (row) => (
      <PillText text-align="left" color="#000">
        {row?.department}
      </PillText>
    ),
    collapsedKey: (row) => row?.department,
    percSize: 30,
    csv: (row) => row?.department,
  },
  {
    headerName: 'Role',
    component: (row) => (
      <PillText text-align="center" color="#727272">
        {row?.role_generic_descriptor}
      </PillText>
    ),
    percSize: 40,
    csv: (row) => row?.role_generic_descriptor,
  },
  {
    headerName: 'Rate',
    component: (row) => (
      <PillText text-align="center" color="#727272">
        {row?.rate?.toFixed(2)}
      </PillText>
    ),
    percSize: 12,
    csv: (row) => row?.rate?.toFixed(2),
  },
  {
    headerName: 'Number of rates',
    component: (row) => (
      <PillText text-align="center" color="#727272">
        {row?.count}
      </PillText>
    ),
    percSize: 12,
    csv: (row) => row?.count,
  },
  {
    headerName: 'Number of agency',
    component: (row) => (
      <VisibilityButtonOnHoverContainer>
        <PillText text-align="left" color="#727272">
          {row?.agencies.length}
        </PillText>
        <AgenciesTableListModal agencies={row?.agencies} />
      </VisibilityButtonOnHoverContainer>
    ),
    percSize: 12,
    csv: (row) => row?.agencies.length,
  },
];

export function SimpleAnalysisResults(props: SimpleAnalysisResultsProps) {
  const params = useParams();
  const { data, errorMessage } = useAnalysisResult(
    params?.banchMarkResultId || ''
  );

  const orderedData = data
    ? orderDataByDepartment(flatArrayTwoTimes(data.results))
    : [];

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  return (
    <PageWrapper title="Simple analysis" breadcrumbs={breadcrumbs}>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <HeadPageButtonsContainer>
        <ButtonPrimary
          onClick={() => {
            orderedData &&
              exportCsv(['export-simple-analysis'], orderedData, {
                'export-simple-analysis': colDescription,
              });
          }}
        >
          CSV EXPORT
        </ButtonPrimary>
      </HeadPageButtonsContainer>
      <Divider></Divider>
      <WhiteBackground>
        <TableTitle title="Result"></TableTitle>
        {orderedData && (
          <Table data={orderedData} colDescription={colDescription} />
        )}
      </WhiteBackground>
    </PageWrapper>
  );
}

export default SimpleAnalysisResults;
