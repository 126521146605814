import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  CollectorDto,
  CollectorRateDto,
  NewCollectorRateDto,
} from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export function useCreateRate(agency_id: string, sub_section_id: string) {
  const fetcher = useFetcher();
  const { error: createRateError, handle } = useHandleRespError();

  const { data, error: fetchDefaultError } = useSWR<CollectorDto>(
    `/api/rate-collection/collector/${sub_section_id}/agency/${agency_id}`,
    fetcher
  );

  const defaultData = handle(data)?.data;

  const error = fetchDefaultError
    ? 'Error during form default data fetching'
    : createRateError
    ? createRateError
    : undefined;

  return {
    error,
    defaultData,
    async createRate(data: NewCollectorRateDto) {
      const result = await fetcher(
        `/api/sub-section/compile/${sub_section_id}/agency/${agency_id}`,
        {
          method: 'POST',
          body: data,
        }
      );

      return handle<CollectorRateDto>(result);
    },
  };
}
