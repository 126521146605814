import {
  BreadcrumbItemProps,
  NavbarContainer,
  PageWrapper,
} from '@observatory/front-end/core-ui';
import RateCollectionList from '../../components/rate-collection-list/rate-collection-list';
import { ConnectedNavbar } from '@observatory/front-end/user-features';

/* eslint-disable-next-line */
export interface RateCollectionProps {}

export function RateCollection(props: RateCollectionProps) {
  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: '/rate_collection',
      active: true,
      label: 'Rate collection list',
    },
  ];

  return (
    <PageWrapper title="Rate collection" breadcrumbs={breadcrumbs} borderBottom>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <RateCollectionList />
    </PageWrapper>
  );
}

export default RateCollection;
