import {
  ButtonOutlined,
  ButtonPrimary,
  DialogButtons,
  LabelText,
  Modal,
  RowItem,
  Search,
  SubmitButton,
  IconEdit,
  IconDelete,
  useSyncAlertControllerWithState,
  useAlertController,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AddRateCollectionModalForm from '../add-rate-collection-modal-form/add-rate-collection-modal-form';
import { useThrottle } from '@observatory/front-end/admin-check';
import {
  useCreateRateCollection,
  useDeleteRateCollection,
  useGetRateCollectionList,
  useUpdateRateCollection,
} from '../../data-access';
import { useAggregateError } from '@f-technology-srl/react-hook-utils';
import { DialogClose } from '@radix-ui/react-dialog';

/* eslint-disable-next-line */
export interface RateCollectionListProps {}

const SearchAddContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const SearchInput = styled(Search)`
  width: 320px;
`;

const AddButton = styled(ButtonPrimary)`
  min-width: 294px;
`;

const RateCollectionListing = styled.div``;

const RateCollectionListingHeader = styled.div<{ theme: GardThemeType }>`
  display: flex;

  > :first-of-type {
    flex: 1 0 35%;
  }

  > :last-of-type {
    flex: 1 1 65%;
    margin-left: ${(props) => props.theme.spacing.xs};
  }
`;

const RateCollectionListingHeaderName = styled(LabelText)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const RateCollectionItem = styled(RowItem)<{ theme: GardThemeType }>`
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const ButtonsRowContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  border: 1px solid transparent;
  margin-left: ${(props) => props.theme.spacing.m};
`;

const ButtonsRow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 0;
  cursor: pointer;
  flex: 1 0;
`;

const Edit = styled(ButtonsRow)<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.secondaryLight};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const Delete = styled(ButtonsRow)<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.dangerLight};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export function RateCollectionList(props: RateCollectionListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search') || '';
  const throttle = useThrottle(500);

  const {
    data: rateCollectionList,
    mutate,
    error: errorFetch,
    isLoading,
  } = useGetRateCollectionList(search);
  const { createRate } = useCreateRateCollection();
  const { updateRate } = useUpdateRateCollection();
  const { deleteRate } = useDeleteRateCollection();

  const error = useAggregateError('danger', errorFetch);

  useSyncAlertControllerWithState(error, [
    error?.label,
    error?.status,
    isLoading,
  ]);

  const { showMessage } = useAlertController();

  return (
    <>
      <SearchAddContainer>
        <SearchInput
          placeholder="Start typing here..."
          onChange={throttle((search: string) => setSearchParams({ search }))}
        />
        <Modal
          triggerElement={
            <AddButton data-testid="add-rate-collector">
              ADD RATE COLLECTOR
            </AddButton>
          }
          title="Add rate collector"
          subtitle="Please enter the information requested in the fields below."
        >
          {({ close }) => (
            <AddRateCollectionModalForm
              defaultValues={{
                name: '',
              }}
              onSubmit={(values) =>
                createRate(values)
                  .then(() => {
                    mutate();
                    close();
                  })
                  .catch((error) =>
                    showMessage({ label: error.message, status: 'danger' })
                  )
              }
              modalClose={close}
            />
          )}
        </Modal>
      </SearchAddContainer>
      <RateCollectionListing>
        <RateCollectionListingHeader>
          <RateCollectionListingHeaderName>
            Project Name
          </RateCollectionListingHeaderName>
          <RateCollectionListingHeaderName>
            Created
          </RateCollectionListingHeaderName>
        </RateCollectionListingHeader>
        {rateCollectionList?.map((item) => (
          <RateCollectionItem
            key={item.id}
            type="rate-row"
            label={item.name}
            rateRowText={item.created}
            linkLabel="See detail"
            linkHref={`/rate_collection/detail/${item.id}`}
            data-testid="rate-collection-item"
          >
            <ButtonsRowContainer>
              <Modal
                triggerElement={
                  <Edit data-testid="edit-rate-collector">
                    <IconEdit />
                  </Edit>
                }
                title="Edit rate collector"
                subtitle="Please enter the information requested in the fields below."
              >
                {({ close }) => (
                  <AddRateCollectionModalForm
                    isEdit
                    defaultValues={{
                      name: item.name,
                    }}
                    onSubmit={({ ...values }) =>
                      updateRate({ collector_id: item.id, ...values })
                        .then(() => {
                          mutate();
                          close();
                        })
                        .catch((error) =>
                          showMessage({
                            label: error.message,
                            status: 'danger',
                          })
                        )
                    }
                    modalClose={close}
                  />
                )}
              </Modal>

              <Modal
                triggerElement={
                  <Delete data-testid="delete-rate-collector">
                    <IconDelete />
                  </Delete>
                }
                title={`Delete '${item.name}'`}
                subtitle="Are you sure you want to delete this project? Every agency and rate linked to it will be deleted too."
                bigTitle
              >
                {({ close }) => (
                  <DialogButtons data-testid="delete-confirm-buttons-rate-collector">
                    <SubmitButton
                      onClick={() =>
                        deleteRate(item.id)
                          .then(() => {
                            mutate();
                            close();
                          })
                          .catch((error) =>
                            showMessage({
                              label: error.message,
                              status: 'danger',
                            })
                          )
                      }
                      data-testid="confirm-delete-rate-collector"
                    >
                      Confirm
                    </SubmitButton>
                    <DialogClose asChild>
                      <ButtonOutlined>Cancel</ButtonOutlined>
                    </DialogClose>
                  </DialogButtons>
                )}
              </Modal>
            </ButtonsRowContainer>
          </RateCollectionItem>
        ))}
      </RateCollectionListing>
    </>
  );
}

export default RateCollectionList;
