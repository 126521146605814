import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useController } from 'react-hook-form';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ErrorText, LabelText } from '../text/text';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerReExported = DatePicker as any;

export interface DatepickerProps {
  label?: string;
  selectedDate?: Date;
  onChange: (date: Date) => void;
  required?: boolean;
  placeholder?: string;
  invalid?: boolean;
}

const DatepickerContainer = styled.div<{
  theme: GardThemeType;
  invalid?: boolean;
}>`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    border: ${(props) =>
      `1px solid ${
        props.invalid ? props.theme.colors.danger : props.theme.colors.greyLight
      }`};
    outline: none;
    border-radius: 3px;
  }
`;

const RequiredAsterics = styled.span`
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  right: 5px;
  top: 25px;
  opacity: 0.5;
`;

const ErrorContainer = styled.div<{
  theme: GardThemeType;
  center?: boolean;
}>`
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  margin-bottom: ${(props) => (props.center ? props.theme.spacing.xs : '0px')};
  position: absolute;
  width: auto !important;
`;

const DatePickerFormInputContainer = styled.div`
  position: relative;
`;

export const Label = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

export function Datepicker(props: DatepickerProps) {
  return (
    <DatepickerContainer invalid={props.invalid}>
      {props.label && <Label>{props.label}</Label>}
      <DatePickerReExported
        selected={props.selectedDate}
        placeholderText={props.placeholder}
        onChange={props.onChange}
      />
      {props.required && <RequiredAsterics>*</RequiredAsterics>}
    </DatepickerContainer>
  );
}

export const DatePickerFormInput = (
  props: Omit<DatepickerProps, 'onChange'> & { name: string }
) => {
  const { field, fieldState } = useController({
    name: props.name,
    defaultValue: props.selectedDate,
    rules: { required: props.required },
  });

  return (
    <DatePickerFormInputContainer>
      <Datepicker
        label={props.label}
        selectedDate={field.value}
        placeholder={props.placeholder}
        onChange={(date) => field.onChange(date)}
        invalid={fieldState.invalid}
        required={props.required}
      />
      {fieldState.error?.message && (
        <ErrorContainer>
          <ErrorText>{fieldState.error?.message}</ErrorText>
        </ErrorContainer>
      )}
    </DatePickerFormInputContainer>
  );
};

export default Datepicker;
