import { useState, useEffect, ReactElement, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useController, useFormState } from 'react-hook-form';
import { ErrorText, LabelText } from '../text/text';
import { useClickOutside } from '@f-technology-srl/react-hook-utils';

export interface SelectProps<Type> {
  label?: string;
  options: Array<{ label: string; value: Type }>;
  selected?: Type | Array<Type>;
  onSelected: (item: Type) => void;
  placeholder?: string;
  children?(option: {
    label: string;
    value: Type;
  }): ReactElement | undefined | JSX.Element;
  type: 'multiple-selection' | 'single-selection';
  testid?: string;
  required?: boolean;
  invalid?: boolean;
}

const IconClose = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.295 7.115c.3894-.38936.3894-1.02064 0-1.41-.3894-.38936-1.0206-.38936-1.41 0L12 10.59 7.115 5.705c-.38936-.38936-1.02064-.38936-1.41 0-.38936.38936-.38936 1.02064 0 1.41L10.59 12l-4.885 4.885c-.38936.3894-.38936 1.0206 0 1.41.38936.3894 1.02064.3894 1.41 0L12 13.41l4.885 4.885c.3894.3894 1.0206.3894 1.41 0 .3894-.3894.3894-1.0206 0-1.41L13.41 12l4.885-4.885Z"
        fill="#BD9E24"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.295 7.115c.3894-.38936.3894-1.02064 0-1.41-.3894-.38936-1.0206-.38936-1.41 0L12 10.59 7.115 5.705c-.38936-.38936-1.02064-.38936-1.41 0-.38936.38936-.38936 1.02064 0 1.41L10.59 12l-4.885 4.885c-.38936.3894-.38936 1.0206 0 1.41.38936.3894 1.02064.3894 1.41 0L12 13.41l4.885 4.885c.3894.3894 1.0206.3894 1.41 0 .3894-.3894.3894-1.0206 0-1.41L13.41 12l4.885-4.885Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
};

const Label = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const Placeholder = styled.span<{ theme: GardThemeType }>`
  color: rgba(114, 114, 114, 0.4);
`;

const DropDownContainer = styled.div<{ theme: GardThemeType }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DropDownHeader = styled.div<{
  theme: GardThemeType;
  focus?: boolean;
  invalid?: boolean;
  type: 'multiple-selection' | 'single-selection';
}>`
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 10px;
  cursor: pointer;
  position: relative;
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.regular};
  border-radius: 3px;
  border: ${(props) =>
    `1px solid ${
      props.invalid
        ? props.theme.colors.danger
        : props.focus
        ? props.theme.colors.primary
        : props.theme.colors.greyLight
    }`};
  height: ${(props) => (props.type === 'single-selection' ? '40px' : 'auto')};
`;

const DropDownListContainer = styled(motion.div)<{
  theme: GardThemeType;
  withLabel: boolean;
}>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${(props) => (props.withLabel ? 64 : 40)}px;
  z-index: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.greyLight03}`};
  background-color: ${(props) => props.theme.colors.white};
`;

const DropDownList = styled.ul<{ theme: GardThemeType }>`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes.pill};
  &:first-child {
    padding-top: 1em;
  }
`;

const ListItem = styled.li<{ theme: GardThemeType }>`
  list-style: none;
  margin-bottom: 1em;
  cursor: pointer;
`;

const Icon = styled.svg<{ theme: GardThemeType; isOpen: boolean }>`
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  width: 8px;
  height: 5px;
  position: absolute;
  top: 17px;
  right: 14px;
  transition: all 0.5s ease-in;
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
`;

const SelectedItem = styled.div<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 2px;
  color: ${(props) => props.theme.colors.white};
  margin-right: 6px;
  margin-bottom: 4px;
  padding: 2px 5px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const IconCloseWrapper = styled.i`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const RequiredAsterics = styled.span`
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0.5;
`;

const ErrorContainer = styled.div<{
  theme: GardThemeType;
  center?: boolean;
}>`
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  margin-bottom: ${(props) => (props.center ? props.theme.spacing.xs : '0px')};
  position: relative;
  width: auto !important;
`;

const SelectFormInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const HiddenSelect = styled.select`
  position: absolute;
  opacity: 0;
  visibility: hidden;
`;

export const Select = React.forwardRef(
  <Type extends string | number>(
    props: SelectProps<Type> &
      Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>,
    ref: React.ForwardedRef<HTMLSelectElement>
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [visibleFilter, setVisibleFilter] = useState<string | null>(null);

    const container = useRef<HTMLDivElement>(null);
    useClickOutside(container, (ev) => {
      if (isOpen) {
        ev.preventDefault();
        ev.stopPropagation();
        setIsOpen(false);
      }
    });

    const visibleOptions = useMemo(
      () =>
        visibleFilter
          ? props.options?.filter((opt) =>
              opt.label
                .toLocaleLowerCase()
                .includes(visibleFilter.toLocaleLowerCase())
            )
          : props.options,
      [visibleFilter, props.options]
    );

    useEffect(() => {
      setVisibleFilter(null);
    }, [isOpen]);

    let selectedLabel: Array<{ label: string; value: Type }> = [];

    if (
      props.type === 'multiple-selection' &&
      Array.isArray(props.selected) &&
      props.selected.length > 0
    ) {
      const sel: Array<Type> = props.selected;
      selectedLabel = props.options?.filter(
        (option: { label: string; value: Type }) =>
          sel.find((item: Type) => item === option.value)
      );
    }

    const onOptionClicked = (value: Type) => {
      props.onSelected(value);
      if (props.type !== 'multiple-selection') {
        setIsOpen(false);
      }
    };

    const toggling = () => setIsOpen((before) => !before);

    useEffect(() => {
      function handleExit(ev: KeyboardEvent) {
        if (ev.key === 'Escape' && isOpen) {
          ev.preventDefault();
          setIsOpen(false);
        }
        if (ev.key !== 'Escape' && isOpen) {
          ev.preventDefault();
          if (ev.key === 'Backspace') {
            setVisibleFilter(null);
          } else {
            setVisibleFilter((filter) =>
              filter ? `${filter}${ev.key}` : ev.key
            );
          }
        }
      }
      document.addEventListener('keydown', handleExit);
      return () => {
        document.removeEventListener('keydown', handleExit);
      };
    }, [isOpen]);

    const value = useMemo(() => {
      if (props.type === 'multiple-selection') {
        return Array.isArray(props.selected)
          ? props.selected.map((item) => `${item}`)
          : [];
      } else {
        return `${props.selected}`;
      }
    }, [props.selected, props.type]);

    return (
      <DropDownContainer className={props.className} ref={container}>
        <HiddenSelect
          ref={ref}
          value={value}
          multiple={props.type === 'multiple-selection'}
          onChange={() => null}
        >
          {props.options.map((option) => {
            return (
              <option key={option.value} value={`${option.value}`}>
                {option.label}
              </option>
            );
          })}
        </HiddenSelect>
        {props.label && <Label>{props.label}</Label>}
        <DropDownHeader
          onClick={toggling}
          focus={isOpen}
          invalid={props.invalid}
          type={props.type}
          data-testid={`select-header-${props.testid}`}
        >
          {props.type === 'multiple-selection' && selectedLabel.length > 0 ? (
            <SelectedContainer>
              {selectedLabel.map((item, key) => {
                return (
                  <SelectedItem key={key}>
                    {item.label}
                    <IconCloseWrapper
                      onClick={(event) => {
                        event.stopPropagation();
                        onOptionClicked(item.value);
                      }}
                    >
                      <IconClose />
                    </IconCloseWrapper>
                  </SelectedItem>
                );
              })}
            </SelectedContainer>
          ) : props.type === 'single-selection' && props.selected ? (
            props.options.find(({ value }) => value === props.selected)?.label
          ) : (
            <Placeholder>{props.placeholder}</Placeholder>
          )}
          <Icon viewBox="0 0 8 5" isOpen={isOpen}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.41421 0C0.523309 0 0.077142 1.07714 0.707107 1.70711L3.29289 4.29289C3.68342 4.68342 4.31658 4.68342 4.70711 4.29289L7.2929 1.70711C7.92286 1.07714 7.47669 0 6.58579 0H1.41421Z"
              fill="#270654"
            />
          </Icon>
          {props.required && <RequiredAsterics>*</RequiredAsterics>}
        </DropDownHeader>
        <AnimatePresence>
          {isOpen && (
            <DropDownListContainer
              withLabel={!!props.label}
              initial={{ maxHeight: 0 }}
              animate={{ maxHeight: 200 }}
              exit={{ maxHeight: 0 }}
            >
              <DropDownList>
                {visibleOptions.map((option) => (
                  <ListItem
                    onClick={() => onOptionClicked(option.value)}
                    key={String(option.value)}
                    data-testid={`select-list-item-${props.testid}`}
                  >
                    {props.children ? props.children(option) : option.label}
                  </ListItem>
                ))}
                {visibleOptions?.length === 0 && (
                  <ListItem>
                    <LabelText>No options</LabelText>
                  </ListItem>
                )}
              </DropDownList>
            </DropDownListContainer>
          )}
        </AnimatePresence>
      </DropDownContainer>
    );
  }
);

export const SelectFormInput = <Type extends string | number>(
  props: Omit<SelectProps<Type>, 'onSelected'> & { name: string }
) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);
  const { field, fieldState } = useController({
    name: props.name,
    defaultValue: props.selected || null,
    rules: { required: props.required },
  });
  const { isSubmitSuccessful, isValid, errors } = useFormState();

  useEffect(() => {
    if (
      fieldState.invalid &&
      fieldState.isDirty &&
      fieldState.error &&
      !isValid &&
      !isSubmitSuccessful &&
      errors
    ) {
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [
    fieldState.invalid,
    fieldState.isDirty,
    fieldState.error,
    isValid,
    isSubmitSuccessful,
    errors,
    props.name,
    field.ref,
  ]);

  return (
    <SelectFormInputContainer>
      <Select
        label={props.label}
        type={props.type}
        options={props.options}
        selected={field.value}
        onSelected={(value) => field.onChange(value)}
        placeholder={props.placeholder}
        invalid={fieldState.invalid}
        required={props.required}
        testid={props.name}
        ref={(newRef) => {
          inputRef.current = newRef;
          field.ref(newRef);
        }}
      />
      {fieldState.error?.message && (
        <ErrorContainer>
          <ErrorText>{fieldState.error?.message}</ErrorText>
        </ErrorContainer>
      )}
    </SelectFormInputContainer>
  );
};

export default Select;
