import { ConnectedNavbar } from '@observatory/front-end/user-features';
import {
  BreadcrumbItemProps,
  ButtonOutlined,
  ButtonPrimary,
  HeadPageButtonsContainer,
  Loader,
  Modal,
  NavbarContainer,
  PageWrapper,
  PillText,
  PillTextBold,
  Table,
  TableDescription,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Divider } from '../../components/divider/divider';
import { useGetRateCollection } from '@observatory/front-end/rate-collection';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useEffect, useState } from 'react';
import { TableRateBenchmarking } from '../../components/table-rate-benchmarking/table-rate-benchmarking';
import { TableProjectHours } from '../../components/table-project-hours/table-project-hours';
import { TableProjectFee } from '../../components/table-project-fee/table-project-fee';
import { TableProjectGraph } from '../../components/table-project-graph/table-project-graph';
import TableQuery from '../../components/table-query/table-query';
import { LoadingContainer } from '../simple-analysis/simple-analysis';
import { useAnalysisResult } from '../../data-access';
import { flatArrayTwoTimes } from '../simple-analysis-results/simple-analysis-results';
import {
  remapResultsToAgency,
  nestRecursiveData,
  ReversedDataInterface,
  sortByAgencyName,
  condenseRowsIfIsNeeded,
} from './data-manipulation-utils';
import CsvExportModalForm from '../../components/csv-export-modal-form/csv-export-modal-form';
import { AgencyInfoDto } from '@observatory/shared/gard/dtos';
import { ArrayColsDescription } from './export-csv-utils';

export interface ExportCsvFileProps {
  data: ReversedDataInterface[];
  colsDescriptionBenchmarking: Array<TableDescription<ReversedDataInterface>>;
  colsDescriptionHours: Array<TableDescription<ReversedDataInterface>>;
  colsDescriptionFees: Array<TableDescription<ReversedDataInterface>>;
}

const ExportCsvFile = ({
  data,
  colsDescriptionBenchmarking,
  colsDescriptionHours,
  colsDescriptionFees,
}: ExportCsvFileProps) => {
  const arrayColsDescription: ArrayColsDescription<ReversedDataInterface> = {
    benchmarking: colsDescriptionBenchmarking,
    hours: colsDescriptionHours,
    fees: colsDescriptionFees,
  };

  return (
    <Modal
      triggerElement={<ButtonPrimary>CSV EXPORT</ButtonPrimary>}
      title="CSV Export"
      subtitle="Please enter the information requested in the fields below."
      customWidth="754px"
      bigTitle
      contentMarginXs
    >
      {({ close }) => (
        <CsvExportModalForm
          onClose={close}
          data={data}
          colsDescription={arrayColsDescription}
        />
      )}
    </Modal>
  );
};

export interface AgenciesTableListModalProps {
  agencies?: Array<AgencyInfoDto>;
}

const AgencyTableDescription: Array<TableDescription<AgencyInfoDto>> = [
  {
    headerName: 'NAME',
    component: (row) => (
      <PillTextBold text-align="left" color="#000">
        {row?.name}
      </PillTextBold>
    ),
  },
  {
    headerName: 'COUNTRY',
    component: (row) => (
      <PillText text-align="left" color="#000">
        {row?.country}
      </PillText>
    ),
  },
  {
    headerName: 'CITY',
    component: (row) => (
      <PillText text-align="left" color="#000">
        {row?.city}
      </PillText>
    ),
  },
];

export const AgenciesTableListModal = (props: AgenciesTableListModalProps) => {
  return (
    <Modal
      triggerElement={
        <ButtonOutlinedMarginLeft
          size="small"
          disabled={!props.agencies?.length}
        >
          INFO
        </ButtonOutlinedMarginLeft>
      }
      title="Agency list"
      customWidth="1000px"
      bigTitle
      contentMarginXs
    >
      {({ close }) => (
        <Table
          colDescription={AgencyTableDescription}
          data={props.agencies?.sort(sortByAgencyName) || []}
        />
      )}
    </Modal>
  );
};

export const ButtonsContainer = styled.div<{
  theme: GardThemeType;
}>`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const ButtonOutlinedMarginLeft = styled(ButtonOutlined)`
  margin-left: ${(props) => props.theme.spacing.sm};
  visibility: hidden;
  padding: 6px 20px;
  background-color: #ffffff;

  :hover {
    background-color: #ffffff;
  }
`;

export const VisibilityButtonOnHoverContainer = styled.div`
  :hover {
    > button {
      visibility: visible;
    }
  }
`;

function scroll(el: HTMLElement) {
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

export function CollectorAnalysisResults() {
  const params = useParams();
  const rateCollectorId = params.rate_collector_id || '';
  const banchMarkResultId = params.banchMarkResultId || '';

  const { data: rateCollector, isLoading } =
    useGetRateCollection(rateCollectorId);
  const {
    data: resultData,
    isLoading: resultDataLoading,
    errorMessage,
  } = useAnalysisResult(banchMarkResultId);

  const flatternResult = resultData?.results
    ? flatArrayTwoTimes(resultData?.results)
    : [];
  const { agenciesAndColName: agencyColumn, reversedData: flatternResultData } =
    remapResultsToAgency(flatternResult);

  const condensedFlatternResultData =
    condenseRowsIfIsNeeded(flatternResultData);

  const graphFeesData = {
    name: 'Sum of all',
    children: nestRecursiveData(
      flatternResult,
      ['role_generic_descriptor', 'department', 'sub_section', 'agency'],
      'fee'
    ),
  };

  const graphBillableHourYear = {
    name: 'Sum of all',
    children: nestRecursiveData(
      flatternResult,
      ['role_generic_descriptor', 'department', 'sub_section', 'agency'],
      'hous'
    ),
  };

  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      link: '/',
      active: false,
      label: 'GARD',
    },
    {
      link: `/rate_collection`,
      active: false,
      label: 'Rate collection list',
    },
    {
      link: `/rate_collection/detail/${rateCollectorId}`,
      active: false,
      label: 'Rate collection',
    },
    {
      link: `/collector_analysis/${rateCollectorId}`,
      active: false,
      label: 'Compare analysis',
    },
    {
      link: '',
      active: true,
      label: 'Result',
    },
  ];

  // table columns Benchmarking
  const colDescription: Array<TableDescription<ReversedDataInterface>> = [
    {
      headerName: 'Department',
      component: (row) => (
        <PillText text-align="left" color="#000">
          {row?.department}
        </PillText>
      ),
      collapsedKey: (row) => row?.department,
      csv: (row) => row?.department,
    },
    {
      headerName: 'Role',
      component: (row) => (
        <PillText text-align="left" color="#727272">
          {row?.role_generic_descriptor}
        </PillText>
      ),
      csv: (row) => row?.role_generic_descriptor,
    },
    ...agencyColumn.map((agency) => ({
      headerName: agency.colName,
      component: (row: ReversedDataInterface | undefined) => (
        <PillText text-align="left" color="#727272">
          {row?.row[agency.colName]?.rate?.toFixed(2)}
        </PillText>
      ),
      csv: (row: ReversedDataInterface | undefined) =>
        row?.row[agency.colName]?.rate?.toFixed(2),
    })),
    {
      headerName: 'Tool Benchmark',
      component: (row) => (
        <PillText text-align="left" color="#727272">
          {row?.standardRow?.compareRate?.toFixed(2)}
        </PillText>
      ),
      csv: (row) => row?.standardRow?.compareRate?.toFixed(2),
    },
    {
      headerName: 'Number of rates',
      component: (row) => (
        <PillText text-align="left" color="#727272">
          {row?.standardRow?.compareCount}
        </PillText>
      ),
      csv: (row) => row?.standardRow?.compareCount,
    },
    {
      headerName: 'Number of agency',
      component: (row) => (
        <VisibilityButtonOnHoverContainer>
          <PillText text-align="left" color="#727272">
            {row?.standardRow?.agencies.length}
          </PillText>
          <AgenciesTableListModal agencies={row?.standardRow?.agencies} />
        </VisibilityButtonOnHoverContainer>
      ),
      csv: (row) => row?.standardRow?.agencies.length,
    },
  ];

  // table columns Hours
  const colDescriptionHours: Array<TableDescription<ReversedDataInterface>> = [
    {
      headerName: 'Department',
      component: (row) => (
        <PillText text-align="left" color="#000">
          {row?.department}
        </PillText>
      ),
      collapsedKey: (row) => row?.department,
      csv: (row) => row?.department,
    },
    {
      headerName: 'Role',
      component: (row) => (
        <PillText text-align="left" color="#727272">
          {row?.role_generic_descriptor}
        </PillText>
      ),
      csv: (row) => row?.role_generic_descriptor,
    },
    ...agencyColumn.map((agency) => ({
      headerName: agency.colName,
      component: (row: ReversedDataInterface | undefined) => (
        <PillText text-align="left" color="#727272">
          {row?.row[agency.colName]?.total_billable_hours_in_year?.toFixed(2)}
        </PillText>
      ),
      csv: (row: ReversedDataInterface | undefined) =>
        row?.row[agency.colName]?.total_billable_hours_in_year?.toFixed(2),
    })),
  ];

  // table columns Fees
  const colDescriptionFees: Array<TableDescription<ReversedDataInterface>> = [
    {
      headerName: 'Department',
      component: (row) => (
        <PillText text-align="left" color="#000">
          {row?.department}
        </PillText>
      ),
      collapsedKey: (row) => row?.department,
      csv: (row) => row?.department,
    },
    {
      headerName: 'Role',
      component: (row) => (
        <PillText text-align="left" color="#727272">
          {row?.role_generic_descriptor}
        </PillText>
      ),
      csv: (row) => row?.department,
    },
    ...agencyColumn.map((agency) => ({
      headerName: agency.colName,
      component: (row: ReversedDataInterface | undefined) => (
        <PillText text-align="left" color="#727272">
          {row?.row[agency.colName]?.fee?.toFixed(2)}
        </PillText>
      ),
      csv: (row: ReversedDataInterface | undefined) =>
        row?.row[agency.colName]?.fee?.toFixed(2),
    })),
  ];

  // status buttons container
  const [statusOpenAncors, setOpenAncors] = useState(true);

  const [statusBenchmarking, setStatusBenchmarking] = useState(true);
  const [statusHours, setStatusHours] = useState(false);
  const [statusFees, setStatusFees] = useState(false);
  const [hoursGraphShow, sethoursGraphShow] = useState(false);
  const [feesGraphShow, setfeesGraphShow] = useState(false);
  const [statusQuery, setStatusQuery] = useState(false);

  useEffect(() => {
    const hours = document.getElementById('hours') as HTMLElement;
    if (hours) {
      scroll(hours);
    }
  }, [statusHours]);

  useEffect(() => {
    const fees = document.getElementById('fees') as HTMLElement;
    if (fees) {
      scroll(fees);
    }
  }, [statusFees]);

  useEffect(() => {
    const graph_billable = document.getElementById(
      'graph_billable'
    ) as HTMLElement;
    if (graph_billable) {
      scroll(graph_billable);
    }
  }, [hoursGraphShow]);

  useEffect(() => {
    const graph_fees = document.getElementById('graph_fees') as HTMLElement;
    if (graph_fees) {
      scroll(graph_fees);
    }
  }, [feesGraphShow]);

  useEffect(() => {
    const query = document.getElementById('query') as HTMLElement;
    if (query) {
      scroll(query);
    }
  }, [statusQuery]);

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  return (
    <PageWrapper title={rateCollector?.name} breadcrumbs={breadcrumbs}>
      <HeadPageButtonsContainer>
        <ExportCsvFile
          data={condensedFlatternResultData}
          colsDescriptionBenchmarking={colDescription}
          colsDescriptionHours={colDescriptionHours}
          colsDescriptionFees={colDescriptionFees}
        />

        <ButtonPrimary
          onClick={() => setOpenAncors((prevStatus) => !prevStatus)}
        >
          {statusOpenAncors ? 'CLOSE ANCORS' : 'OPEN ANCORS'}
        </ButtonPrimary>
      </HeadPageButtonsContainer>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <Divider marginBottom="15px"></Divider>
      {(isLoading || resultDataLoading) && (
        <LoadingContainer>
          <Loader type="circle" />
        </LoadingContainer>
      )}

      {statusOpenAncors ? (
        <ButtonsContainer aria-expanded={statusOpenAncors}>
          <ButtonOutlined
            active={statusBenchmarking}
            onClick={() =>
              setStatusBenchmarking(
                (prevStatusBenchmarking) => !prevStatusBenchmarking
              )
            }
            key="benchmarking"
          >
            Rate benchmarking
          </ButtonOutlined>
          <ButtonOutlined
            active={statusHours}
            onClick={() => {
              setStatusHours((prevStatusHours) => !prevStatusHours);
            }}
            key="hours"
          >
            Project hours table
          </ButtonOutlined>
          <ButtonOutlined
            active={statusFees}
            onClick={() => setStatusFees((prevStatusFees) => !prevStatusFees)}
            key="fees"
          >
            Project fee's table
          </ButtonOutlined>
          <ButtonOutlined
            active={hoursGraphShow}
            onClick={() =>
              sethoursGraphShow((prevhoursGraphShow) => !prevhoursGraphShow)
            }
            key="graph_billable"
          >
            Project Hours Graph
          </ButtonOutlined>
          <ButtonOutlined
            active={feesGraphShow}
            onClick={() =>
              setfeesGraphShow((prevfeesGraphShow) => !prevfeesGraphShow)
            }
            key="graph_fees"
          >
            Project Fees Graph
          </ButtonOutlined>
          <ButtonOutlined
            active={statusQuery}
            onClick={() =>
              setStatusQuery((prevStatusQuery) => !prevStatusQuery)
            }
            key="query"
          >
            Query
          </ButtonOutlined>
        </ButtonsContainer>
      ) : null}

      <TableRateBenchmarking
        title="Rate benchmarking"
        weight="regular"
        data={condensedFlatternResultData}
        colDescription={
          statusBenchmarking
            ? colDescription
            : colDescription.filter((i) => i.headerName !== 'Number of rates')
        }
        marginTop={'30px'}
      />

      {statusHours ? (
        <TableProjectHours
          title="PROJECT HOURS"
          weight="regular"
          data={condensedFlatternResultData}
          colDescription={colDescriptionHours}
          id="hours"
        />
      ) : null}

      {statusFees ? (
        <TableProjectFee
          title="PROJECT FEES"
          weight="regular"
          data={condensedFlatternResultData}
          colDescription={colDescriptionFees}
          id="fees"
        />
      ) : null}

      {hoursGraphShow ? (
        <TableProjectGraph
          title="PROJECT HOURS GRAPH"
          weight="regular"
          data={graphBillableHourYear}
          id="graph_billable"
        />
      ) : null}

      {feesGraphShow ? (
        <TableProjectGraph
          title="PROJECT FEES GRAPH"
          weight="regular"
          data={graphFeesData}
          id="graph_fees"
        />
      ) : null}

      {statusQuery ? (
        <TableQuery
          title="Query"
          weight="regular"
          id="query"
          query={resultData?.from_query}
        />
      ) : null}
    </PageWrapper>
  );
}

export default CollectorAnalysisResults;
