import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';

export interface TableTitleProps {
  color?: string;
  background?: string;
  title: string;
  weight?: string;
}

const TableTop = styled.div<{ theme: GardThemeType; weight?: string }>`
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.opacheWhite};
  font-family: ${(props) =>
    props.weight === 'regular'
      ? props.theme.fonts.regular
      : props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes.h5};
  line-height: 1.48;
  text-align: center;
  padding-top: ${(props) => props.theme.spacing.xs};
  padding-bottom: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.s};
  width: 100%;
`;

export function TableTitle(props: TableTitleProps) {
  return (
    <TableTop
      className="table-title"
      weight={props.weight}
      style={{
        backgroundColor: `${props.background}`,
        color: `${props.color}`,
      }}
    >{`${props.title}`}</TableTop>
  );
}

export default TableTitle;
