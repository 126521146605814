import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import { LabelText } from '../text/text';

/* eslint-disable-next-line */
export interface AgencyHeaderProps {}

const AgencyHeaderContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const AgencyLabel = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  flex: 1 1;
`;

const ContactsLabel = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  flex: 2 1;
  padding-left: ${(props) => props.theme.spacing.sm};
`;

const SubSectionsLabel = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  flex: 1 1;
`;

const ActionsLabel = styled(LabelText)<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.primary};
  width: 106px;
`;

export function AgencyHeader(props: AgencyHeaderProps) {
  return (
    <AgencyHeaderContainer>
      <AgencyLabel>Agency</AgencyLabel>
      <ContactsLabel>Contacts</ContactsLabel>
      <SubSectionsLabel>Sub-sections</SubSectionsLabel>
      <ActionsLabel>Actions</ActionsLabel>
    </AgencyHeaderContainer>
  );
}

export default AgencyHeader;
