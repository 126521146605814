import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  CollectorRateDto,
  UpdateCollectorRateDto,
} from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetRate = (agency_id: string, sub_section_id: string) => {
  const fetcher = useFetcher();
  const { error: fetchError, handle: handleFetch } = useHandleRespError(false);
  const { error: actionError, handle: handleAction } = useHandleRespError();

  const {
    data,
    error: getError,
    mutate,
  } = useSWR<CollectorRateDto>(
    `/api/sub-section/sub_section/${sub_section_id}/agency/${agency_id}`,
    fetcher
  );

  const error = !!actionError || !!getError || !!fetchError;

  return {
    data: handleFetch(data),
    isLoading: !error && !data,
    isError: error,
    errorMessage: actionError || fetchError,
    async updateRate(body: UpdateCollectorRateDto) {
      const result = await fetcher('/api/sub-section/update', {
        method: 'PUT',
        body,
      });
      const collector = handleAction<CollectorRateDto>(result);
      await mutate();
      return collector;
    },
    mutate,
  };
};
