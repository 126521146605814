import { useLogin } from '@f-technology-srl/client-authorization';
import {
  AdditionalLoggedInNavbarContainer,
  Navbar,
} from '@observatory/front-end/core-ui';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetUserInfo } from '../hooks';
import { ShowIfAdmin } from './show-if-admin';

const ToolsIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.488 24c-.016 0-.404-.002-.626-.257-.017-.033-.209-.41-.634-2.564a4.699 4.699 0 0 0-.282-.897l-.19-.449-.452.183a4.716 4.716 0 0 0-.835.435c-1.825 1.221-2.224 1.353-2.285 1.367-.289-.002-.513-.183-.575-.24l-2.151-2.149c-.013-.012-.296-.29-.273-.636.01-.038.143-.438 1.366-2.262.16-.24.306-.522.433-.835l-.265-.642a4.781 4.781 0 0 0-.898-.282c-2.153-.426-2.531-.617-2.584-.65-.199-.174-.232-.485-.236-.576v-3.04c-.002-.018-.005-.415.256-.642.034-.019.41-.21 2.564-.635.284-.057.586-.152.897-.283l.449-.19-.184-.451a4.63 4.63 0 0 0-.432-.835C2.328 5.646 2.195 5.245 2.182 5.184c-.016-.264.179-.507.24-.575l2.15-2.151c.013-.013.268-.273.594-.273.078.012.48.144 2.302 1.366.24.159.522.306.836.433l.451.184.19-.45c.13-.31.227-.612.283-.896.425-2.155.616-2.532.65-2.585.174-.199.484-.232.576-.236L13.496 0c.047 0 .423.007.64.257.019.034.209.412.634 2.564.058.285.152.588.283.897l.19.45.453-.183c.313-.129.594-.275.833-.434 1.825-1.222 2.226-1.354 2.289-1.368.286.002.51.184.574.24l2.15 2.149c.014.013.299.29.274.636-.012.037-.144.437-1.365 2.262-.161.239-.308.52-.436.834l-.183.451.45.19c.308.131.61.227.895.283 2.155.426 2.532.617 2.585.65.199.175.232.486.237.577l.001 3.04c0 .017.005.415-.258.643-.033.017-.41.208-2.563.633a4.685 4.685 0 0 0-.897.283l-.449.191.182.45c.128.316.274.596.436.836 1.22 1.825 1.352 2.225 1.367 2.286.017.264-.18.507-.24.575l-2.148 2.151c-.013.013-.269.273-.594.273-.08-.012-.481-.144-2.306-1.366a4.595 4.595 0 0 0-.834-.434l-.452-.183-.19.449c-.13.31-.225.612-.283.896-.426 2.154-.616 2.531-.649 2.585-.174.199-.486.232-.576.236l-3.058.002zm1.511-16.512A4.519 4.519 0 0 0 7.487 12a4.519 4.519 0 0 0 4.512 4.513A4.518 4.518 0 0 0 16.512 12a4.518 4.518 0 0 0-4.513-4.513z"
        fill="#fff"
      />
    </svg>
  );
};

const UsersMangamentLink = styled(Link)`
  display: flex;
`;

export function ConnectedNavbar() {
  const navigate = useNavigate();
  const { logout, loggedIn } = useLogin();
  const { data: userInfo } = useGetUserInfo();

  if (loggedIn) {
    return (
      <Navbar
        type="loggedIn"
        logoutOnClick={logout}
        username={loggedIn ? userInfo?.username || '' : ''}
      >
        <AdditionalLoggedInNavbarContainer>
          <ShowIfAdmin>
            <UsersMangamentLink
              to="/users_management"
              data-testid="user-management-link"
            >
              <ToolsIcon />
            </UsersMangamentLink>
          </ShowIfAdmin>
        </AdditionalLoggedInNavbarContainer>
      </Navbar>
    );
  } else {
    return <Navbar type="loggedOut" loginOnClick={() => navigate('/login')} />;
  }
}
