import {
  Loader,
  PageWrapper,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { useParams } from 'react-router-dom';
import { useGetIsRateNew } from '../../data-access';
import RateCollectionSubSectionCreate, {
  LoadingContainer,
} from '../rate-collection-sub-section-create/rate-collection-sub-section-create';
import RateCollectionSubSectionEdit from '../rate-collection-sub-section-edit/rate-collection-sub-section-edit';

export function RateCollectionSubSectionSwitch() {
  const params = useParams();

  const agency_id = params?.agency_id || '';
  const sub_section_id = params?.sub_section_id || '';

  const { data, errorMessage, mutate } = useGetIsRateNew(
    agency_id,
    sub_section_id
  );

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  if (!data) {
    return (
      <PageWrapper>
        <LoadingContainer>
          <Loader type="circle" />
        </LoadingContainer>
      </PageWrapper>
    );
  }

  if (data.completed) {
    return <RateCollectionSubSectionEdit />;
  }

  return <RateCollectionSubSectionCreate mutateOnSubmit={mutate} />;
}

export default RateCollectionSubSectionSwitch;
