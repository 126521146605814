import {
  useAggregateError,
  useAggregateResets,
} from '@f-technology-srl/react-hook-utils';
import {
  Agency,
  AgencyHeader,
  AgencyProps,
  ButtonIcon,
  ButtonOutlined,
  DialogButtons,
  HistoricizeButton,
  IconDelete,
  Modal,
  PillText,
  SubmitButton,
  SubProps,
  useAlertController,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { useIsAdmin } from '@observatory/front-end/user-features';
import { ContactDto, NewContactDto } from '@observatory/shared/gard/dtos';
import { DialogClose } from '@radix-ui/react-dialog';
import styled from 'styled-components';
import {
  useDeleteAgency,
  useDeleteSubSectionSubmission,
  useGetAgencyContactsList,
  useMakeSubsectionHistoric,
  useUpdateAgency,
} from '../../data-access';
import AddAgencyModalForm from '../add-agency-modal-form/add-agency-modal-form';
import { AddContactModalForm } from '../add-contact-modal-form/add-contact-modal-form';

/* eslint-disable-next-line */
export interface AgenciesListProps {
  reloadAgencies: () => unknown;
  aviableSubSections: Array<SubProps>;
  agencies: Array<
    Omit<
      AgencyProps,
      | 'editOnClick'
      | 'deleteOnClick'
      | 'dashboardOnClick'
      | 'addOnClick'
      | 'contacts'
      | 'addModal'
    >
  >;
}

const AgencyListContainer = styled.div<{ theme: GardThemeType }>`
  margin-top: ${(props) => props.theme.spacing.m};
`;

const AgencyItem = styled(Agency)<{ theme: GardThemeType }>`
  margin-bottom: ${(props) => props.theme.spacing.xs};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ButtonsRow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 0;
  cursor: pointer;
  flex: 1 0;
`;

const DeleteButton = styled(ButtonsRow)<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.dangerLight};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export interface AddContactProps {
  agencyName: string;
  onSubmit: (values: NewContactDto) => Promise<unknown>;
}

export function AddContact(props: AddContactProps) {
  return (
    <Modal
      triggerElement={<ButtonIcon type="add" />}
      title={`Add contact for ${props.agencyName}`}
      subtitle="Please enter the information requested in the fields below."
    >
      {({ close }) => (
        <AddContactModalForm
          onSubmit={(values) => props.onSubmit(values).then(() => close())}
          modalClose={close}
        />
      )}
    </Modal>
  );
}

export function EditAgencyModal(props: {
  agencyId: string;
  agencyName: string;
  subSections: Array<SubProps>;
  aviableSubSections: Array<SubProps>;
  mutate: () => unknown;
}) {
  const { updateAgency, error } = useUpdateAgency();

  useSyncAlertControllerWithState(
    error
      ? {
          label: error,
          status: 'danger',
        }
      : undefined,
    [error]
  );

  return (
    <Modal
      triggerElement={<ButtonIcon type="edit" />}
      title="Edit agency"
      subtitle="Change the agency name, deselect a subsection or add a new one"
    >
      {({ close }) => (
        <AddAgencyModalForm
          onSubmit={(values) =>
            updateAgency({
              id: props.agencyId,
              selected_subsections: values.subSections,
              name: values.agencyName,
            })
              .then(() => {
                props.mutate();
                close();
              })
              .catch(() => {
                // error is handle above
              })
          }
          modalClose={close}
          agencyNameDefault={props.agencyName}
          subSectionsDefault={props.subSections.map(
            (sub) => sub.sub_section_id
          )}
          subSectionsOptions={props.aviableSubSections.map((sub) => ({
            label: sub.label,
            value: sub.sub_section_id,
            disabled: props.subSections?.find(
              (agencySub) => agencySub.sub_section_id === sub.sub_section_id
            )?.completed,
          }))}
          edit
        />
      )}
    </Modal>
  );
}

export function DeleteAgencyModal(props: {
  agencyId: string;
  agencyName: string;
  mutate: () => unknown;
}) {
  const { deleteAgency, error } = useDeleteAgency();

  useSyncAlertControllerWithState(
    error
      ? {
          label: error,
          status: 'danger',
        }
      : undefined,
    [error]
  );

  return (
    <Modal
      triggerElement={<ButtonIcon type="delete" />}
      title={`Delete agency '${props.agencyName}'`}
      subtitle="Are you sure you want to delete this agency? Every rate linked to it will be deleted too."
    >
      {({ close }) => (
        <DialogButtons>
          <SubmitButton
            onClick={() =>
              deleteAgency(props.agencyId).then(() => {
                props.mutate();
                close();
              })
            }
          >
            Confirm
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Cancel</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      )}
    </Modal>
  );
}

export interface HisotricizeModalProps {
  subSection: SubProps;
  onPressButton: () => Promise<unknown>;
}

export function HisotricizeModal(props: HisotricizeModalProps) {
  const title = props.subSection.historic
    ? 'Remove rate from the collection database'
    : 'Import rate in the collection database';
  const subtitle = props.subSection.historic
    ? 'Are you sure you want to remove this rate from the collection database?'
    : 'Are you sure you want to import this rate into the collection database?';
  const isAdmin = useIsAdmin();

  return (
    <Modal
      triggerElement={
        <HistoricizeButton
          disabled={!props.subSection.completed || !isAdmin}
          isHistoric={props.subSection.historic}
        />
      }
      title={title}
      subtitle={subtitle}
    >
      {({ close }) => (
        <DialogButtons>
          <SubmitButton onClick={() => props.onPressButton().finally(close)}>
            Confirm
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Cancel</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      )}
    </Modal>
  );
}

export interface RemoveSubSectionSubmissionModalProps {
  subSection: SubProps;
  onPressButton: () => Promise<unknown>;
}

export function RemoveSubSectionSubmissionModal(
  props: RemoveSubSectionSubmissionModalProps
) {
  const isAdmin = useIsAdmin();

  return (
    <Modal
      triggerElement={
        <DeleteButton
          style={{ marginRight: 10 }}
          disabled={
            !props.subSection.completed || props.subSection.historic || !isAdmin
          }
        >
          <IconDelete />
        </DeleteButton>
      }
      title={'Remove the entry'}
      subtitle={
        'Are you sure you want to remove the entry related to this subsection?'
      }
    >
      {({ close }) => (
        <DialogButtons>
          <SubmitButton onClick={() => props.onPressButton().finally(close)}>
            Confirm
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Cancel</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      )}
    </Modal>
  );
}

export interface EditContactProps {
  contactData: ContactDto;
  onSubmit: (values: NewContactDto) => Promise<unknown>;
  isModalOpen: boolean;
  modalChangeState: (state: boolean) => unknown;
}

export function EditContact(props: EditContactProps) {
  const { id, ...defaultVals } = props.contactData;
  return (
    <Modal
      controlOpen={props.isModalOpen}
      toggleOpen={props.modalChangeState}
      title={`Edit contact`}
      subtitle="Please enter the information requested in the fields below."
    >
      {({ close }) => (
        <AddContactModalForm
          onSubmit={(values) => props.onSubmit(values).then(() => close())}
          modalClose={close}
          defaultValues={defaultVals}
        />
      )}
    </Modal>
  );
}

export function AgencyItemConnected(
  props: AgenciesListProps['agencies'][0] &
    Pick<AgenciesListProps, 'reloadAgencies'> &
    Pick<AgenciesListProps, 'aviableSubSections'>
) {
  const { showMessageWithTimeout } = useAlertController();

  const {
    data: contacts,
    createAgencyContact,
    updateAgencyContact,
    deleteAgencyContact,
  } = useGetAgencyContactsList(props.id);

  const { makeHistoric, rollbackHistoric } = useMakeSubsectionHistoric();

  const { deleteSubSectionSubmission } = useDeleteSubSectionSubmission();

  return (
    <AgencyItem
      key={props.id}
      id={props.id}
      name={props.name}
      contacts={contacts || []}
      subs={props.subs}
      deleteOnClick={(contact) => deleteAgencyContact(contact.id)}
      dashboardHref={`/rate_collection/dashboard/${props.id}`}
      dashboardState={props}
      editModal={(open, close, contact) => (
        <EditContact
          contactData={contact}
          isModalOpen={open}
          modalChangeState={(willOpen) => (!willOpen ? close() : null)}
          onSubmit={(contactData) =>
            updateAgencyContact({
              id: contact.id,
              email: contactData.email,
              name: contactData.name,
            })
          }
        />
      )}
      addModal={
        <AddContact
          agencyName={props.name}
          onSubmit={(values) =>
            createAgencyContact({
              name: values.name,
              email: values.email,
            })
          }
        />
      }
      historicizeModal={(sub) => (
        <HisotricizeModal
          subSection={sub}
          onPressButton={() =>
            (sub.historic
              ? rollbackHistoric(props.id, sub.sub_section_id).then((result) =>
                  result?.success
                    ? showMessageWithTimeout({
                        status: 'warning',
                        label: `Removed ${result?.removed} rates from collection database`,
                      })
                    : null
                )
              : makeHistoric(props.id, sub.sub_section_id).then((result) =>
                  result?.success
                    ? showMessageWithTimeout({
                        status: 'success',
                        label: `Added ${result?.created} rates to the collection database`,
                      })
                    : null
                )
            )
              .catch((error) => {
                showMessageWithTimeout({
                  status: 'danger',
                  label: error.message,
                });
              })
              .finally(() => props.reloadAgencies())
          }
        />
      )}
      removeSubSectionSubmission={(sub) => (
        <RemoveSubSectionSubmissionModal
          subSection={sub}
          onPressButton={() =>
            deleteSubSectionSubmission(props.id, sub.sub_section_id)
              .then(() => {
                showMessageWithTimeout({
                  status: 'success',
                  label: `Removed ${sub.label} from the collection database`,
                });
              })
              .catch((error) => {
                showMessageWithTimeout({
                  status: 'danger',
                  label: error.message,
                });
              })
              .finally(() => props.reloadAgencies())
          }
        />
      )}
      editAgency={
        <EditAgencyModal
          agencyId={props.id}
          agencyName={props.name}
          subSections={props.subs}
          aviableSubSections={props.aviableSubSections}
          mutate={props.reloadAgencies}
        />
      }
      deleteAgency={
        <DeleteAgencyModal
          agencyId={props.id}
          agencyName={props.name}
          mutate={props.reloadAgencies}
        />
      }
    />
  );
}

export function AgenciesList(props: AgenciesListProps) {
  return (
    <AgencyListContainer>
      <AgencyHeader />
      {props.agencies ? (
        props.agencies.map((agency) => {
          return (
            <AgencyItemConnected
              reloadAgencies={props.reloadAgencies}
              key={agency.id}
              id={agency.id}
              name={agency.name}
              subs={agency.subs}
              aviableSubSections={props.aviableSubSections}
            />
          );
        })
      ) : (
        <PillText>No agencies found.</PillText>
      )}
    </AgencyListContainer>
  );
}

export default AgenciesList;
