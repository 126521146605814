import { joiResolver } from '@hookform/resolvers/joi';
import {
  ButtonOutlined,
  CheckboxFormInput,
  DialogButtons,
  InputFormInput,
  LabelText,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { DialogClose } from '@radix-ui/react-dialog';
import Joi from 'joi';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface AddAgencyModalFormProps {
  onSubmit: (values: AddAgencyFormValues) => void | Promise<void>;
  subSectionsOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  subSectionsDefault?: Array<string>;
  agencyNameDefault?: string;
  modalClose: () => void;
  edit?: boolean;
}

const AddAgencyModalFormContainer = styled.form``;

const SubSectionsCheckboxLabel = styled(LabelText)<{ theme: GardThemeType }>`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: ${(props) => props.theme.spacing.s};
  margin-top: ${(props) => props.theme.spacing.m};
`;

const Checkbox = styled(CheckboxFormInput)`
  margin-bottom: ${(props) => props.theme.spacing.s};
`;

export type AddAgencyFormValues = {
  agencyName: string;
  subSections: Array<string>;
};

const schema = Joi.object({
  agencyName: Joi.string().required().messages({
    'string.base': `The field 'Agency name' is required`,
  }),
  subSections: Joi.array().items(Joi.string()).optional(),
});

export function AddAgencyModalForm(props: AddAgencyModalFormProps) {
  const formMethods = useForm<AddAgencyFormValues>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
    defaultValues: {
      agencyName: props.agencyNameDefault,
      subSections: props.subSectionsDefault || [],
    },
  });

  return (
    <FormProvider {...formMethods}>
      <AddAgencyModalFormContainer
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
      >
        <InputFormInput
          label="Agency name"
          name="agencyName"
          type="text"
          placeholder="Agency name"
          required
        />
        <SubSectionsCheckboxLabel>
          {props.edit
            ? 'Change subsections for this agency'
            : 'Select subsections for this agency'}
        </SubSectionsCheckboxLabel>
        {props.subSectionsOptions.map((sub, key) => {
          return (
            <Checkbox
              name="subSections"
              disabled={sub.disabled}
              value={sub.value}
              label={sub.label}
              key={key}
            />
          );
        })}
        <DialogButtons>
          <SubmitButton
            disabled={formMethods.formState.isSubmitting}
            type="submit"
          >
            {props.edit ? 'Save' : 'Create'}
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Close</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      </AddAgencyModalFormContainer>
    </FormProvider>
  );
}

export default AddAgencyModalForm;
