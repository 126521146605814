/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGlobalStyle } from 'styled-components';
import CenturyGothic from './fonts/Century-Gothic.woff';
import CenturyGothicBold from './fonts/Century-Gothic-Bold.woff';
import CenturyGothicItalic from './fonts/Century-Gothic-Italic.woff';
import CenturyGothicBoldItalic from './fonts/Century-Gothic-Bold-Italic.woff';

export const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Century Gothic Regular';
        src: local('Century Gothic'), local('CenturyGothic'),
        url(${CenturyGothic}) format('woff');
        font-weight: 400;
        font-style: normal;
    }

     @font-face {
        font-family: 'Century Gothic Bold';
        src: local('Century Gothic Bold'), local('CenturyGothicBold'),
        url(${CenturyGothicBold}) format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Century Gothic Italic';
        src: local('Century Gothic Italic'), local('CenturyGothicItalic'),
        url(${CenturyGothicItalic}) format('woff');
        font-weight: 400;
        font-style: normal;
    }

     @font-face {
        font-family: 'Century Gothic Bold Italic';
        src: local('Century Gothic Bold Italic'), local('CenturyGothicBoldItalic'),
        url(${CenturyGothicBoldItalic}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
` as any;

export const GlobalReset = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: none;
  }

  body {
    margin: 0;
  }
` as any;

export const GlobalStyles = () => {
  return (
    <>
      <GlobalReset />
      <GlobalFonts />
    </>
  );
};
