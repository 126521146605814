import {
  CheckboxSelectFormInput,
  SelectProps,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { AviableOptions, useOptions } from '../../data-access';

export interface OptionsCheckboxSelectProps {
  label: string;
  name: string;
  option: AviableOptions;
  placeholder?: string;
  required?: boolean;
  type?: SelectProps<string>['type'];
  optionsSearchParams?: URLSearchParams;
}

export const OptionsCheckboxSelect = (props: OptionsCheckboxSelectProps) => {
  const { data, errorMessage } = useOptions(
    props.option,
    props.optionsSearchParams
  );

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  return (
    <div>
      <CheckboxSelectFormInput
        label={props.label}
        options={data || []}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
      />
    </div>
  );
};

export default OptionsCheckboxSelect;
