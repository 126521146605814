import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { NewCollectorDto, CollectorDto } from '@observatory/shared/gard/dtos';

export function useCreateRateCollection() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  return {
    error,
    async createRate(data: NewCollectorDto) {
      const result = await fetcher('/api/rate-collection/create', {
        method: 'POST',
        body: data,
      });

      return handle<Array<CollectorDto>>(result);
    },
  };
}
