import styled from 'styled-components';
import {
  Checkbox,
  CheckboxFormInputContainer,
  CheckboxProps,
  ErrorContainer,
  ErrorText,
} from '@observatory/front-end/core-ui';
import { useController } from 'react-hook-form';
import { GardThemeType } from '@observatory/front-end/gard-theme';

const CheckboxBooleanFormInputContainer = styled(CheckboxFormInputContainer)<{
  theme: GardThemeType;
}>`
  padding-top: ${(props) => props.theme.spacing.s};
`;

export const BooleanCheckbox = (
  props: Omit<CheckboxProps<boolean>, 'onChange' | 'isChecked' | 'value'> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  const { field, fieldState } = useController({
    name: props.name,
  });

  const toggle = () => {
    field.onChange(!field.value);
  };

  return (
    <CheckboxBooleanFormInputContainer className={props.className}>
      <Checkbox
        name={props.name}
        isChecked={field.value}
        value={`${field.value}`}
        label={props.label}
        onChange={toggle}
        onClick={toggle}
        disabled={props.disabled}
      />
      {fieldState.error?.message && (
        <ErrorContainer>
          <ErrorText>{fieldState.error?.message}</ErrorText>
        </ErrorContainer>
      )}
    </CheckboxBooleanFormInputContainer>
  );
};

export default Checkbox;
