import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

@ValidatorConstraint({ name: 'isPast', async: false })
export class IsPastConstraint implements ValidatorConstraintInterface {
  validate(propertyValue: string) {
    const parsed = new Date(propertyValue);
    if (!isNaN(parsed.getTime())) {
      const now = new Date();
      return parsed.getTime() < now.getTime();
    }
    return false;
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} must be a past date`;
  }
}
