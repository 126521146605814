import { RequiredAuth } from '@f-technology-srl/client-authorization';
import { Route, Routes } from 'react-router-dom';
import { ShowIfAdmin } from './component';
import { UsersManagement } from './pages';

export const UsersManagementRoutes = () => (
  <Routes>
    <Route
      path=""
      element={
        <RequiredAuth redirectUri="/login">
          <ShowIfAdmin redirectTo="/">
            <UsersManagement />
          </ShowIfAdmin>
        </RequiredAuth>
      }
    />
  </Routes>
);
