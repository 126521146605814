import useSWR from 'swr';
import { PaginateResultLogCheckDto } from '@observatory/shared/gard/dtos';
import { useFetcher } from '@f-technology-srl/client-authorization';

export const useGetLogList = (page = 0, offset = 20, search = '') => {
  const fetcher = useFetcher();
  const { data, error } = useSWR<PaginateResultLogCheckDto>(
    `/api/log/list?page=${page}&offset=${offset}&search=${search}`,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error as Error,
  };
};
