import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';
import { PillText } from '../..';

/* eslint-disable-next-line */
export interface ContactCardProps {
  name: string;
  email: string;
  editOnClick: () => void;
  deleteOnClick: () => void;
}

export const IconEdit = () => {
  return (
    <svg width="15" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1 16.9999H1.50005c-.23869 0-.46761.0949-.636396.2636-.168782.1688-.263604.3978-.263604.6364 0 .2387.094822.4677.263604.6364.168786.1688.397706.2636.636396.2636H14.1c.2387 0 .4677-.0948.6364-.2636.1688-.1687.2636-.3977.2636-.6364 0-.2386-.0948-.4676-.2636-.6364-.1687-.1687-.3977-.2636-.6364-.2636Zm-12.59995-1.8h.081l3.753-.342c.41112-.0409.79563-.222 1.089-.513L14.523 6.24495c.3144-.33213.4843-.77535.4725-1.23252-.0118-.45718-.2043-.89103-.5355-1.20648l-2.466-2.466c-.3218-.30232-.7435-.475782-1.185-.487398-.4414-.011616-.87165.139426-1.20895.424398l-8.1 8.09999c-.29091.29337-.47205.67786-.513 1.08896l-.387 3.753c-.012124.1319.004981.2647.050095.3892.045115.1244.117127.2374.210905.3308.084096.0835.18383.1495.29348.1942.10966.0448.22707.0675.34552.0668ZM10.743 2.59995l2.457 2.457-1.8 1.755-2.41195-2.412 1.75495-1.8ZM2.73305 10.6189l5.067-5.03095 2.42995 2.43-5.03995 5.03995-2.7.252.243-2.691Z"
        fill="#270654"
      />
    </svg>
  );
};

export const IconDelete = () => {
  return (
    <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3 2.60005c0-.47739.18965-.93523.52721-1.27279C6.16478.989691 6.62261.800049 7.1.800049h5.4c.4774 0 .9352.189642 1.2728.527211.3376.33756.5272.7954.5272 1.27279v1.8h3.6c.2387 0 .4676.09482.6364.2636.1688.16879.2636.3977.2636.6364 0 .23869-.0948.46761-.2636.6364-.1688.16878-.3977.2636-.6364.2636h-.9621l-.7803 10.92785c-.0323.4541-.2355.8791-.5687 1.1894s-.7715.4828-1.2268.4828H5.237c-.45527 0-.89364-.1725-1.2268-.4828-.33316-.3103-.53637-.7353-.5687-1.1894L2.663 6.20005H1.7c-.23869 0-.46761-.09482-.63639-.2636-.168786-.16879-.263607-.39771-.263607-.6364 0-.2387.094821-.46761.263607-.6364.16878-.16878.3977-.2636.63639-.2636h3.6v-1.8Zm1.8 1.8h5.4v-1.8H7.1v1.8Zm-2.6334 1.8L5.2379 17h9.1251l.7713-10.79995H4.4666ZM8 8.00005c.2387 0 .46762.09482.6364.2636.16878.16879.2636.39771.2636.6364v5.40005c0 .2386-.09482.4676-.2636.6363-.16878.1688-.3977.2637-.6364.2637-.23869 0-.46761-.0949-.63639-.2637-.16878-.1687-.26361-.3977-.26361-.6363V8.90005c0-.23869.09483-.46761.26361-.6364.16878-.16878.3977-.2636.63639-.2636Zm3.6 0c.2387 0 .4676.09482.6364.2636.1688.16879.2636.39771.2636.6364v5.40005c0 .2386-.0948.4676-.2636.6363-.1688.1688-.3977.2637-.6364.2637-.2387 0-.4676-.0949-.6364-.2637-.1688-.1687-.2636-.3977-.2636-.6363V8.90005c0-.23869.0948-.46761.2636-.6364.1688-.16878.3977-.2636.6364-.2636Z"
        fill="#EB5757"
      />
    </svg>
  );
};

const ContactCardContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.bg};
  border-radius: 3px;
`;

const ButtonCardContainer = styled.div`
  display: flex;
  border: 1px solid transparent;
`;

const Name = styled(PillText)<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  padding: 0 18px;
  background-color: ${(props) => props.theme.colors.bg};
  flex: 2 0 80px;
`;

const Email = styled(PillText)<{ theme: GardThemeType }>`
  display: flex;
  align-items: center;
  padding: 0 28px;
  flex: 3 0 160px;
`;

const ButtonCard = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 0;
  cursor: pointer;
  flex: 1 0;
`;

const Edit = styled(ButtonCard)<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.secondaryLight};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const Delete = styled(ButtonCard)<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.dangerLight};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export function ContactCard(
  props: ContactCardProps & React.HTMLAttributes<HTMLDivElement>
) {
  return (
    <ContactCardContainer className={props.className}>
      <Name>{props.name}</Name>
      <Email>{props.email}</Email>
      <ButtonCardContainer>
        <Edit onClick={props.editOnClick}>
          <IconEdit />
        </Edit>
        <Delete onClick={props.deleteOnClick}>
          <IconDelete />
        </Delete>
      </ButtonCardContainer>
    </ContactCardContainer>
  );
}

export default ContactCard;
