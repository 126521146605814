import styled from 'styled-components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ReactElement, useState } from 'react';

/* eslint-disable-next-line */
export interface ModalProps {
  triggerElement?: JSX.Element;
  title?: string | JSX.Element;
  subtitle?: string;
  addOnClick?: () => void;
  children(options: { close: () => void }): ReactElement | undefined;
  customWidth?: string;
  contentMarginXs?: boolean;
  controlOpen?: boolean;
  toggleOpen?: (state: boolean) => unknown;
  bigTitle?: boolean;
}

const Overlay = styled(DialogPrimitive.Overlay)<{ theme: GardThemeType }>`
  z-index: 10;
  background-color: ${(props) => props.theme.colors.secondary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
`;

const DialogContent = styled(DialogPrimitive.Content)<{
  theme: GardThemeType;
  custom_width?: string;
}>`
  z-index: 11;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px 68px;
  width: ${(props) => (props.custom_width ? props.custom_width : '750px')};
  max-width: calc(100% - 60px);
  opacity: 1;
  max-height: calc(100vh - 60px);
  overflow: auto;

  :focus {
    outline: 'none';
  }
`;

const DialogTitle = styled(DialogPrimitive.Title)<{
  theme: GardThemeType;
  big_title?: boolean;
}>`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) =>
    props.big_title ? props.theme.fontSizes.h3 : props.theme.fontSizes.h4};
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.36;
  display: flex;
  align-items: center;

  > svg {
    margin-left: 1rem;
  }
`;

const DialogSubtitle = styled(DialogPrimitive.Description)`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.text};
  line-height: 1.5;
`;

const DialogChildren = styled.div<{
  theme: GardThemeType;
  content_margin_xs?: boolean;
}>`
  margin-top: ${(props) =>
    props.content_margin_xs ? props.theme.spacing.s : props.theme.spacing.m};
`;

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;

export function Modal(props: ModalProps) {
  const [open, setOpen] = useState(false);

  const externalOpenCloseControl =
    typeof props.controlOpen === 'boolean' && props.toggleOpen;
  const emitOpenClose = externalOpenCloseControl ? props.toggleOpen : setOpen;

  return (
    <Dialog
      open={externalOpenCloseControl ? props.controlOpen : open}
      onOpenChange={emitOpenClose}
    >
      <Overlay />
      {!externalOpenCloseControl && (
        <DialogTrigger asChild>{props.triggerElement}</DialogTrigger>
      )}
      <DialogContent custom_width={props.customWidth}>
        <DialogTitle big_title={props.bigTitle}>{props.title}</DialogTitle>
        {props.subtitle ? (
          <DialogSubtitle>{props.subtitle}</DialogSubtitle>
        ) : null}
        <DialogChildren content_margin_xs={props.contentMarginXs}>
          {props.children({
            close: () => (emitOpenClose ? emitOpenClose(false) : null),
          })}
        </DialogChildren>
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
