import { IsBoolean, IsOptional, IsString, Length } from 'class-validator';

export class NewSubsectionDto {
  @IsString()
  @Length(3, 50, {
    message: 'Subsection name length should be between 5 and 50 characters',
  })
  name: string;

  @IsOptional()
  @IsBoolean()
  is_only_rate?: boolean;
}
