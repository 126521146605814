import {
  SelectFormInput,
  SelectProps,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { AviableOptions, useOptions } from '../../data-access';
import { orderOptions } from './options-select-utils';

export interface OptionsSelectProps {
  label: string;
  name: string;
  option: AviableOptions;
  placeholder?: string;
  required?: boolean;
  type?: SelectProps<string>['type'];
  order?: string;
}

export const OptionsSelect = (props: OptionsSelectProps) => {
  const { data, errorMessage } = useOptions(props.option);

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  const orderedData = data
    ? props.order
      ? orderOptions(props.order, data)
      : data
    : [];

  return (
    <div>
      <SelectFormInput
        label={props.label}
        options={orderedData || []}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type || 'single-selection'}
      />
    </div>
  );
};

export default OptionsSelect;
