import {
  SelectFormInput,
  useSyncAlertControllerWithState,
} from '@observatory/front-end/core-ui';
import { useOptions } from '../../data-access';

export interface CurrencySelectProps {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
}

export const CurrencySelect = (props: CurrencySelectProps) => {
  const { data, errorMessage } = useOptions('currency');

  useSyncAlertControllerWithState(
    errorMessage
      ? {
          label: errorMessage,
          status: 'danger',
        }
      : undefined,
    [errorMessage]
  );

  return (
    <div>
      <SelectFormInput
        label={props.label}
        options={data || []}
        name={props.name}
        type="single-selection"
        placeholder={props.placeholder}
        required={props.required}
      />
    </div>
  );
};

export default CurrencySelect;
