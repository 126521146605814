import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import LogoPng from './Logo.png';

export interface LogoProps {
  size?: number;
}

const LogoContainer = styled.div<{ theme: GardThemeType; size?: number }>`
  width: 100%;
  max-width: ${(props) => (props.size ? props.size + 'px' : '390px')};
`;

const LogoSource = styled.img<{ theme: GardThemeType }>`
  width: 100%;
`;

export function Logo(props: LogoProps) {
  return (
    <LogoContainer size={props.size}>
      <LogoSource src={LogoPng} />
    </LogoContainer>
  );
}

export default Logo;
