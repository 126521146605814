import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  CollectorDto,
  UpdateCollectorDto,
} from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetRateCollection = (collector_id: string) => {
  const fetcher = useFetcher();
  const { error: actionError, handle } = useHandleRespError();

  const {
    data,
    error: getError,
    mutate,
  } = useSWR<CollectorDto>(`/api/rate-collection/${collector_id}`, fetcher);

  const error = !!actionError || !!getError;

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    errorMessage: actionError,
    async update(data: Omit<UpdateCollectorDto, 'collector_id'>) {
      const result = await fetcher('/api/rate-collection/update', {
        method: 'PUT',
        body: {
          collector_id,
          ...data,
        },
      });
      const collector = handle<CollectorDto>(result);
      await mutate();
      return collector;
    },
    mutate,
  };
};
