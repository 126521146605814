import { joiResolver } from '@hookform/resolvers/joi';
import { useThrottle } from '@observatory/front-end/admin-check';
import {
  ButtonIcon,
  ButtonOutlined,
  DialogButtons,
  Input,
  InputFormInput,
  LabelText,
  ParagraphText,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import { BooleanCheckbox } from '@observatory/front-end/form-inputs';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { UpdateSubSectionDto } from '@observatory/shared/gard/dtos';
import { DialogClose } from '@radix-ui/react-dialog';
import Joi, { func } from 'joi';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface AddSubSectionModalFormProps {
  onSubmit: (values: AddContactFormValues) => Promise<unknown>;
  onDeleteSubSection: (subSectionId: string) => Promise<unknown>;
  modalClose: () => void;
  subSections: Array<{ label: string; id: string; disabled: boolean }>;
  onChangeEditSubSection: (value: UpdateSubSectionDto) => Promise<unknown>;
}

const AddSubSectionModalFormContainer = styled.div`
  display: flex;
`;

const SubtitleInputContainer = styled.div`
  flex: 1 1 50%; ;
`;

const Subtitle = styled(ParagraphText)<{ theme: GardThemeType }>`
  display: block;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: ${(props) => props.theme.spacing.m};
`;

const SubSectionsContainer = styled.div<{ theme: GardThemeType }>`
  flex: 1 1 40%;
  padding-left: ${(props) => props.theme.spacing.l};
`;

const SubSectionsTitle = styled(LabelText)<{ theme: GardThemeType }>`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: ${(props) => props.theme.spacing.s};
`;

const SubItem = styled(Input)<{ theme: GardThemeType }>`
  margin-bottom: ${(props) => props.theme.spacing.xxs};
  margin-right: ${(props) => props.theme.spacing.s};
`;

const SubSectionItemContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export type AddContactFormValues = {
  subSection: string;
  is_only_rate: boolean;
};

const schema = Joi.object({
  subSection: Joi.string().min(3).required().messages({
    'string.min': 'Sub section name must be longer than 3 character',
    'string.empty': 'Provide a sub section name',
  }),

  is_only_rate: Joi.boolean(),
});

export interface SavedMagicSubItemProps {
  subSection: AddSubSectionModalFormProps['subSections'][0];
  onChangeEditSubSection: AddSubSectionModalFormProps['onChangeEditSubSection'];
  onDeleteSubSection: AddSubSectionModalFormProps['onDeleteSubSection'];
  disabled?: boolean;
}

export function SavedMagicSubItem(props: SavedMagicSubItemProps) {
  const throttle = useThrottle(500);
  const [loading, setLoading] = useState(false);

  return (
    <SubSectionItemContainer>
      <SubItem
        type="text"
        onChange={(e) => {
          setLoading(true);
          throttle((name) => {
            props
              .onChangeEditSubSection({
                sub_section_id: props.subSection.id,
                name,
              })
              .finally(() => {
                setLoading(false);
              });
          })(e);
        }}
        disabled={props.disabled}
        defaultValue={props.subSection.label}
        placeholder="subsection"
        backgroundOnFocus={loading ? 'rgba(189, 158, 36, 0.3)' : undefined}
      />
      <ButtonIcon
        type="delete"
        outlined
        onClick={(ev) => {
          ev.preventDefault();
          props.onDeleteSubSection(props.subSection.id);
        }}
        disabled={props.disabled}
      />
    </SubSectionItemContainer>
  );
}

export function AddSubSectionModalForm(props: AddSubSectionModalFormProps) {
  const formMethods = useForm<AddContactFormValues>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
    defaultValues: {
      is_only_rate: false,
    },
  });

  const { isSubmitSuccessful, isSubmitting } = formMethods.formState;

  // Reset form on submission
  useEffect(() => {
    if (!isSubmitting && isSubmitSuccessful) {
      formMethods.resetField('subSection');
    }
  }, [formMethods, isSubmitSuccessful, formMethods.resetField, isSubmitting]);

  return (
    <AddSubSectionModalFormContainer>
      <SubtitleInputContainer>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
            <Subtitle>Add a new one:</Subtitle>
            <InputFormInput
              name="subSection"
              type="text"
              placeholder="Subsection"
              required
            />
            <BooleanCheckbox
              name="is_only_rate"
              label={`Use template "Only Rates"`}
            />
            <DialogButtons>
              <SubmitButton
                disabled={formMethods.formState.isSubmitting}
                type="submit"
              >
                Save
              </SubmitButton>
              <DialogClose asChild>
                <ButtonOutlined>Close</ButtonOutlined>
              </DialogClose>
            </DialogButtons>
          </form>
        </FormProvider>
      </SubtitleInputContainer>
      <SubSectionsContainer>
        <SubSectionsTitle>Sub-sections</SubSectionsTitle>
        {props.subSections.map((sub) => (
          <SavedMagicSubItem
            key={sub.id}
            onChangeEditSubSection={(values) =>
              props.onChangeEditSubSection(values)
            }
            subSection={sub}
            disabled={sub.disabled}
            onDeleteSubSection={props.onDeleteSubSection}
          />
        ))}
      </SubSectionsContainer>
    </AddSubSectionModalFormContainer>
  );
}

export default AddSubSectionModalForm;
