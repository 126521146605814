import {
  ButtonPrimary,
  DatePickerFormInput,
  InputFormInput,
} from '@observatory/front-end/core-ui';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { RateCalculationQueryDto } from '@observatory/shared/gard/dtos';
import {
  CurrencySelect,
  OptionsCheckboxSelect,
} from '@observatory/front-end/form-inputs';
import { useEffect, useMemo } from 'react';

/* eslint-disable-next-line */
export interface FormAnalysisProps {
  onSubmit: (values: RateCalculationQueryDto) => void | Promise<void>;
}

const FormAnalysisContainer = styled.form`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 0 1 calc(33.33% - 20px);
    width: calc(33.33% - 20px);
    margin-bottom: 15px;

    &:nth-child(2),
    &:nth-child(3n + 2) {
      margin: 0 30px 15px 30px;
    }
  }
`;

const FormAnalysisFooter = styled.div`
  flex: 1 0 100%;
  width: 100%;
  margin: 20px 0 30px 0 !important;

  > button {
    width: 230px;
  }
`;

const schema = Joi.object<RateCalculationQueryDto>({
  date: Joi.date().required(),
  currency: Joi.string().required().messages({
    'string.base': `The field 'Currency' is required`,
  }),
  billableHoursPerYear: Joi.number().required().messages({
    'string.base': `The field 'Billable Hours Per Year' is required`,
  }),
  dataType: Joi.array().optional(),
  clientCategory: Joi.array().optional(),
  agencySize: Joi.array().optional(),
  agencyType: Joi.array().optional(),
  agencyOwnership: Joi.array().optional(),
  agencyLocationCountry: Joi.array().optional(),
  agencyLocationCity: Joi.array().optional(),
});

export function FormAnalysis(props: FormAnalysisProps) {
  const formMethods = useForm<RateCalculationQueryDto>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
  });

  const countries = formMethods.watch('agencyLocationCountry');

  const countriesParams = useMemo(() => {
    if (!countries) {
      return;
    }
    const params = new URLSearchParams();
    for (const county of countries) {
      params.append('countries', county);
    }
    return params;
  }, [countries]);

  useEffect(() => {
    formMethods.setValue('agencyLocationCity', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesParams?.toString()]);

  return (
    <FormProvider {...formMethods}>
      <FormAnalysisContainer
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
      >
        <DatePickerFormInput
          label="Effective date dd/mm/yyyy"
          name="date"
          placeholder="Effective date dd/mm/yyyy"
          required
        />
        <CurrencySelect
          label="Currency"
          name="currency"
          placeholder="Currency"
          required
        />
        <InputFormInput
          label="Billable hours per year"
          name="billableHoursPerYear"
          type="number"
          placeholder="Billable hours per year"
          required
        />
        <OptionsCheckboxSelect
          label="Data type"
          name="dataType"
          option="rate_categories"
          placeholder="Data type"
        />
        <OptionsCheckboxSelect
          label="Client category"
          name="clientCategory"
          option="client-categories"
          placeholder="Client category"
        />
        <OptionsCheckboxSelect
          label="Agency size"
          name="agencySize"
          option="agency-sizes"
          placeholder="Agency size"
        />
        <OptionsCheckboxSelect
          label="Agency type"
          name="agencyType"
          option="agency-types"
          placeholder="Agency type"
        />
        <OptionsCheckboxSelect
          label="Agency ownership"
          name="agencyOwnership"
          option="agency-ownerships"
          placeholder="Agency ownership"
        />
        <OptionsCheckboxSelect
          label="Agency location country"
          name="agencyLocationCountry"
          option="countries"
          placeholder="Agency location country"
        />
        <OptionsCheckboxSelect
          label="Agency location city"
          name="agencyLocationCity"
          option="cities"
          placeholder="Agency location city"
          optionsSearchParams={countriesParams}
        />
        <FormAnalysisFooter>
          <ButtonPrimary
            disabled={formMethods.formState.isSubmitting}
            type="submit"
          >
            SUBMIT FILTERS
          </ButtonPrimary>
        </FormAnalysisFooter>
      </FormAnalysisContainer>
    </FormProvider>
  );
}

export default FormAnalysis;
