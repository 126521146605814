import { GardThemeType } from '@observatory/front-end/gard-theme';
import styled from 'styled-components';

export interface TableAnalysisContainerProps {
  marginBottom?: string;
  marginTop?: string;
}

export const TableAnalysisContainer = styled.div<{
  theme: GardThemeType;
  marginTop?: string;
}>`
  background: #fff;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '18px')};
`;

export default TableAnalysisContainer;
