import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  ContactDto,
  NewContactDto,
  UpdateContactDto,
} from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetAgencyContactsList = (agency_id: string) => {
  const fetcher = useFetcher();
  const { error: actionError, handle, reset } = useHandleRespError();

  const { data, error, mutate } = useSWR<Array<ContactDto>>(
    `/api/contact/agency/${agency_id}`,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    errorMessage: actionError,
    reset,
    async createAgencyContact(data: NewContactDto) {
      const result = await fetcher(`/api/contact/create/agency/${agency_id}`, {
        method: 'POST',
        body: data,
      });
      const collector = handle<ContactDto>(result);
      await mutate();
      return collector;
    },
    async updateAgencyContact(data: UpdateContactDto) {
      const result = await fetcher(`/api/contact/update`, {
        method: 'PUT',
        body: data,
      });
      const collector = handle<ContactDto>(result);
      await mutate();
      return collector;
    },
    async deleteAgencyContact(contact_id: string) {
      const result = await fetcher(
        `/api/contact/delete/${contact_id}/agency/${agency_id}`,
        {
          method: 'DELETE',
          body: {},
        }
      );
      handle(result);
      await mutate();
    },
    mutate,
  };
};
