import { Expose, Type } from 'class-transformer';
import { CurrencyType } from './currency.type';
import { RateCalculationQueryDto } from './query.dto';

@Expose()
export class InfoCalclsResultQueryDto {
  effective_date: Date;
  currency: CurrencyType;
  h_per_year: number;
}

@Expose()
export class ResultQueryDataInfoDto {
  @Type(() => InfoCalclsResultQueryDto)
  info_calcs: InfoCalclsResultQueryDto;

  @Type(() => RateCalculationQueryDto)
  query: RateCalculationQueryDto;
}

export class ResultQueryDto {
  @Expose()
  @Type(() => ResultQueryDataInfoDto)
  query: ResultQueryDataInfoDto;

  @Expose()
  date_query: Date;
}
