import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Alert } from '../alert/alert';

export interface AlertControllerProps {
  message: {
    label: string;
    status: 'danger' | 'success' | 'warning';
  };
  onClose: () => unknown;
}

const AlertContext = React.createContext<
  | {
      pushMessage: (msg: AlertControllerProps['message']) => number;
      popMessage: (id: number) => void;
      alertsStates: Array<AlertControllerProps['message'] & { id: number }>;
    }
  | undefined
>(undefined);

export function useAlertController() {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('Should use useAlertController inside of AlertProvider');
  }

  return {
    showMessage: context.pushMessage,
    showMessageWithTimeout: (
      message: AlertControllerProps['message'],
      timeout = 2000,
      cb?: () => void
    ) => {
      const id = context.pushMessage(message);
      setTimeout(() => {
        context.popMessage(id);
        cb?.();
      }, timeout);
    },
  };
}

export function useSyncAlertControllerWithState(
  msg?: AlertControllerProps['message'],
  deps?: Array<unknown>
) {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('Should use useAlertController inside of AlertProvider');
  }

  useEffect(() => {
    if (msg) {
      context.pushMessage(msg);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

const Container = styled.div`
  position: fixed;
  bottom: ${(props) => props.theme.spacing.m};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.m};
`;

export function AlertContainer() {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('Should use AlertContainer inside of AlertProvider');
  }

  return (
    <Container>
      {context.alertsStates.map((msg) => (
        <Alert
          key={msg.id}
          label={msg.label}
          status={msg.status}
          onClose={() => {
            context.popMessage(msg.id);
          }}
        />
      ))}
    </Container>
  );
}

export function AlertProvider(props: React.PropsWithChildren) {
  const [alertsStates, setAlertsStates] = useState<
    Array<AlertControllerProps['message'] & { id: number }>
  >([]);

  function pushMessage(message: AlertControllerProps['message']) {
    const id = Math.floor(Math.random() * 10000);
    setAlertsStates((old) => [
      ...old,
      { id, label: message.label, status: message.status },
    ]);
    return id;
  }

  function popMessage(id: number) {
    setAlertsStates((old) => old.filter((msg) => msg.id !== id));
  }

  return (
    <AlertContext.Provider
      value={{
        pushMessage,
        popMessage,
        alertsStates,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
}

export default AlertContainer;
