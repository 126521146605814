import { joiResolver } from '@hookform/resolvers/joi';
import {
  ButtonOutlined,
  DialogButtons,
  InputFormInput,
  SubmitButton,
} from '@observatory/front-end/core-ui';
import {
  CurrencySelect,
  OptionsSelect,
} from '@observatory/front-end/form-inputs';
import { CurrencyType } from '@observatory/shared/gard/dtos';
import { DialogClose } from '@radix-ui/react-dialog';
import Joi from 'joi';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface EditDetailsModalFormProps {
  onSubmit: (values: EditDetailsFormValues) => void | Promise<void>;
  modalClose: () => void;
  defaultValues?: EditDetailsFormValues;
}

const EditDetailsModalFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 0 1 calc(33.33% - 20px);
    width: calc(33.33% - 20px);
    margin-bottom: 15px;
    display: flex;
    align-items: flex-end;

    &:nth-child(2),
    &:nth-child(3n + 2) {
      margin: 0 30px 15px 30px;
    }
  }
`;

export type EditDetailsFormValues = {
  clientName?: string;
  clientBrand?: string;
  currentCurrency: CurrencyType;
  country: string;
  city?: string;
  numberEmployes: string;
  billableHoursYear?: number;
  ownership: string;
  overhead?: number;
  profitMargin?: number;
  agency_type: string;
  dataType?: string;
};

const schema = Joi.object({
  clientName: Joi.string().optional(),
  clientBrand: Joi.string().optional(),
  currentCurrency: Joi.string().required().messages({
    'string.base': `The field 'Currency' is required`,
  }),
  country: Joi.string().required().messages({
    'string.base': `The field 'Country' is required`,
  }),
  city: Joi.string().optional(),
  numberEmployes: Joi.string().required().messages({
    'string.base': `The field 'Number of employees' is required`,
  }),
  billableHoursYear: Joi.number().optional(),
  ownership: Joi.string().required().messages({
    'string.base': `The field 'Ownership' is required`,
  }),
  overhead: Joi.number().optional(),
  profitMargin: Joi.number().optional(),
  dataType: [Joi.string().optional(), Joi.allow(null)],
  agency_type: Joi.string().required().messages({
    'string.base': `The field 'Agency type' is required`,
  }),
});

export function EditDetailsModalForm(props: EditDetailsModalFormProps) {
  const formMethods = useForm<EditDetailsFormValues>({
    //TODO JM: Remove Joi and use class-validator resolver
    resolver: joiResolver(schema),
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <EditDetailsModalFormContainer>
          <InputFormInput
            label="Client Organisation Name"
            name="clientName"
            type="text"
            placeholder="Client Organisation Name"
          />
          <InputFormInput
            label="Client Brand or Project Name"
            name="clientBrand"
            type="text"
            placeholder="Client Brand or Project Name"
          />
          <CurrencySelect
            label="Currency"
            name="currentCurrency"
            placeholder="Currency"
            required
          />
          <OptionsSelect
            label="Country"
            name="country"
            type="single-selection"
            placeholder="Country"
            option="countries"
            required
          />
          <InputFormInput
            label="City"
            name="city"
            placeholder="City"
            type="text"
          />
          <OptionsSelect
            label="Number of employees"
            name="numberEmployes"
            option="agency-sizes"
            placeholder="Number of employees"
            order="agency-sizes"
            required
          />
          <InputFormInput
            label="Number of billable hours/year"
            name="billableHoursYear"
            type="number"
            placeholder="Number of billable hours/year"
          />
          <OptionsSelect
            label="Ownership"
            name="ownership"
            option="agency-ownerships"
            placeholder="Ownership"
            required
          />
          <InputFormInput
            label="Overhead (expressed as a % uplift on fixed costs, e.g. 100%)"
            name="overhead"
            type="number"
            placeholder="Overhead"
            isPercentage
          />
          <InputFormInput
            label="Profit Margin (expressed as a % of the Gross rate)"
            name="profitMargin"
            type="number"
            placeholder="Profit Margin"
            isPercentage
          />
          <OptionsSelect
            label="Agency type"
            name="agency_type"
            option="agency-types"
            placeholder="Agency type"
            required
          />
          <OptionsSelect
            label="Data type"
            name="dataType"
            option="rate_categories"
            placeholder="Data type"
          />
        </EditDetailsModalFormContainer>
        <DialogButtons>
          <SubmitButton
            disabled={formMethods.formState.isSubmitting}
            type="submit"
          >
            Save
          </SubmitButton>
          <DialogClose asChild>
            <ButtonOutlined>Close</ButtonOutlined>
          </DialogClose>
        </DialogButtons>
      </form>
    </FormProvider>
  );
}

export default EditDetailsModalForm;
