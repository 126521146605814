import {
  BreadcrumbItemProps,
  NavbarContainer,
  PageWrapper,
} from '@observatory/front-end/core-ui';
import { Routes, Route } from 'react-router-dom';
import TableWithFilters from '../components/table-with-filters/table-with-filters';
import { ConnectedNavbar } from '@observatory/front-end/user-features';

/* eslint-disable-next-line */
export interface AdminCheckProps {}

export function AdminCheck(props: AdminCheckProps) {
  const breadcrumbs: Array<BreadcrumbItemProps> = [
    {
      label: 'GARD',
      link: '/',
      active: false,
    },
    {
      label: 'Admin check',
      link: '/admin_check',
      active: true,
    },
  ];

  const entriesForPageOptions: Array<{ label: string; value: number }> = [
    {
      label: 'Show 10 entries',
      value: 10,
    },
    {
      label: 'Show 25 entries',
      value: 25,
    },
    {
      label: 'Show 50 entries',
      value: 50,
    },
    {
      label: 'Show 100 entries',
      value: 100,
    },
  ];

  return (
    <PageWrapper title="Admin check" breadcrumbs={breadcrumbs} borderBottom>
      <NavbarContainer>
        <ConnectedNavbar />
      </NavbarContainer>
      <TableWithFilters
        entriesForPageSelected={10}
        entriesForPageOptions={entriesForPageOptions}
      />
    </PageWrapper>
  );
}

export const AdminCheckRoutes = () => (
  <Routes>
    <Route path="" element={<AdminCheck />} />
  </Routes>
);

export default AdminCheck;
