import { GardTheme } from '@observatory/front-end/gard-theme';
import TableAnalysisContainer from '../table-analysis-container/table-analysis-container';
import TableTitle from '../table-title/table-title';

export interface TableQueryProps {
  color?: string;
  background?: string;
  title: string;
  weight?: string;
  marginTop?: string;
  id?: string;
  query?: unknown;
}

export function TableQuery(props: TableQueryProps, marginTop?: string) {
  return (
    <TableAnalysisContainer marginTop={props.marginTop} id={props.id}>
      <TableTitle
        title={props.title}
        weight={props.weight}
        background={GardTheme.colors.secondary}
      ></TableTitle>
      <div style={{ padding: '0 20px 20px' }}>
        <pre>{JSON.stringify(props.query || {}, undefined, 2)}</pre>
      </div>
    </TableAnalysisContainer>
  );
}

export default TableQuery;
