import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { motion } from 'framer-motion';

/* eslint-disable-next-line */
export interface AlertProps {
  label: string;
  status: 'danger' | 'success' | 'warning';
  onClose?(): void;
}

const StyledAlert = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 810px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  background-color: ${(props: {
    theme: GardThemeType;
    status: AlertProps['status'];
  }) =>
    `${props.status === 'success' ? props.theme.colors.successLight : ''} 
    ${props.status === 'danger' ? props.theme.colors.dangerLight : ''}
    ${props.status === 'warning' ? props.theme.colors.warningLight : ''}`};
  border: ${(props) =>
    `${
      props.status === 'success'
        ? `1px solid ${props.theme.colors.success}`
        : ''
    } 
    ${props.status === 'danger' ? `1px solid ${props.theme.colors.danger}` : ''}
    ${
      props.status === 'warning'
        ? `1px solid ${props.theme.colors.warning}`
        : ''
    }`};
  padding-top: ${(props) => props.theme.spacing.xs};
  padding-bottom: ${(props) => props.theme.spacing.xs};
  width: 100%;
  height: 44px;
`;

const StyledContent = styled.div<{ theme: GardThemeType }>`
  display: flex;
  justify-content: align-start;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing.s};
`;

const StyledLabel = styled.label<{ theme: GardThemeType }>`
  font-size: ${(props) => props.theme.fontSizes.pill};
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.secondary};
`;

const StyledButton = styled.button<{ theme: GardThemeType }>`
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CloseIcon = styled.svg<{ theme: GardThemeType }>`
  width: 24px;
  height: 24px;
  fill: none;
  stroke: ${(props) => props.theme.colors.secondary};
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const WarningIcon = styled.svg<{ theme: GardThemeType }>`
  width: 16px;
  height: 16px;
  fill: none;
  stroke: ${(props) => props.theme.colors.secondary};
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
  margin-right: ${(props) => props.theme.spacing.xs};
`;

export function Alert(props: AlertProps) {
  return (
    <StyledAlert
      status={props.status}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <StyledContent>
        <WarningIcon viewBox="0 0 24 24">
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </WarningIcon>
        <StyledLabel data-testid={`alert-${props.status}-label`}>
          {props.label}
        </StyledLabel>
      </StyledContent>
      <StyledButton>
        <CloseIcon viewBox="0 0 24 24" onClick={props.onClose}>
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </CloseIcon>
      </StyledButton>
    </StyledAlert>
  );
}

export default Alert;
