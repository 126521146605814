import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

/* eslint-disable-next-line */
export interface ParagraphProps {
  text: string;
}

const StyledParagraph = styled.p<{ theme: GardThemeType }>`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes.paragraph};
  font-family: ${(props) => props.theme.fonts.regular};
  margin: 0;
`;

export function Paragraph(props: ParagraphProps) {
  return <StyledParagraph>{props.text}</StyledParagraph>;
}

export default Paragraph;
