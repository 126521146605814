import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import { UserDto } from '@observatory/shared/gard/dtos';
import useSWR from 'swr';

export const useGetUserInfo = () => {
  const fetcher = useFetcher();
  const { error: actionError, handle } = useHandleRespError(false);

  const { data, error, mutate } = useSWR<UserDto>('/api/user/info', fetcher);

  return {
    data: handle(data),
    isLoading: !error && !data,
    isError: !!(error || actionError),
    errorMessage: actionError,
    mutate,
  };
};
