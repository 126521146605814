import {
  IsEnum,
  IsOptional,
  Matches,
  MaxLength,
  MinLength,
} from 'class-validator';
import { UserRolesDto } from './user-roles.dto';

export class UpdateUserDto {
  @MinLength(5, {
    message: 'Username is too short',
  })
  @MaxLength(20, {
    message: 'Username is too long',
  })
  @IsOptional()
  username?: string;

  @MinLength(8, {
    message: 'Password is too short',
  })
  @MaxLength(50, {
    message: 'Password is too long',
  })
  @Matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
    {
      message: 'Is not a valid password, it is too easy to guess',
    }
  )
  @IsOptional()
  password?: string;

  @IsEnum(UserRolesDto)
  @IsOptional()
  role?: UserRolesDto;
}
