/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Remove the lint exception
import {
  ScaleLinear,
  format,
  hierarchy,
  interpolate,
  PartitionLayout,
  Path,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  ScalePower,
  scaleSqrt,
  schemeSet3,
  select,
  partition as d3Partition,
  arc as d3Arc,
  path as d3Path,
} from 'd3';
import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  useRef,
} from 'react';
import { D3Graph } from '../../components';
import './sunburst-chart.scss';

export interface ZoomableSunBurstData {
  name: string;
  children?: Array<ZoomableSunBurstData>;
  size?: number;
}

export interface SunburstChartProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  refSvgElement?: MutableRefObject<SVGElement | null>;
  data: ZoomableSunBurstData;
  width?: string;
  height?: string;
}

function makeGraph(uniqueTokenToSelectOnlyInternalClasses: string) {
  return function (data: ZoomableSunBurstData, container: HTMLDivElement) {
    const rect = container.getBoundingClientRect();
    const width: number = rect.width;
    const height: number = rect.height;
    const maxRadius: number = Math.min(width, height) / 2 - 5;

    const formatNumber: (n: number | { valueOf(): number }) => string =
      format(',d');

    const x: ScaleLinear<number, number> = scaleLinear()
      .range([0, 2 * Math.PI])
      .clamp(true);
    const y: ScalePower<number, number> = scaleSqrt().range([
      maxRadius * 0.1,
      maxRadius,
    ]);

    const color: ScaleOrdinal<string, string> = scaleOrdinal(schemeSet3);

    const partition: PartitionLayout<unknown> = d3Partition();

    const arc: any = d3Arc()
      .startAngle((d: any) => x(d.x0) || 0)
      .endAngle((d: any) => x(d.x1) || 0)
      .innerRadius((d: any) => Math.max(0, y(d.y0) || 0))
      .outerRadius((d: any) => Math.max(0, y(d.y1) || 0));

    const middleArcLine = (d: any) => {
      const halfPi: number = Math.PI / 2;
      const angles: number[] = [
        (x(d.x0) || 0) - halfPi,
        (x(d.x1) || 0) - halfPi,
      ];
      const r: number = Math.max(0, ((y(d.y0) || 0) + (y(d.y1) || 0)) / 2);

      const middleAngle: number = (angles[1] + angles[0]) / 2;
      const invertDirection: boolean = middleAngle > 0 && middleAngle < Math.PI;
      if (invertDirection) {
        angles.reverse();
      }

      const path: Path = d3Path();
      path.arc(0, 0, r, angles[0], angles[1], invertDirection);
      return path.toString();
    };

    const textFits: any = (d: any) => {
      const CHAR_SPACE = 6;
      const deltaAngle: number = (x(d.x1) || 0) - (x(d.x0) || 0);
      const r: number = Math.max(0, ((y(d.y0) || 0) + (y(d.y1) || 0)) / 2);
      const perimeter: number = r * deltaAngle;

      return d.data.name.length * CHAR_SPACE < perimeter;
    };

    // Avoid recreating multiple instance when rerender append
    select(container).selectChild('svg').remove();

    const svg = select(container)
      .append('svg')
      .style('width', width ? width : '100vw')
      .style('height', height ? height : '100vh')
      .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
      // Make extracting styles easy
      .attr('class', 'svgContainerStyle')
      .on('click', () => focusOn());

    const root = hierarchy(data);
    root.sum((d: any) => d.size);

    const slice = svg
      .selectAll(
        `g[data-target=slice-${uniqueTokenToSelectOnlyInternalClasses}]`
      )
      .data(partition(root).descendants());

    slice.exit().remove();

    const newSlice = slice
      .enter()
      .append('g')
      .attr('class', 'slice')
      .attr('data-target', `slice-${uniqueTokenToSelectOnlyInternalClasses}`)
      .on('click', (event, d: any) => {
        event.stopPropagation();
        focusOn(d);
      });

    newSlice
      .append('title')
      .text((d: any) => d.data.name + '\n' + formatNumber(d.value));

    newSlice
      .append('path')
      .attr('class', 'main-arc')
      .attr('data-target', `main-arc-${uniqueTokenToSelectOnlyInternalClasses}`)
      .style('fill', (d: any) => color((d.children ? d : d.parent).data.name))
      .attr('d', arc);

    newSlice
      .append('path')
      .attr('class', 'hidden-arc')
      .attr(
        'data-target',
        `hidden-arc-${uniqueTokenToSelectOnlyInternalClasses}`
      )
      .attr(
        'id',
        (_, i) => `hiddenArc${i}-${uniqueTokenToSelectOnlyInternalClasses}`
      )
      .attr('d', middleArcLine);

    const text = newSlice
      .append('text')
      .attr(
        'data-target',
        `text-slice-${uniqueTokenToSelectOnlyInternalClasses}`
      )
      .attr('display', (d: any) => (textFits(d) ? null : 'none'));

    text
      .append('textPath')
      .attr('startOffset', '50%')
      .attr(
        'xlink:href',
        (_, i) => `#hiddenArc${i}-${uniqueTokenToSelectOnlyInternalClasses}`
      )
      .text((d: any) => d.data.name + ' - ' + formatNumber(d.value))
      .style('fill', 'none')
      .style('stroke', '#fff')
      .style('stroke-width', 2)
      .style('stroke-linejoin', 'round');

    text
      .append('textPath')
      .attr('startOffset', '50%')
      .attr(
        'xlink:href',
        (_, i) => `#hiddenArc${i}-${uniqueTokenToSelectOnlyInternalClasses}`
      )
      .text((d: any) => d.data.name + ' - ' + formatNumber(d.value));

    function focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
      const transition = svg
        .transition()
        .duration(750)
        .tween('scale', () => {
          const xd = interpolate(x.domain(), [d.x0, d.x1]),
            yd = interpolate(y.domain(), [d.y0, 1]);
          return (t: any) => {
            x.domain(xd(t));
            y.domain(yd(t));
          };
        });

      transition
        .selectAll(
          `path[data-target=main-arc-${uniqueTokenToSelectOnlyInternalClasses}]`
        )
        .attrTween('d', (d: any) => () => arc(d));

      transition
        .selectAll(
          `path[data-target=hidden-arc-${uniqueTokenToSelectOnlyInternalClasses}]`
        )
        .attrTween('d', (d: any) => () => middleArcLine(d));

      transition
        .selectAll(
          `text[data-target=text-slice-${uniqueTokenToSelectOnlyInternalClasses}]`
        )
        .attrTween(
          'display',
          (d: any) => (): any => textFits(d) ? null : 'none'
        );

      moveStackToFront(d);

      function moveStackToFront(elD: any) {
        svg
          .selectAll(
            `[data-target=slice-${uniqueTokenToSelectOnlyInternalClasses}]`
          )
          .filter((d) => d === elD)
          .each(function (d: any) {
            d?.parentNode?.appendChild(this);
            if (d.parent) {
              moveStackToFront(d.parent);
            }
          });
      }
    }

    return svg.node() as SVGElement;
  };
}
export function SunburstChart({ data, ...containerProps }: SunburstChartProps) {
  const uniqueToken = useRef(`${Math.floor(Math.random() * 10000)}`);

  return (
    <D3Graph
      data-test-id="sunburst-graph"
      data={data}
      makeGraph={makeGraph(uniqueToken.current)}
      {...containerProps}
    />
  );
}

export default SunburstChart;
