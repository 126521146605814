import { Type } from 'class-transformer';
import {
  IsArray,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Min,
  ValidateNested,
} from 'class-validator';
import { CurrencyType } from '../rate-calculation';
import { RateDto } from './new-collector-rate.dto';

export class UpdateCollectorRateDto {
  @IsString()
  collector_rate_id: string;

  @IsOptional()
  client_name?: string;

  @IsOptional()
  client_brand?: string;

  @IsOptional()
  @IsEnum(CurrencyType, { message: 'Currency should be a valid currency code' })
  currency?: CurrencyType;

  @IsOptional()
  agency_country?: string;

  @IsOptional()
  agency_city?: string;

  @IsOptional()
  agency_size?: string;

  @IsOptional()
  agency_type?: string;

  @IsOptional()
  @IsNumber()
  @Min(1, { message: 'Rate should be grater than zero' })
  billable_hours?: number;

  @IsOptional()
  ownership?: string;

  @IsOptional()
  @IsNumber()
  overhead?: number;

  @IsOptional()
  @IsNumber()
  margin?: number;

  @IsOptional()
  @Type(() => RateDto)
  @ValidateNested({ each: true })
  @IsArray()
  rates: Array<RateDto>;

  @IsOptional()
  @IsString()
  rate_category?: string;
}
