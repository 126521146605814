import { GardThemeType } from '@observatory/front-end/gard-theme';
import { ContactDto } from '@observatory/shared/gard/dtos';
import { ForwardedRef, forwardRef, Fragment, useState } from 'react';
import styled from 'styled-components';
import ButtonIcon from '../button-icon/button-icon';
import ContactCard from '../contact-card/contact-card';
import Sub, { SubProps } from '../sub/sub';

/* eslint-disable-next-line */
export interface AgencyProps {
  id: string;
  name: string;
  contacts: Array<ContactDto>;
  subs: Array<SubProps>;
  deleteOnClick: (contact: ContactDto) => void;
  historicizeModal?: (subSection: SubProps) => JSX.Element;
  removeSubSectionSubmission?: (subSection: SubProps) => JSX.Element;
  dashboardOnClick?: () => void;
  dashboardHref?: string;
  dashboardState?: unknown;
  addModal?: JSX.Element;
  editModal?: (
    open: boolean,
    close: () => void,
    contact: ContactDto
  ) => JSX.Element;
  editAgency?: JSX.Element;
  deleteAgency?: JSX.Element;
}

export const ImportedIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    {...props}
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.78 0H2.113c-1.133 0-2 .867-2 2v16c0 1.133.867 2 2 2H16.78c1.133 0 2-.867 2-2V2c0-1.133-.867-2-2-2zM2.113 1.333H16.78c.4 0 .667.267.667.667v10.533a.77.77 0 0 1-.8.8H13.18c-.267 0-.467.134-.533.4-.534 1.6-2.2 2.534-3.867 2.2-1.2-.266-2.133-1.066-2.467-2.2-.066-.266-.333-.4-.533-.4H2.313c-.533 0-.866-.333-.866-.8V2c0-.4.266-.667.666-.667z"
      fill="#41D3AC"
    />
    <path
      d="M12.943 5c-1.29 1.293-2.581 2.58-3.873 3.873L7.172 7.29 6.113 8.56l2.479 2.066.581.482.534-.534c1.467-1.47 2.936-2.933 4.406-4.407L12.943 5z"
      fill="#41D3AC"
    />
  </svg>
);

export const ImportIcon = (
  props: { disabled?: boolean } & React.HTMLAttributes<SVGElement>
) => (
  <svg
    {...props}
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.78 0H2.113c-1.133 0-2 .867-2 2v16c0 1.133.867 2 2 2H16.78c1.133 0 2-.867 2-2V2c0-1.133-.867-2-2-2zM2.113 1.333H16.78c.4 0 .667.267.667.667v10.533a.77.77 0 0 1-.8.8H13.18c-.267 0-.467.134-.533.4-.534 1.6-2.2 2.534-3.867 2.2-1.2-.266-2.133-1.066-2.467-2.2-.066-.266-.333-.4-.533-.4H2.313c-.533 0-.866-.333-.866-.8V2c0-.4.266-.667.666-.667z"
      fill="#270654"
    />
    <path
      d="M5.36 7.85h1.466c.133 0 .266.133.266.267v2.866c0 .134.134.267.267.267h4.2a.287.287 0 0 0 .267-.267V8.05c0-.133.133-.267.266-.267h1.4c.2 0 .334-.266.2-.4l-4.066-4c-.067-.066-.267-.066-.334 0l-4.066 4c-.2.2-.067.467.133.467z"
      fill="#270654"
    />
  </svg>
);

const HistoricizeButtonWrapper = styled.button<{
  theme: GardThemeType;
  isHistoric?: boolean;
  disabled?: boolean;
}>`
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 1rem;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  opacity: ${(props) => (props.disabled && !props.isHistoric ? 0.3 : 1)};
  padding: 9px 9px 6px 9px;
  background-color: ${(props) =>
    props.isHistoric
      ? props.theme.colors.successLight
      : props.theme.colors.secondaryLight};
  border-radius: 5px;

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) =>
      props.disabled ? (!props.isHistoric ? 0.3 : 1) : 0.8};
  }

  :focus {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) =>
      props.disabled ? (!props.isHistoric ? 0.3 : 1) : 0.8};
  }
`;

export const HistoricizeButton = forwardRef(
  (
    props: React.HTMLAttributes<HTMLButtonElement> & {
      isHistoric?: boolean;
      disabled?: boolean;
    },
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <HistoricizeButtonWrapper role="button" {...props} ref={ref}>
      {props.isHistoric ? (
        <ImportedIcon />
      ) : (
        <ImportIcon disabled={props.disabled} />
      )}
    </HistoricizeButtonWrapper>
  )
);

const AgencyContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.greyLight};
  background-color: ${(props) => props.theme.colors.white};
  position: relative;

  @media (max-width: 992px) {
    display: block;
  }
`;

const AgencyName = styled.div<{ theme: GardThemeType }>`
  border-right: 1px solid ${(props) => props.theme.colors.greyLight};
  padding: 16px;
  margin: 0;
  flex: 1 1;
  color: ${(props) => props.theme.colors.textHeading};
  font-size: ${(props) => props.theme.fontSizes.paragraph};
  font-family: ${(props) => props.theme.fonts.regular};

  @media (max-width: 992px) {
    border-bottom: 1px solid ${(props) => props.theme.colors.greyLight};
  }
`;

const AgencyContacts = styled.div<{ theme: GardThemeType }>`
  padding: 10px;
  border-right: 1px solid ${(props) => props.theme.colors.greyLight};
  flex: 2 1;

  @media (max-width: 992px) {
    display: inline-block;
    width: 75%;
    vertical-align: top;
  }
`;

const ContactCardItem = styled(ContactCard)`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AgencySubs = styled.div`
  padding: 10px;
  flex: 1 1;

  @media (max-width: 992px) {
    display: inline-block;
    width: 25%;
    vertical-align: top;
  }
`;

const SubItem = styled(Sub)`
  margin-bottom: 10px;
  cursor: auto;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ActionButtonContaienr = styled.div`
  padding: 5px;
`;

const AgencyButtons = styled.div<{ theme: GardThemeType }>`
  display: flex;
  justify-content: space-between;
  border-left: 1px solid ${(props) => props.theme.colors.greyLight};
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 5px;

  @media (max-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export function Agency(
  props: AgencyProps & React.HTMLAttributes<HTMLDivElement>
) {
  const [editingContact, setEditingContact] = useState<ContactDto>();

  return (
    <AgencyContainer id={props.id} className={props.className}>
      <AgencyName>{props.name}</AgencyName>
      <AgencyContacts>
        {props.contacts
          ? props.contacts?.map((contact) => {
              return (
                <Fragment key={contact.id}>
                  <ContactCardItem
                    name={contact.name}
                    email={contact.email}
                    editOnClick={() => setEditingContact(contact)}
                    deleteOnClick={() => props.deleteOnClick(contact)}
                  />
                  {props.editModal && editingContact?.id === contact.id
                    ? props.editModal(
                        true,
                        () => setEditingContact(undefined),
                        contact
                      )
                    : null}
                </Fragment>
              );
            })
          : null}
      </AgencyContacts>
      <AgencySubs>
        {props.subs
          ? props.subs?.map((sub) => {
              return (
                <SubItem
                  key={sub.sub_section_id}
                  label={sub.label}
                  completed={sub.completed}
                  sub_section_id={sub.sub_section_id}
                >
                  {props.removeSubSectionSubmission?.(sub)}
                  {props.historicizeModal?.(sub)}
                </SubItem>
              );
            })
          : null}
      </AgencySubs>
      <AgencyButtons>
        <div>
          {props.dashboardOnClick && (
            <ActionButtonContaienr>
              <ButtonIcon type="dashboard" onClick={props.dashboardOnClick} />
            </ActionButtonContaienr>
          )}
          {props.dashboardHref && (
            <ActionButtonContaienr>
              <ButtonIcon
                type="dashboard"
                href={props.dashboardHref}
                state={props.dashboardState}
              />
            </ActionButtonContaienr>
          )}
          {props.addModal && (
            <ActionButtonContaienr>{props.addModal}</ActionButtonContaienr>
          )}
        </div>
        <div>
          {props.editAgency && (
            <ActionButtonContaienr>{props.editAgency}</ActionButtonContaienr>
          )}
          {props.deleteAgency && (
            <ActionButtonContaienr>{props.deleteAgency}</ActionButtonContaienr>
          )}
        </div>
      </AgencyButtons>
    </AgencyContainer>
  );
}

export default Agency;
