export enum CurrencyType {
  'usd' = 'usd',
  'eur' = 'eur',
  'gbp' = 'gbp',
  'inr' = 'inr',
  'aud' = 'aud',
  'cad' = 'cad',
  'sgd' = 'sgd',
  'chf' = 'chf',
  'myr' = 'myr',
  'jpy' = 'jpy',
  'cny' = 'cny',
  'nzd' = 'nzd',
  'thb' = 'thb',
  'huf' = 'huf',
  'aed' = 'aed',
  'hkd' = 'hkd',
  'mxn' = 'mxn',
  'zar' = 'zar',
  'php' = 'php',
  'sek' = 'sek',
  'idr' = 'idr',
  'brl' = 'brl',
  'sar' = 'sar',
  'try' = 'try',
  'kes' = 'kes',
  'krw' = 'krw',
  'egp' = 'egp',
  'iqd' = 'iqd',
  'nok' = 'nok',
  'kwd' = 'kwd',
  'rub' = 'rub',
  'dkk' = 'dkk',
  'pkr' = 'pkr',
  'ils' = 'ils',
  'pln' = 'pln',
  'qar' = 'qar',
  'xau' = 'xau',
  'omr' = 'omr',
  'cop' = 'cop',
  'clp' = 'clp',
  'twd' = 'twd',
  'ars' = 'ars',
  'czk' = 'czk',
  'vnd' = 'vnd',
  'mad' = 'mad',
  'jod' = 'jod',
  'bhd' = 'bhd',
  'xof' = 'xof',
  'lkr' = 'lkr',
  'uah' = 'uah',
  'ngn' = 'ngn',
  'tnd' = 'tnd',
  'ugx' = 'ugx',
  'ron' = 'ron',
  'bdt' = 'bdt',
  'pen' = 'pen',
  'gel' = 'gel',
  'xaf' = 'xaf',
  'fjd' = 'fjd',
  'vef' = 'vef',
  'ves' = 'ves',
  'byn' = 'byn',
  'hrk' = 'hrk',
  'uzs' = 'uzs',
  'bgn' = 'bgn',
  'dzd' = 'dzd',
  'irr' = 'irr',
  'dop' = 'dop',
  'isk' = 'isk',
  'crc' = 'crc',
  'xag' = 'xag',
  'syp' = 'syp',
  'jmd' = 'jmd',
  'lyd' = 'lyd',
  'ghs' = 'ghs',
  'mur' = 'mur',
  'aoa' = 'aoa',
  'uyu' = 'uyu',
  'afn' = 'afn',
  'lbp' = 'lbp',
  'xpf' = 'xpf',
  'ttd' = 'ttd',
  'tzs' = 'tzs',
  'all' = 'all',
  'xcd' = 'xcd',
  'gtq' = 'gtq',
  'npr' = 'npr',
  'bob' = 'bob',
  'zwd' = 'zwd',
  'bbd' = 'bbd',
  'cuc' = 'cuc',
  'lak' = 'lak',
  'bnd' = 'bnd',
  'bwp' = 'bwp',
  'hnl' = 'hnl',
  'pyg' = 'pyg',
  'etb' = 'etb',
  'nad' = 'nad',
  'pgk' = 'pgk',
  'sdg' = 'sdg',
  'mop' = 'mop',
  'bmd' = 'bmd',
  'nio' = 'nio',
  'bam' = 'bam',
  'kzt' = 'kzt',
  'pab' = 'pab',
  'gyd' = 'gyd',
  'yer' = 'yer',
  'mga' = 'mga',
  'kyd' = 'kyd',
  'mzn' = 'mzn',
  'rsd' = 'rsd',
  'scr' = 'scr',
  'amd' = 'amd',
  'azn' = 'azn',
  'sbd' = 'sbd',
  'sll' = 'sll',
  'top' = 'top',
  'bzd' = 'bzd',
  'gmd' = 'gmd',
  'mwk' = 'mwk',
  'bif' = 'bif',
  'htg' = 'htg',
  'sos' = 'sos',
  'gnf' = 'gnf',
  'mnt' = 'mnt',
  'mvr' = 'mvr',
  'cdf' = 'cdf',
  'stn' = 'stn',
  'tjs' = 'tjs',
  'kpw' = 'kpw',
  'kgs' = 'kgs',
  'lrd' = 'lrd',
  'lsl' = 'lsl',
  'mmk' = 'mmk',
  'gip' = 'gip',
  'xpt' = 'xpt',
  'mdl' = 'mdl',
  'cup' = 'cup',
  'khr' = 'khr',
  'mkd' = 'mkd',
  'vuv' = 'vuv',
  'ang' = 'ang',
  'mru' = 'mru',
  'szl' = 'szl',
  'cve' = 'cve',
  'srd' = 'srd',
  'svc' = 'svc',
  'xpd' = 'xpd',
  'bsd' = 'bsd',
  'xdr' = 'xdr',
  'rwf' = 'rwf',
  'awg' = 'awg',
  'btn' = 'btn',
  'djf' = 'djf',
  'kmf' = 'kmf',
  'ern' = 'ern',
  'fkp' = 'fkp',
  'shp' = 'shp',
  'spl' = 'spl',
  'wst' = 'wst',
  'jep' = 'jep',
  'tmt' = 'tmt',
  'ggp' = 'ggp',
  'imp' = 'imp',
  'tvd' = 'tvd',
  'zmw' = 'zmw',
  'clf' = 'clf',
  'cnh' = 'cnh',
  'mxv' = 'mxv',
  'ved' = 'ved',
  'xbt' = 'xbt',
  'byr' = 'byr',
  'eek' = 'eek',
  'gqe' = 'gqe',
  'ltl' = 'ltl',
  'lvl' = 'lvl',
  'mro' = 'mro',
  'mzm' = 'mzm',
  'veb' = 'veb',
  'zmk' = 'zmk',
  'zwr' = 'zwr',
}

export const CurrenciesNamesList = [
  { cc: CurrencyType.aed, symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { cc: CurrencyType.afn, symbol: 'Afs', name: 'Afghan afghani' },
  { cc: CurrencyType.all, symbol: 'L', name: 'Albanian lek' },
  { cc: CurrencyType.amd, symbol: 'AMD', name: 'Armenian dram' },
  {
    cc: CurrencyType.ang,
    symbol: 'NA\u0192',
    name: 'Netherlands Antillean gulden',
  },
  { cc: CurrencyType.aoa, symbol: 'Kz', name: 'Angolan kwanza' },
  { cc: CurrencyType.ars, symbol: '$', name: 'Argentine peso' },
  { cc: CurrencyType.aud, symbol: '$', name: 'Australian dollar' },
  { cc: CurrencyType.awg, symbol: '\u0192', name: 'Aruban florin' },
  { cc: CurrencyType.azn, symbol: 'AZN', name: 'Azerbaijani manat' },
  {
    cc: CurrencyType.bam,
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka',
  },
  { cc: CurrencyType.bbd, symbol: 'Bds$', name: 'Barbadian dollar' },
  { cc: CurrencyType.bdt, symbol: '\u09f3', name: 'Bangladeshi taka' },
  { cc: CurrencyType.bgn, symbol: 'BGN', name: 'Bulgarian lev' },
  { cc: CurrencyType.bhd, symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { cc: CurrencyType.bif, symbol: 'FBu', name: 'Burundi franc' },
  { cc: CurrencyType.bmd, symbol: 'BD$', name: 'Bermudian dollar' },
  { cc: CurrencyType.bnd, symbol: 'B$', name: 'Brunei dollar' },
  { cc: CurrencyType.bob, symbol: 'Bs.', name: 'Bolivian boliviano' },
  { cc: CurrencyType.brl, symbol: 'R$', name: 'Brazilian real' },
  { cc: CurrencyType.bsd, symbol: 'B$', name: 'Bahamian dollar' },
  { cc: CurrencyType.btn, symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { cc: CurrencyType.bwp, symbol: 'P', name: 'Botswana pula' },
  { cc: CurrencyType.byr, symbol: 'Br', name: 'Belarusian ruble' },
  { cc: CurrencyType.bzd, symbol: 'BZ$', name: 'Belize dollar' },
  { cc: CurrencyType.cad, symbol: '$', name: 'Canadian dollar' },
  { cc: CurrencyType.cdf, symbol: 'F', name: 'Congolese franc' },
  { cc: CurrencyType.chf, symbol: 'Fr.', name: 'Swiss franc' },
  { cc: CurrencyType.clp, symbol: '$', name: 'Chilean peso' },
  { cc: CurrencyType.cny, symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { cc: CurrencyType.cop, symbol: 'Col$', name: 'Colombian peso' },
  { cc: CurrencyType.crc, symbol: '\u20a1', name: 'Costa Rican colon' },
  { cc: CurrencyType.cuc, symbol: '$', name: 'Cuban peso' },
  { cc: CurrencyType.cve, symbol: 'Esc', name: 'Cape Verdean escudo' },
  { cc: CurrencyType.czk, symbol: 'K\u010d', name: 'Czech koruna' },
  { cc: CurrencyType.djf, symbol: 'Fdj', name: 'Djiboutian franc' },
  { cc: CurrencyType.dkk, symbol: 'Kr', name: 'Danish krone' },
  { cc: CurrencyType.dop, symbol: 'RD$', name: 'Dominican peso' },
  { cc: CurrencyType.dzd, symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { cc: CurrencyType.eek, symbol: 'KR', name: 'Estonian kroon' },
  { cc: CurrencyType.egp, symbol: '\u00a3', name: 'Egyptian pound' },
  { cc: CurrencyType.ern, symbol: 'Nfa', name: 'Eritrean nakfa' },
  { cc: CurrencyType.etb, symbol: 'Br', name: 'Ethiopian birr' },
  { cc: CurrencyType.eur, symbol: '\u20ac', name: 'European Euro' },
  { cc: CurrencyType.fjd, symbol: 'FJ$', name: 'Fijian dollar' },
  { cc: CurrencyType.fkp, symbol: '\u00a3', name: 'Falkland Islands pound' },
  { cc: CurrencyType.gbp, symbol: '\u00a3', name: 'British pound' },
  { cc: CurrencyType.gel, symbol: 'GEL', name: 'Georgian lari' },
  { cc: CurrencyType.ghs, symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { cc: CurrencyType.gip, symbol: '\u00a3', name: 'Gibraltar pound' },
  { cc: CurrencyType.gmd, symbol: 'D', name: 'Gambian dalasi' },
  { cc: CurrencyType.gnf, symbol: 'FG', name: 'Guinean franc' },
  { cc: CurrencyType.gqe, symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: CurrencyType.gtq, symbol: 'Q', name: 'Guatemalan quetzal' },
  { cc: CurrencyType.gyd, symbol: 'GY$', name: 'Guyanese dollar' },
  { cc: CurrencyType.hkd, symbol: 'HK$', name: 'Hong Kong dollar' },
  { cc: CurrencyType.hnl, symbol: 'L', name: 'Honduran lempira' },
  { cc: CurrencyType.hrk, symbol: 'kn', name: 'Croatian kuna' },
  { cc: CurrencyType.htg, symbol: 'G', name: 'Haitian gourde' },
  { cc: CurrencyType.huf, symbol: 'Ft', name: 'Hungarian forint' },
  { cc: CurrencyType.idr, symbol: 'Rp', name: 'Indonesian rupiah' },
  { cc: CurrencyType.ils, symbol: '\u20aa', name: 'Israeli new sheqel' },
  { cc: CurrencyType.inr, symbol: '\u20B9', name: 'Indian rupee' },
  { cc: CurrencyType.iqd, symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { cc: CurrencyType.irr, symbol: 'IRR', name: 'Iranian rial' },
  { cc: CurrencyType.isk, symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { cc: CurrencyType.jmd, symbol: 'J$', name: 'Jamaican dollar' },
  { cc: CurrencyType.jod, symbol: 'JOD', name: 'Jordanian dinar' },
  { cc: CurrencyType.jpy, symbol: '\u00a5', name: 'Japanese yen' },
  { cc: CurrencyType.kes, symbol: 'KSh', name: 'Kenyan shilling' },
  {
    cc: CurrencyType.kgs,
    symbol: '\u0441\u043e\u043c',
    name: 'Kyrgyzstani som',
  },
  { cc: CurrencyType.khr, symbol: '\u17db', name: 'Cambodian riel' },
  { cc: CurrencyType.kmf, symbol: 'KMF', name: 'Comorian franc' },
  { cc: CurrencyType.kpw, symbol: 'W', name: 'North Korean won' },
  { cc: CurrencyType.krw, symbol: 'W', name: 'South Korean won' },
  { cc: CurrencyType.kwd, symbol: 'KWD', name: 'Kuwaiti dinar' },
  { cc: CurrencyType.kyd, symbol: 'KY$', name: 'Cayman Islands dollar' },
  { cc: CurrencyType.kzt, symbol: 'T', name: 'Kazakhstani tenge' },
  { cc: CurrencyType.lak, symbol: 'KN', name: 'Lao kip' },
  { cc: CurrencyType.lbp, symbol: '\u00a3', name: 'Lebanese lira' },
  { cc: CurrencyType.lkr, symbol: 'Rs', name: 'Sri Lankan rupee' },
  { cc: CurrencyType.lrd, symbol: 'L$', name: 'Liberian dollar' },
  { cc: CurrencyType.lsl, symbol: 'M', name: 'Lesotho loti' },
  { cc: CurrencyType.ltl, symbol: 'Lt', name: 'Lithuanian litas' },
  { cc: CurrencyType.lvl, symbol: 'Ls', name: 'Latvian lats' },
  { cc: CurrencyType.lyd, symbol: 'LD', name: 'Libyan dinar' },
  { cc: CurrencyType.mad, symbol: 'MAD', name: 'Moroccan dirham' },
  { cc: CurrencyType.mdl, symbol: 'MDL', name: 'Moldovan leu' },
  { cc: CurrencyType.mga, symbol: 'FMG', name: 'Malagasy ariary' },
  { cc: CurrencyType.mkd, symbol: 'MKD', name: 'Macedonian denar' },
  { cc: CurrencyType.mmk, symbol: 'K', name: 'Myanma kyat' },
  { cc: CurrencyType.mnt, symbol: '\u20ae', name: 'Mongolian tugrik' },
  { cc: CurrencyType.mop, symbol: 'P', name: 'Macanese pataca' },
  { cc: CurrencyType.mro, symbol: 'UM', name: 'Mauritanian ouguiya' },
  { cc: CurrencyType.mur, symbol: 'Rs', name: 'Mauritian rupee' },
  { cc: CurrencyType.mvr, symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { cc: CurrencyType.mwk, symbol: 'MK', name: 'Malawian kwacha' },
  { cc: CurrencyType.mxn, symbol: '$', name: 'Mexican peso' },
  { cc: CurrencyType.myr, symbol: 'RM', name: 'Malaysian ringgit' },
  { cc: CurrencyType.mzm, symbol: 'MTn', name: 'Mozambican metical' },
  { cc: CurrencyType.nad, symbol: 'N$', name: 'Namibian dollar' },
  { cc: CurrencyType.ngn, symbol: '\u20a6', name: 'Nigerian naira' },
  { cc: CurrencyType.nio, symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { cc: CurrencyType.nok, symbol: 'kr', name: 'Norwegian krone' },
  { cc: CurrencyType.npr, symbol: 'NRs', name: 'Nepalese rupee' },
  { cc: CurrencyType.nzd, symbol: 'NZ$', name: 'New Zealand dollar' },
  { cc: CurrencyType.omr, symbol: 'OMR', name: 'Omani rial' },
  { cc: CurrencyType.pab, symbol: 'B./', name: 'Panamanian balboa' },
  { cc: CurrencyType.pen, symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { cc: CurrencyType.pgk, symbol: 'K', name: 'Papua New Guinean kina' },
  { cc: CurrencyType.php, symbol: '\u20b1', name: 'Philippine peso' },
  { cc: CurrencyType.pkr, symbol: 'Rs.', name: 'Pakistani rupee' },
  { cc: CurrencyType.pln, symbol: 'z\u0142', name: 'Polish zloty' },
  { cc: CurrencyType.pyg, symbol: '\u20b2', name: 'Paraguayan guarani' },
  { cc: CurrencyType.qar, symbol: 'QR', name: 'Qatari riyal' },
  { cc: CurrencyType.ron, symbol: 'L', name: 'Romanian leu' },
  { cc: CurrencyType.rsd, symbol: 'din.', name: 'Serbian dinar' },
  { cc: CurrencyType.rub, symbol: 'R', name: 'Russian ruble' },
  { cc: CurrencyType.sar, symbol: 'SR', name: 'Saudi riyal' },
  { cc: CurrencyType.sbd, symbol: 'SI$', name: 'Solomon Islands dollar' },
  { cc: CurrencyType.scr, symbol: 'SR', name: 'Seychellois rupee' },
  { cc: CurrencyType.sdg, symbol: 'SDG', name: 'Sudanese pound' },
  { cc: CurrencyType.sek, symbol: 'kr', name: 'Swedish krona' },
  { cc: CurrencyType.sgd, symbol: 'S$', name: 'Singapore dollar' },
  { cc: CurrencyType.shp, symbol: '\u00a3', name: 'Saint Helena pound' },
  { cc: CurrencyType.sll, symbol: 'Le', name: 'Sierra Leonean leone' },
  { cc: CurrencyType.sos, symbol: 'Sh.', name: 'Somali shilling' },
  { cc: CurrencyType.srd, symbol: '$', name: 'Surinamese dollar' },
  { cc: CurrencyType.syp, symbol: 'LS', name: 'Syrian pound' },
  { cc: CurrencyType.szl, symbol: 'E', name: 'Swazi lilangeni' },
  { cc: CurrencyType.thb, symbol: '\u0e3f', name: 'Thai baht' },
  { cc: CurrencyType.tjs, symbol: 'TJS', name: 'Tajikistani somoni' },
  { cc: CurrencyType.tmt, symbol: 'm', name: 'Turkmen manat' },
  { cc: CurrencyType.tnd, symbol: 'DT', name: 'Tunisian dinar' },
  { cc: CurrencyType.try, symbol: 'TRY', name: 'Turkish new lira' },
  { cc: CurrencyType.ttd, symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  { cc: CurrencyType.twd, symbol: 'NT$', name: 'New Taiwan dollar' },
  { cc: CurrencyType.tzs, symbol: 'TZS', name: 'Tanzanian shilling' },
  { cc: CurrencyType.uah, symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { cc: CurrencyType.ugx, symbol: 'USh', name: 'Ugandan shilling' },
  { cc: CurrencyType.usd, symbol: 'US$', name: 'United States dollar' },
  { cc: CurrencyType.uyu, symbol: '$U', name: 'Uruguayan peso' },
  { cc: CurrencyType.uzs, symbol: 'UZS', name: 'Uzbekistani som' },
  { cc: CurrencyType.veb, symbol: 'Bs', name: 'Venezuelan bolivar' },
  { cc: CurrencyType.vnd, symbol: '\u20ab', name: 'Vietnamese dong' },
  { cc: CurrencyType.vuv, symbol: 'VT', name: 'Vanuatu vatu' },
  { cc: CurrencyType.wst, symbol: 'WS$', name: 'Samoan tala' },
  { cc: CurrencyType.xaf, symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: CurrencyType.xcd, symbol: 'EC$', name: 'East Caribbean dollar' },
  { cc: CurrencyType.xdr, symbol: 'SDR', name: 'Special Drawing Rights' },
  { cc: CurrencyType.xof, symbol: 'CFA', name: 'West African CFA franc' },
  { cc: CurrencyType.xpf, symbol: 'F', name: 'CFP franc' },
  { cc: CurrencyType.yer, symbol: 'YER', name: 'Yemeni rial' },
  { cc: CurrencyType.zar, symbol: 'R', name: 'South African rand' },
  { cc: CurrencyType.zmk, symbol: 'ZK', name: 'Zambian kwacha' },
  { cc: CurrencyType.zwr, symbol: 'Z$', name: 'Zimbabwean dollar' },
  { cc: CurrencyType.ves, symbol: 'VES', name: 'Venezuelan Bolívar' },
];
