import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';
import {
  UpdateCollectorDto,
  CollectorDto,
} from '@observatory/shared/gard/dtos';

export function useUpdateRateCollection() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  return {
    error,
    async updateRate(data: UpdateCollectorDto) {
      const result = await fetcher('/api/rate-collection/update', {
        method: 'PUT',
        body: data,
      });

      return handle<Array<CollectorDto>>(result);
    },
  };
}
