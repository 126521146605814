import { joiResolver } from '@hookform/resolvers/joi';
import {
  ButtonPrimary,
  H4,
  InfoArea,
  InputFormInput,
  LabelText,
  Modal,
  ParagraphText,
  PillTextBold,
  SelectFormInput,
  Table,
  TableDescription,
  TableText,
} from '@observatory/front-end/core-ui';
import {
  CurrencySelect,
  OptionsSelect,
} from '@observatory/front-end/form-inputs';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import {
  CurrencyType,
  RoleGenericDescriptorType,
} from '@observatory/shared/gard/dtos';
import Joi from 'joi';
import React, { Children, PropsWithChildren, useEffect } from 'react';
import { useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import {
  accountManagementTableInfo,
  agencyManagementTableInfo,
  analyticsTableInfo,
  creativeTableInfo,
  mediaTableInfo,
  otherTableInfo,
  productionTableInfo,
  projectManagementTableInfo,
} from '../../data';

/* eslint-disable-next-line */
export interface AddRatesSubFormProps {
  defaultValues?: Partial<AddRatesSubFormValues>;
  agencyName?: string;
  subSectionName?: string;
  isRateOnly?: boolean;
  onSubmit: (values: AddRatesSubFormValues) => unknown | Promise<unknown>;
}

const AddRatesSubFormContainer = styled.form``;

const MainFormContainer = styled.div<{ theme: GardThemeType }>`
  padding: 42px 65px 62px 65px;
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 16px;
`;

const MainForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;

  > * {
    flex: 0 1 calc(33.33% - 10px);
    width: calc(33.33% - 10px);
    margin-bottom: 15px;
    display: flex;
    align-items: flex-end;

    &:nth-child(2),
    &:nth-child(3n + 2) {
      margin: 0 15px 15px 15px;
    }
  }
`;

const RowsFormContainer = styled.div<{ theme: GardThemeType }>`
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 16px;
`;

const RowsFormHeader = styled.div<{ theme: GardThemeType }>`
  display: flex;
  justify-content: space-between;
  padding: 32px 65px;
  border-bottom: 1px solid ${(props) => props.theme.colors.greyLight};
`;

const RowsFormContent = styled.div`
  padding: 32px 65px;
`;

const NotARowItem = (props: PropsWithChildren<unknown>) =>
  props.children as JSX.Element;

function calculateNumberOfGivenChildren(
  children: PropsWithChildren<unknown>['children']
) {
  let childOfFragments = 0;
  const filteredChildren = Children.toArray(children).filter((child) => {
    if (React.isValidElement(child)) {
      if (child.type === NotARowItem) {
        // Ignore the item wrapped in not a row item
        return false;
      }
      if (child.type === React.Fragment) {
        // If is a fragment calculate the children of it to get the right number of row elements
        childOfFragments += calculateNumberOfGivenChildren(
          child.props.children
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  });
  return filteredChildren.length + childOfFragments;
}

const RowItem = styled.div`
  display: flex;

  > * {
    flex: 0 1
      calc(
        ${(props) => `${100 / calculateNumberOfGivenChildren(props.children)}%`} -
          4px
      );
    width: calc(
      ${(props) => `${100 / calculateNumberOfGivenChildren(props.children)}%`} -
        4px
    );
    margin-right: 5px;

    &:nth-child(5),
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RowItemInputs = styled(RowItem)`
  position: relative;
  margin-bottom: 8px;
`;

const RowItemInputLabel = styled(LabelText)<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 5px;
`;

const SubmitButton = styled(ButtonPrimary)`
  width: 100%;
  margin-top: 32px;
`;

const RowsFormHeaderTitle = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 12px;
  }
`;

const DeleteButton = styled.i`
  position: absolute;
  width: auto;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const InfoIconButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const DepartmentsTotalContainer = styled.div<{ theme: GardThemeType }>`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => `${props.theme.spacing.s} ${props.theme.spacing.l}`};
`;

const InfoIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(0.8)' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3828 25.5c6.9036 0 12.5-5.5964 12.5-12.5 0-6.90356-5.5964-12.5-12.5-12.5C6.47925.5.882812 6.09644.882812 13c0 6.9036 5.596438 12.5 12.499988 12.5Z"
        fill="#270654"
      />
      <path
        d="M14.8597 19.5h-2.533v-8.942h2.533V19.5Zm.238-11.526c0 .46467-.153.833-.459 1.105-.2946.26067-.646.391-1.054.391-.408 0-.765-.13033-1.071-.391-.2946-.272-.442-.64033-.442-1.105s.1474-.82733.442-1.088c.306-.272.663-.408 1.071-.408.408 0 .7594.136 1.054.408.306.26067.459.62333.459 1.088Z"
        fill="#fff"
      />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3 2.60005c0-.47739.18965-.93523.52721-1.27279C6.16478.989691 6.62261.800049 7.1.800049h5.4c.4774 0 .9352.189642 1.2728.527211.3376.33756.5272.7954.5272 1.27279v1.8h3.6c.2387 0 .4676.09482.6364.2636.1688.16879.2636.3977.2636.6364 0 .23869-.0948.46761-.2636.6364-.1688.16878-.3977.2636-.6364.2636h-.9621l-.7803 10.92785c-.0323.4541-.2355.8791-.5687 1.1894s-.7715.4828-1.2268.4828H5.237c-.45527 0-.89364-.1725-1.2268-.4828-.33316-.3103-.53637-.7353-.5687-1.1894L2.663 6.20005H1.7c-.23869 0-.46761-.09482-.63639-.2636-.168786-.16879-.263607-.39771-.263607-.6364 0-.2387.094821-.46761.263607-.6364.16878-.16878.3977-.2636.63639-.2636h3.6v-1.8Zm1.8 1.8h5.4v-1.8H7.1v1.8Zm-2.6334 1.8L5.2379 17h9.1251l.7713-10.79995H4.4666ZM8 8.00005c.2387 0 .46762.09482.6364.2636.16878.16879.2636.39771.2636.6364v5.40005c0 .2386-.09482.4676-.2636.6363-.16878.1688-.3977.2637-.6364.2637-.23869 0-.46761-.0949-.63639-.2637-.16878-.1687-.26361-.3977-.26361-.6363V8.90005c0-.23869.09483-.46761.26361-.6364.16878-.16878.3977-.2636.63639-.2636Zm3.6 0c.2387 0 .4676.09482.6364.2636.1688.16879.2636.39771.2636.6364v5.40005c0 .2386-.0948.4676-.2636.6363-.1688.1688-.3977.2637-.6364.2637-.2387 0-.4676-.0949-.6364-.2637-.1688-.1687-.2636-.3977-.2636-.6363V8.90005c0-.23869.0948-.46761.2636-.6364.1688-.16878.3977-.2636.6364-.2636Z"
        fill="#EB5757"
      />
    </svg>
  );
};

const agencyRoleOptions: Array<{
  label: string;
  value: RoleGenericDescriptorType;
}> = [
  { label: 'CEO', value: 'CEO' },
  { label: 'Chairman', value: 'Chairman' },
  { label: 'President', value: 'President' },
  { label: 'Managing Director', value: 'Managing Director' },
  {
    label: 'Other (e.g. Partner, COO, etc.)',
    value: 'Other (e.g. Partner, COO, etc.)',
  },
];

const roleOptions: Array<{ label: string; value: RoleGenericDescriptorType }> =
  [
    { label: 'Department Head', value: 'Department Head' },
    { label: 'Senior', value: 'Senior' },
    { label: 'Middle(Upper)', value: 'Middle (Upper)' },
    { label: 'Middle(Lower)', value: 'Middle (Lower)' },
    { label: 'Junior', value: 'Junior' },
  ];

const projectRoleOptions: Array<{
  label: string;
  value: RoleGenericDescriptorType;
}> = [
  { label: 'Department Head', value: 'Department Head' },
  { label: 'Senior', value: 'Senior' },
  { label: 'Middle(Upper)', value: 'Middle (Upper)' },
  { label: 'Middle(Lower)', value: 'Middle (Lower)' },
  { label: 'Junior', value: 'Junior' },
  { label: 'Technology - Senior', value: 'Technology - Senior' },
  { label: 'Technology - Middle(Upper)', value: 'Technology - Middle (Upper)' },
  { label: 'Technology - Middle(Lower)', value: 'Technology - Middle (Lower)' },
  { label: 'Technology - Junior', value: 'Technology - Junior' },
];

const productionRoleOptions: Array<{
  label: string;
  value: RoleGenericDescriptorType;
}> = [
  { label: 'Department Head', value: 'Department Head' },
  {
    label: 'Broadcast Production - Senior',
    value: 'Broadcast Production - Senior',
  },
  {
    label: 'Broadcast Production - Middle (Upper)',
    value: 'Broadcast Production - Middle (Upper)',
  },
  {
    label: 'Broadcast Production - Middle (Lower)',
    value: 'Broadcast Production - Middle (Lower)',
  },
  {
    label: 'Broadcast Production - Junior',
    value: 'Broadcast Production - Junior',
  },
  { label: 'Print Production - Senior', value: 'Print Production - Senior' },
  {
    label: 'Print Production - Middle (Upper)',
    value: 'Print Production - Middle (Upper)',
  },
  {
    label: 'Print Production - Middle (Lower)',
    value: 'Print Production - Middle (Lower)',
  },
  { label: 'Print Production - Junior', value: 'Print Production - Junior' },
  {
    label: 'Digital Production - Senior',
    value: 'Digital Production - Senior',
  },
  {
    label: 'Digital Production - Middle (Upper)',
    value: 'Digital Production - Middle (Upper)',
  },
  {
    label: 'Digital Production - Middle (Lower)',
    value: 'Digital Production - Middle (Lower)',
  },
  {
    label: 'Digital Production - Junior',
    value: 'Digital Production - Junior',
  },
  { label: 'Other Production - Senior', value: 'Other Production - Senior' },
  {
    label: 'Other Production - Middle (Upper)',
    value: 'Other Production - Middle (Upper)',
  },
  {
    label: 'Other Production - Middle (Lower)',
    value: 'Other Production - Middle (Lower)',
  },
  { label: 'Other Production - Junior', value: 'Other Production - Junior' },
];

export interface RowInputsTypes {
  role: RoleGenericDescriptorType;
  jobTitle: string;
  hourlyRate: number;
  percentageTime: number;
}

export const calculateWh = (billableHours: number, percentageTime: number) => {
  if (billableHours && percentageTime) {
    return Math.round(billableHours * (percentageTime / 100));
  } else {
    return 0;
  }
};

export const calculateTbpy = (wh: number, hourlyRate: number) => {
  if (wh && hourlyRate) {
    return Math.round(wh * hourlyRate);
  } else {
    return 0;
  }
};

const FormRowItem = ({
  roleOptions,
  roleName,
  jobTitleName,
  hourlyRateName,
  percentageTimeName,
  fieldToWatch,
  deleteAction,
  isRateOnly,
}: {
  roleOptions: Array<{ label: string; value: string }>;
  roleName: string;
  jobTitleName: string;
  hourlyRateName: string;
  percentageTimeName: string;
  fieldToWatch: string;
  deleteAction: () => void;
  isRateOnly?: boolean;
}) => {
  const billableHours = useWatch({
    name: fieldToWatch,
  });
  const hourlyRate = useWatch({
    name: hourlyRateName,
  });
  const percentageTime = useWatch({
    name: percentageTimeName,
  });

  const wh: number = useMemo(
    () => calculateWh(billableHours, percentageTime),
    [billableHours, percentageTime]
  );

  const tbpy: number = useMemo(
    () => calculateTbpy(wh, hourlyRate),
    [wh, hourlyRate]
  );

  return (
    <RowItemInputs>
      <SelectFormInput
        name={roleName}
        type="single-selection"
        options={roleOptions}
        placeholder="Role"
        required
      />
      <InputFormInput
        name={jobTitleName}
        type="text"
        placeholder="Actual job title"
        required
      />
      <InputFormInput
        name={hourlyRateName}
        type="text"
        placeholder="Hourly rate (gross)"
        required
      />
      {!isRateOnly && (
        <>
          <InputFormInput
            name={percentageTimeName}
            type="text"
            placeholder="% of the time"
            required
          />
          <InfoArea
            label={
              wh !== 0 && tbpy !== 0 ? `WH: ${wh} | TBPY: ${tbpy}` : 'EMPTY'
            }
            type={wh !== 0 && tbpy !== 0 ? 'success' : 'error'}
          />
        </>
      )}

      <NotARowItem>
        <DeleteButton data-testid="remove-row-button" onClick={deleteAction}>
          <DeleteIcon />
        </DeleteButton>
      </NotARowItem>
    </RowItemInputs>
  );
};

export interface TableModalInfoData {
  department: string;
  explanation: string;
  roles: string;
  years: string;
}

const FormRowSection = ({
  title,
  name,
  roleOptions,
  tableInfoData,
  isRateOnly,
}: {
  title: string;
  name: string;
  roleOptions: Array<{ label: string; value: string }>;
  tableInfoData: Array<TableModalInfoData>;
  isRateOnly?: boolean;
}) => {
  const { fields, append, remove } = useFieldArray({ name });

  const billableHours: number = useWatch({
    name: 'numberOfBillableInAWorkingYear',
  });
  const sectionRows: Array<RowInputsTypes> = useWatch({ name: name });

  const totalWh: number = sectionRows?.reduce(
    (accumulator: number, { percentageTime }: { percentageTime: number }) =>
      accumulator + calculateWh(billableHours, percentageTime),
    0
  );

  const totalTbpy: number = sectionRows?.reduce(
    (
      accumulator,
      {
        percentageTime,
        hourlyRate,
      }: { percentageTime: number; hourlyRate: number }
    ) => {
      const wh = calculateWh(billableHours, percentageTime);
      return accumulator + calculateTbpy(wh, hourlyRate);
    },
    0
  );

  const colDescription: Array<TableDescription<TableModalInfoData>> = [
    {
      headerName: 'DEPARTMENT / DESCRIPTOR',
      component: (row) => <PillTextBold>{row?.department}</PillTextBold>,
      percSize: 18,
    },
    {
      headerName: 'EXPLANATION',
      component: (row) => (
        <TableText color="#727272">{row?.explanation}</TableText>
      ),
      percSize: 32,
    },
    {
      headerName: 'EXAMPLE ROLES',
      component: (row) => <TableText color="#727272">{row?.roles}</TableText>,
      percSize: 32,
    },
    {
      headerName: 'SUGGESTED YEARS OF EXPERIENCE',
      component: (row) => <TableText color="#727272">{row?.years}</TableText>,
      percSize: 18,
    },
  ];

  return (
    <RowsFormContainer>
      <RowsFormHeader>
        <RowsFormHeaderTitle>
          <H4>{title}</H4>
          <Modal
            triggerElement={
              <InfoIconButton>
                <InfoIcon />
              </InfoIconButton>
            }
            title={
              <>
                {title}
                <InfoIcon />
              </>
            }
            customWidth="1020px"
          >
            {({ close }) => (
              <Table colDescription={colDescription} data={tableInfoData} />
            )}
          </Modal>
        </RowsFormHeaderTitle>
        <ButtonPrimary
          data-testid={`${name}-add-row-button`}
          onClick={() => {
            append({
              role: '',
              jobTitle: '',
              hourlyRate: 0,
              percentageTime: 0,
            });
          }}
        >
          CREATE ROLE
        </ButtonPrimary>
      </RowsFormHeader>
      <RowsFormContent>
        <RowItem>
          <RowItemInputLabel>Role</RowItemInputLabel>
          <RowItemInputLabel>Actual job title</RowItemInputLabel>
          <RowItemInputLabel>
            Hourly rate (gross), including overhead and margin
          </RowItemInputLabel>
          {!isRateOnly && (
            <>
              <RowItemInputLabel>% of the time</RowItemInputLabel>
              <RowItemInputLabel>
                {/* This is here to balance the calculation on number of rows */}
              </RowItemInputLabel>
            </>
          )}
        </RowItem>
        {fields &&
          fields?.map((item, index) => {
            return (
              <FormRowItem
                key={item.id}
                roleOptions={roleOptions}
                roleName={`${name}[${index}].role`}
                jobTitleName={`${name}[${index}].jobTitle`}
                hourlyRateName={`${name}[${index}].hourlyRate`}
                percentageTimeName={`${name}[${index}].percentageTime`}
                fieldToWatch="numberOfBillableInAWorkingYear"
                deleteAction={() => remove(index)}
                isRateOnly={isRateOnly}
              />
            );
          })}
        {totalWh !== 0 && totalTbpy !== 0 && (
          <TotalContainer>
            <InfoArea
              label={`WH: ${totalWh} | TBPY: ${totalTbpy}`}
              type="total"
              totalLabel="TOTAL"
            />
          </TotalContainer>
        )}
      </RowsFormContent>
    </RowsFormContainer>
  );
};

export type AddRatesSubFormValues = {
  clientName?: string;
  clientBrand?: string;
  currency: CurrencyType;
  country: string;
  city?: string;
  numberOfEmployees: string;
  numberOfBillableInAWorkingYear: number;
  ownership: string;
  overhead?: number;
  profitMargin?: number;
  agency_type: string;
  dataType?: string;
  agencyManagement: Array<RowInputsTypes>;
  accountManagement: Array<RowInputsTypes>;
  projectManagement: Array<RowInputsTypes>;
  creative: Array<RowInputsTypes>;
  analytics: Array<RowInputsTypes>;
  production: Array<RowInputsTypes>;
  media: Array<RowInputsTypes>;
  other: Array<RowInputsTypes>;
};

const sectionSchema = Joi.object({
  role: Joi.string().required().messages({
    'string.base': `The field 'Role' is required`,
    'string.empty': `The field 'Role' is required`,
  }),
  jobTitle: Joi.string().required().messages({
    'string.base': `The field 'Job Title' is required`,
    'string.empty': `The field 'Job Title' is required`,
  }),
  hourlyRate: Joi.number().min(1).required().messages({
    'number.base': `The field 'Hourly rate' is required`,
    'number.min': `The field 'Hourly rate' must be greater than or equal to 1`,
    number: `The field 'Hourly rate' must be a number`,
  }),
  percentageTime: Joi.number().min(0).max(100).required().messages({
    'number.base': `The field 'Percentage time' is required`,
    'number.min': `The field 'Percentage time' must be must be less than or equal to 100`,
    'number.max': `The field 'Percentage time' must be less than or equal to 100`,
    number: `The field 'Percentage time' must be a number`,
  }),
});

const schema = Joi.object({
  clientName: Joi.string().optional(),
  clientBrand: Joi.string().optional(),
  currency: Joi.string().required().messages({
    'string.base': `The field 'Currency' is required`,
  }),
  country: Joi.string().required().messages({
    'string.base': `The field 'Country' is required`,
  }),
  city: Joi.string().optional(),
  numberOfEmployees: Joi.string().required().messages({
    'string.base': `The field 'Number of employees' is required`,
  }),
  numberOfBillableInAWorkingYear: Joi.number().required().messages({
    'number.base': `The field 'Number of billable in a working year' is required`,
    number: `The field 'Number of billable in a working year' must be a number`,
  }),
  ownership: Joi.string().required().messages({
    'string.base': `The field 'Ownership' is required`,
  }),
  overhead: Joi.number().optional().messages({
    number: `The field 'Overhead' must be a number`,
  }),
  agency_type: Joi.string().required().messages({
    'string.base': `The field 'Agency type' is required`,
  }),
  profitMargin: Joi.number().optional().messages({
    number: `The field 'Profit Margin' must be a number`,
  }),
  dataType: [Joi.string().optional(), Joi.allow(null)],
  agencyManagement: Joi.array().items(sectionSchema).required(),
  accountManagement: Joi.array().items(sectionSchema).required(),
  projectManagement: Joi.array().items(sectionSchema).required(),
  creative: Joi.array().items(sectionSchema).required(),
  analytics: Joi.array().items(sectionSchema).required(),
  production: Joi.array().items(sectionSchema).required(),
  media: Joi.array().items(sectionSchema).required(),
  other: Joi.array().items(sectionSchema).required(),
});

const verbose_sections = [
  'agencyManagement',
  'accountManagement',
  'projectManagement',
  'creative',
  'analytics',
  'production',
  'media',
  'other',
] as const;

// Return -1 if no duplicated are found
// Return the index of the duplicate value
function findDuplicate(list: Array<RowInputsTypes>) {
  const map = new Map();
  let index = 0;
  for (const item of list) {
    const itemkey = `${item.jobTitle}-${item.role}`;
    if (map.has(itemkey)) {
      return index;
    } else {
      map.set(itemkey, null);
    }
    index++;
  }
  return -1;
}

function isNumeric(str: string) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function reanameJobTitleWhenDuplicateInForm(
  formMethods: UseFormReturn<AddRatesSubFormValues, object>,
  sections: Array<AddRatesSubFormValues[typeof verbose_sections[number]]>
) {
  if (sections && sections.length > 0) {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i] && sections[i].length > 0) {
        const duplicateIndex = findDuplicate(sections[i]);

        if (duplicateIndex === -1) {
          continue;
        }

        const oldList = [...sections[i]];
        const field_name = `${verbose_sections[i]}` as const;
        let oldJobTitle = oldList[duplicateIndex].jobTitle.trim();
        const latestRowCount = oldList
          .filter((item) => {
            return item.jobTitle.includes(oldJobTitle);
          })
          .sort((a, b) => {
            const aNumber = a.jobTitle.trim();
            const bNumber = b.jobTitle.trim();
            return Number(aNumber) - Number(bNumber);
          });

        let last = 0;
        let lastNumber;
        if (latestRowCount?.length > 0) {
          lastNumber = latestRowCount[0].jobTitle.split(/[ ]+/);
          if (lastNumber && lastNumber.length > 0) {
            last = isNumeric(lastNumber[lastNumber.length - 1])
              ? Number(lastNumber[lastNumber.length - 1])
              : 0;
          }
        }
        if (last > 0 && lastNumber && lastNumber.length > 0) {
          oldJobTitle = oldJobTitle
            .replace(` ${lastNumber[lastNumber.length - 1]}`, '')
            .trim();
        }
        oldList[duplicateIndex].jobTitle = `${oldJobTitle} ${last + 1}`;
        formMethods.setValue(field_name, oldList);
      }
    }
  }
}

export function AddRatesSubForm(props: AddRatesSubFormProps) {
  const formMethods = useForm<AddRatesSubFormValues>({
    resolver: joiResolver(schema),
    defaultValues: props.defaultValues,
  });

  const fieldsSectionToWatch = formMethods.watch();

  const billableHours = fieldsSectionToWatch.numberOfBillableInAWorkingYear;

  const sections = useMemo(
    () => [
      fieldsSectionToWatch.agencyManagement,
      fieldsSectionToWatch.accountManagement,
      fieldsSectionToWatch.projectManagement,
      fieldsSectionToWatch.creative,
      fieldsSectionToWatch.analytics,
      fieldsSectionToWatch.production,
      fieldsSectionToWatch.media,
      fieldsSectionToWatch.other,
    ],
    [fieldsSectionToWatch]
  );

  useEffect(() => {
    reanameJobTitleWhenDuplicateInForm(formMethods, sections);
  }, [sections, formMethods]);

  const totalWh = sections?.reduce((acc, section) => {
    return (
      acc +
      section?.reduce((accumulator, { percentageTime }) => {
        return accumulator + calculateWh(billableHours, percentageTime);
      }, 0)
    );
  }, 0);

  const totalTbpy = sections?.reduce((acc, section) => {
    return (
      acc +
      section?.reduce((accumulator, { percentageTime, hourlyRate }) => {
        const wh = calculateWh(billableHours, percentageTime);
        return accumulator + calculateTbpy(wh, hourlyRate);
      }, 0)
    );
  }, 0);

  return (
    <FormProvider {...formMethods}>
      <AddRatesSubFormContainer
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
      >
        <MainFormContainer>
          <H4>
            Add rates for {props.agencyName} {props.subSectionName}
          </H4>
          <ParagraphText>
            Please enter the information requested in the fields below.
          </ParagraphText>
          <MainForm>
            <InputFormInput
              label="Client Organisation Name"
              name="clientName"
              type="text"
              placeholder="Client Organisation Name"
            />
            <InputFormInput
              label="Client Brand or Project Name"
              name="clientBrand"
              type="text"
              placeholder="Client Brand or Project Name"
            />
            <CurrencySelect
              label="Currency"
              name="currency"
              placeholder="Currency"
              required
            />
            <OptionsSelect
              label="Country"
              name="country"
              type="single-selection"
              placeholder="Country"
              option="countries"
              required
            />
            <InputFormInput
              label="City"
              name="city"
              type="text"
              placeholder="City"
            />
            <OptionsSelect
              label="Number of employees"
              name="numberOfEmployees"
              option="agency-sizes"
              placeholder="Number of employees"
              required
            />
            <InputFormInput
              label="Number of billable hours/year"
              name="numberOfBillableInAWorkingYear"
              type="number"
              placeholder="Number of billable hours/year"
              required
            />
            <OptionsSelect
              label="Ownership"
              name="ownership"
              option="agency-ownerships"
              placeholder="Ownership"
              required
            />
            <InputFormInput
              label="Overhead (expressed as % uplift on fixed costs, e.g. 100%)"
              name="overhead"
              type="text"
              placeholder="Overhead"
              isPercentage
            />
            <InputFormInput
              label="Profit Margin (expressed as a % of the Gross rate)"
              name="profitMargin"
              type="text"
              placeholder="Profit Margin (expressed as a % of the Gross rate)"
              isPercentage
            />
            <OptionsSelect
              label="Agency type"
              name="agency_type"
              option="agency-types"
              placeholder="Agency type"
              required
            />
            <OptionsSelect
              label="Data type"
              name="dataType"
              option="rate_categories"
              placeholder="Data type"
            />
          </MainForm>
        </MainFormContainer>
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Agency management"
          name="agencyManagement"
          roleOptions={agencyRoleOptions}
          tableInfoData={agencyManagementTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Account management"
          name="accountManagement"
          roleOptions={roleOptions}
          tableInfoData={accountManagementTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Project management"
          name="projectManagement"
          roleOptions={projectRoleOptions}
          tableInfoData={projectManagementTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Creative"
          name="creative"
          roleOptions={roleOptions}
          tableInfoData={creativeTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Analytics, planning and strategy"
          name="analytics"
          roleOptions={roleOptions}
          tableInfoData={analyticsTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Production and technology"
          name="production"
          roleOptions={productionRoleOptions}
          tableInfoData={productionTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Media Buying"
          name="media"
          roleOptions={roleOptions}
          tableInfoData={mediaTableInfo}
        />
        <FormRowSection
          isRateOnly={props.isRateOnly}
          title="Other"
          name="other"
          roleOptions={roleOptions}
          tableInfoData={otherTableInfo}
        />
        {totalWh !== 0 && totalTbpy !== 0 && (
          <DepartmentsTotalContainer>
            <InfoArea
              label={`WH: ${totalWh} | TBPY: ${totalTbpy}`}
              type="total"
              totalLabel="DEPARTMENTS TOTAL"
            />
          </DepartmentsTotalContainer>
        )}
        <SubmitButton
          disabled={formMethods.formState.isSubmitting}
          data-testid="submit-rate-button"
        >
          SUBMIT DATA
        </SubmitButton>
      </AddRatesSubFormContainer>
    </FormProvider>
  );
}

export default AddRatesSubForm;
