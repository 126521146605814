import {
  ButtonOutlined,
  ButtonPrimary,
  Checkbox,
  DialogButtons,
  TableDescription,
} from '@observatory/front-end/core-ui';
import { GardThemeType } from '@observatory/front-end/gard-theme';
import { DialogClose } from '@radix-ui/react-dialog';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReversedDataInterface } from '../../pages/collector-analysis-results/data-manipulation-utils';
import {
  ArrayColsDescription,
  exportCsv,
} from '../../pages/collector-analysis-results/export-csv-utils';

/* eslint-disable-next-line */
export interface CsvExportModalFormProps {
  onClose: () => void;
  data: Array<ReversedDataInterface>;
  colsDescription: ArrayColsDescription<ReversedDataInterface>;
}

const CsvExportModalFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  > * {
    margin-bottom: 18px;
  }
`;

const LabelModalCsvExport = styled.div<{
  theme: GardThemeType;
}>`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.label};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: 13px;
  line-height: 22px;
`;

export function CsvExportModalForm(props: CsvExportModalFormProps) {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const col1 = 'benchmarking';
  const col2 = 'hours';
  const col3 = 'fees';
  const colsName = useMemo(() => {
    const column: Array<string> = [];

    if (isChecked1) {
      column.push(col1);
    }

    if (isChecked2) {
      column.push(col2);
    }

    if (isChecked3) {
      column.push(col3);
    }

    return column;
  }, [isChecked1, isChecked2, isChecked3]);

  return (
    <>
      <CsvExportModalFormContainer>
        <LabelModalCsvExport>
          Select which tables you want to export
        </LabelModalCsvExport>
        <Checkbox
          isChecked={isChecked1}
          name={col1}
          value={col1}
          label={'Rate benchmarking'}
          onClick={() => {
            setIsChecked1((state) => !state);
          }}
          onChange={() => {
            return null;
          }}
        />
        <Checkbox
          isChecked={isChecked2}
          name={col2}
          value={col2}
          label={'Project hours table'}
          onClick={() => {
            setIsChecked2((state) => !state);
          }}
          onChange={() => {
            return null;
          }}
        />
        <Checkbox
          isChecked={isChecked3}
          name={col3}
          value={col3}
          label={'Project fees table'}
          onClick={() => {
            setIsChecked3((state) => !state);
          }}
          onChange={() => {
            return null;
          }}
        />
      </CsvExportModalFormContainer>
      <DialogButtons>
        <ButtonPrimary
          disabled={isChecked1 || isChecked2 || isChecked3 ? false : true}
          onClick={() => {
            props.data &&
              exportCsv(colsName, props.data, props.colsDescription);
          }}
          style={{ marginRight: '16px' }}
        >
          Export
        </ButtonPrimary>
        <DialogClose asChild>
          <ButtonOutlined active={false}>Close</ButtonOutlined>
        </DialogClose>
      </DialogButtons>
    </>
  );
}

export default CsvExportModalForm;
