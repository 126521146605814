import styled from 'styled-components';
import { GardThemeType } from '@observatory/front-end/gard-theme';

/* eslint-disable-next-line */
export interface LoaderProps {
  type: 'circle' | 'dots';
}

const LoaderContainer = styled.div<{ theme: GardThemeType }>``;

export function Loader(props: LoaderProps) {
  return props.type === 'circle' ? (
    <LoaderContainer>
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="100"
          cy="100"
          r="97.5"
          stroke="#BD9E24"
          strokeOpacity="0.3"
          strokeWidth="5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.561 82.6847C190.929 38.121 153.809 3.62595 108 0V5.01674C151.043 8.6056 185.933 40.8906 193.483 82.6847H198.561Z"
          fill="#270654"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 100 100"
            to="360 100 100"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </LoaderContainer>
  ) : (
    <LoaderContainer>
      <svg
        width="80"
        height="16"
        viewBox="0 0 80 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill="#270654">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle opacity="0.5" cx="40" cy="8" r="8" fill="#270654">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle opacity="0.2" cx="72" cy="8" r="8" fill="#270654">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </LoaderContainer>
  );
}

export default Loader;
