import React from 'react';
import { useController } from 'react-hook-form';
import Checkbox from '../checkbox/checkbox';
import Select, { SelectProps } from '../select/select';

/* eslint-disable-next-line */
export interface CheckboxSelectProps
  extends Omit<SelectProps<string | number>, 'children' | 'type'> {
  label: string;
  selected?: Array<string>;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const CheckboxSelect = (props: CheckboxSelectProps) => {
  return (
    <Select
      label={props.label}
      options={props.options}
      selected={props.selected}
      onSelected={props.onSelected}
      placeholder={props.placeholder}
      type="multiple-selection"
    >
      {(option: { label: string; value: string }) => (
        <Checkbox
          name={props.name}
          label={option.label}
          value={option.value}
          isChecked={
            props.selected?.find((value: string) => value === option.value)
              ? true
              : false
          }
          onChange={props.onChange}
        />
      )}
    </Select>
  );
};

export const CheckboxSelectFormInput = (
  props: Omit<CheckboxSelectProps, 'onSelected'>
) => {
  const { field } = useController({
    name: props.name,
    defaultValue: props.selected || [],
  });

  const onSelect = (value: string | number) => {
    if (field.value.find((item: string) => item === value)) {
      field.onChange(field.value.filter((item: string) => item !== value));
    } else {
      field.onChange([...field.value, value]);
    }
  };

  return (
    <CheckboxSelect
      label={props.label}
      name={props.name}
      options={props.options}
      selected={field.value || false}
      onSelected={(value) => onSelect(value)}
      placeholder={props.placeholder}
      onChange={field.onChange}
    />
  );
};

export default CheckboxSelect;
