import { useFetcher } from '@f-technology-srl/client-authorization';
import { useHandleRespError } from '@f-technology-srl/react-hook-utils';

export function useDeleteRateCollection() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  return {
    error,
    async deleteRate(collector_id: string) {
      const result = await fetcher(
        `/api/rate-collection/delete/${collector_id}`,
        {
          method: 'DELETE',
          body: {},
        }
      );

      return handle<boolean>(result);
    },
  };
}
